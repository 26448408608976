import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_DOMAIN } from '../../constants/api';

const initialState = {
  examCategories: {
    loading: false,
    data: [],
    error: null,
  },
  subExamCategories: {
    loading: false,
    data: [],
    error: null,
  },
  content: {
    loading: false,
    data: [],
    error: null,
  },
};

export const getCategories = createAsyncThunk('examInfo_categories', async (thunkAPI) => {
  try {
    const response = await axios.get(`${API_DOMAIN}/web/pages_categories`);
    if (response.status === 200) {
      const { success, data } = response.data || {};
      let { msg } = response.data || {};

      msg = (msg || '').toLowerCase();
      if (success !== 1 || msg === 'Page information not found' || msg.includes('information not found')) {
        return thunkAPI.rejectWithValue({
          field: 'server',
          message: response?.data?.msg,
        });
      }
      return data;
    }
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const getSubExamCategories = createAsyncThunk(
  'examInfo/getSubCategories',
  async (categorySlugvalue, thunkAPI) => {
    try {
      const response = await axios.get(`${API_DOMAIN}/web/courses_list?cat_slug=${categorySlugvalue}`);

      if (response.status === 200) {
        const { success, data } = response.data || {};
        let { msg } = response.data || {};

        msg = (msg || '').toLowerCase();
        if (success !== 1 || msg === 'Page information not found' || msg.includes('information not found')) {
          return thunkAPI.rejectWithValue({
            field: 'server',
            message: response?.data?.msg,
          });
        }
        return data;
      }

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const getAboutContent = createAsyncThunk('examInfo/getAboutContent', async (categorySlugvalue, thunkAPI) => {
  try {
    const response = await axios.get(`${API_DOMAIN}/web/pagedetailcourses?slug=${categorySlugvalue}`);
    if (response.status === 200) {
      const { success, data } = response.data || {};
      let { msg } = response.data || {};

      msg = (msg || '').toLowerCase();
      if (success !== 1 || msg === 'Page information not found' || msg.includes('information not found')) {
        return thunkAPI.rejectWithValue({
          field: 'server',
          message: response?.data?.msg,
        });
      }
      return data;
    }

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

const examInfoSlice = createSlice({
  name: 'examInfo',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.examCategories.loading = true;
        state.examCategories.data = [];
        state.examCategories.error = null;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.examCategories.loading = false;
        state.examCategories.data = action?.payload;
        state.examCategories.error = null;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.examCategories.loading = false;
        state.examCategories.data = [];
        state.examCategories.error = action.payload.message;
      })
      .addCase(getSubExamCategories.pending, (state) => {
        state.subExamCategories.loading = true;
        state.subExamCategories.data = [];
        state.subExamCategories.error = null;
      })
      .addCase(getSubExamCategories.fulfilled, (state, action) => {
        state.subExamCategories.loading = false;
        state.subExamCategories.data = action?.payload;
        state.subExamCategories.error = null;
      })
      .addCase(getSubExamCategories.rejected, (state, action) => {
        state.subExamCategories.loading = false;
        state.subExamCategories.data = [];
        state.subExamCategories.error = action?.payload?.message;
      })
      .addCase(getAboutContent.pending, (state) => {
        state.content.loading = true;
        state.content.data = [];
        state.content.error = null;
      })
      .addCase(getAboutContent.fulfilled, (state, action) => {
        state.content.loading = false;
        state.content.data = action?.payload;
        state.content.error = null;
      })
      .addCase(getAboutContent.rejected, (state, action) => {
        state.content.loading = false;
        state.content.data = [];
        state.content.error = action?.payload?.message;
      });
  },
});

export default examInfoSlice.reducer;
