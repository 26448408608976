import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { javaApi, phpApi } from '../../utils/AxiosInstance';

const initialState = {
  testInfo: {
    loading: false,
    data: null,
    error: null,
  },
  registerTest: {
    loading: false,
    isRegistered: false,
    data: null,
    error: null,
  },
  testseries: {
    loading: false,
    data: null,
    error: null,
  },
};

// Actions
export const fetchTestRegisteredStatus = createAsyncThunk(
  'fixedMock/fetchTestRegisteredStatus',
  async (mappingId, thunkAPI) => {
    try {
      const { authToken } = thunkAPI.getState().auth;
      const response = await javaApi.get(
        'app-test-series-ws/api/v1/test-series/fixedmock/isUserRegistered',
        { params: { mappingId }, headers: { Authorization: `Bearer ${authToken}` } },
      );

      if (response?.data?.success) return true;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const fetchTestInfo = createAsyncThunk('fixedMock/fetchTestInfo', async ({ testSlug }, thunkAPI) => {
  try {
    const response = await phpApi.get('mock_test_detail', {
      params: { slug: testSlug },
    });

    if (response?.data?.success && response?.data?.data) {
      thunkAPI.dispatch(fetchTestRegisteredStatus(response?.data?.data?.mappingId));

      return response?.data?.data;
    }

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const fetchTestseries = createAsyncThunk('fixedMock/fetchTestseries', async (quryparams, thunkAPI) => {
  try {
    const response = await javaApi.post('app-test-series-ws/api/v1/test-series/fixedmock/list', {
      ...quryparams,
    });

    if (response?.data?.success) {
      return response?.data?.data;
    }

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const registerTest = createAsyncThunk('fixedMock/register', async ({ mappingId, slug: testSlug }, thunkAPI) => {
  try {
    const response = await javaApi.post(
      'app-test-series-ws/api/v1/test-series/fixedmock/register',
      {},
      {
        params: { mappingId },
      },
    );

    thunkAPI.dispatch(fetchTestInfo({ testSlug }));

    if (response?.data?.success) return response.data?.data;

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const resetRegisterTestState = createAction('fixedMock/resetRegisterTestState');

// Fixed Mock Slice
const fixedMockSlice = createSlice({
  name: 'fixedMock',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestInfo.pending, (state) => {
        state.testInfo = {
          ...initialState.testInfo,
          loading: true,
        };
      })
      .addCase(fetchTestInfo.fulfilled, (state, action) => {
        state.testInfo = {
          ...initialState.testInfo,
          data: {
            ...action.payload,
            isEnded: new Date().getTime() > action.payload.end_time - 600000,
          },
        };
      })
      .addCase(fetchTestInfo.rejected, (state) => {
        state.testInfo = {
          ...initialState.testInfo,
          error: null,
        };
      })
      .addCase(fetchTestRegisteredStatus.pending, (state) => {
        if (state.testInfo.data) {
          state.testInfo.loading = true;
          state.testInfo.data.registered = false;
        }
      })
      .addCase(fetchTestRegisteredStatus.fulfilled, (state, action) => {
        if (state.testInfo.data) {
          state.testInfo.loading = false;
          state.testInfo.data.registered = action.payload;
        }
      })
      .addCase(fetchTestRegisteredStatus.rejected, (state) => {
        if (state.testInfo.data) {
          state.testInfo.loading = false;
          state.testInfo.data.registered = false;
        }
      })
      .addCase(registerTest.pending, (state) => {
        state.registerTest = {
          ...initialState.registerTest,
          loading: true,
        };
      })
      .addCase(registerTest.fulfilled, (state, action) => {
        state.registerTest = {
          ...initialState.registerTest,
          isRegistered: true,
          data: action.payload,
        };
      })
      .addCase(registerTest.rejected, (state, action) => {
        state.registerTest = {
          ...initialState.registerTest,
          error: action.payload,
        };
      })
      .addCase(resetRegisterTestState, (state) => {
        state.registerTest = initialState.registerTest;
      })
      .addCase(fetchTestseries.fulfilled, (state, action) => {
        state.testseries = {
          ...initialState.testseries,
          data: action.payload,
        };
      });
  },
});

export default fixedMockSlice.reducer;
