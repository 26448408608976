import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getAuthState, javaApi } from '../../utils/AxiosInstance';

const initialState = {
  loading: false,
  cruxDataDetails: [],
  pptDataDetails: null,
  doubtsRecord: {
    details: [],
    error: null,
    loading: false,
  },
  lessonResources: {
    details: [],
    error: null,
    loading: false,
  },
  testSeriesDetails: {
    details: [],
    error: null,
    loading: false,
  },
  snippetsDetails: {
    details: [],
    error: null,
    loading: false,
  },
  contentTabsDetails: {
    details: [],
    error: null,
    loading: false,
  },
  myCourseAddVideoList: {
    loading: false,
    details: null,
    error: null,
  },
  userVideoList: {
    loading: false,
    details: null,
    error: null,
  },
  saveUserPdf: [],
  videoPlayerModalData: {
    show: false,
    videoDetails: null,
  },
};

export const getSnippets = createAsyncThunk('my_course_video_detail', async ({ videoLessonId, courseId }, thunkAPI) => {
  try {
    const { userID, authToken } = await getAuthState();
    const payload = {
      userId: userID,
      videoLessonId,
      token: authToken,
      courseId,
    };
    const response = await javaApi.post('app-content-ws/api/web/my_course_video_detail', payload);
    const { success, data } = response?.data || {};
    if (success) {
      return {
        ...data,
      };
    }
    return thunkAPI.rejectWithValue(response?.msg);
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const getContentTabs = createAsyncThunk(
  'videoDashboard/get_content_tabs',
  async ({ courseId, lessonId }, thunkAPI) => {
    try {
      const response = await javaApi.post(`app-content-ws/api/lesson/data?lesson_id=${lessonId}&courseId=${courseId}`);
      const { success, options } = response.data || {};
      if (success) {
        return {
          options,
        };
      }

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const getLessonResources = createAsyncThunk(
  'videoDashboard/get_Lesson_Resources',
  async ({ courseId, lessonId }, thunkAPI) => {
    try {
      const { userID } = await getAuthState();
      const response = await javaApi.get('app-content-ws/v1/course/getLessonResources', {
        params: { courseId, userId: userID, lessonId },
      });

      const { success, ...lessonResourceDetails } = response?.data || {};
      if (success) {
        return {
          ...lessonResourceDetails,
        };
      }

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const getTestSeries = createAsyncThunk(
  'videoDashboard/get_Test_Series',
  async ({ courseId, testIds }, thunkAPI) => {
    try {
      const payload = { courseId, testIds: [testIds], purchased: true };

      const response = await javaApi.post('app-test-series-ws/api/v1/test-series/tests', payload);

      const { success, ...testSeriesDetails } = response?.data || {};
      if (success) {
        return {
          ...testSeriesDetails,
        };
      }

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const getCrux = createAsyncThunk('my_course_video_crux', async ({ lessonID }, thunkAPI) => {
  try {
    const { userID, authToken } = await getAuthState();
    const payload = {
      assignedLessonId: lessonID,
      userId: userID,
      token: authToken,
    };
    const response = await javaApi.post('app-content-ws/api/web/my_course_video_crux', payload);
    if (response?.data?.success === 1) {
      return { ...response?.data?.data };
    }

    return thunkAPI.rejectWithValue(response?.msg);
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const getPPT = createAsyncThunk('my_course_video_ppt', async ({ videoID }, thunkAPI) => {
  try {
    const { userID, authToken } = await getAuthState();
    const formData = new FormData();
    formData.append('video_lesson_id', `${videoID}`);
    formData.append('user_id', `${userID}`);
    formData.append('api_token', `${authToken}`);
    const response = await javaApi.post('app-content-ws/api/web/my_course_video_ppt', formData);
    if (response?.data?.success === 1) {
      return response?.data?.data;
    }
    return thunkAPI.rejectWithValue(response?.msg);
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const saveUserPdfStatus = createAsyncThunk(
  'app-consumption-ws/api/v2/pdf/save',
  async ({ courseId, languageId, assignedLessonId, type }, thunkAPI) => {
    try {
      const data = {
        courseId: parseInt(courseId, 10),
        languageId: parseInt(languageId, 10),
        assignedLessonId: parseInt(assignedLessonId, 10),
        type,
      };
      const headers = {
        'Content-Type': 'application/json',
      };

      const response = await javaApi.post('app-consumption-ws/api/v2/pdf/save', data, {
        headers,
      });
      if (response?.data?.success) {
        return response?.data;
      }
      return thunkAPI.rejectWithValue(response?.msg);
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

// Slice
const videoDashboardSlice = createSlice({
  name: 'videoDashboard',
  initialState,
  reducers: {
    videoPlayerDetail: (state, action) => {
      const { details, show } = action.payload;
      state.videoPlayerModalData = {
        show,
        videoDetails: details,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCrux.fulfilled, (state, action) => {
        state.cruxDataDetails = action.payload;
      })
      .addCase(getPPT.fulfilled, (state, action) => {
        state.pptDataDetails = action.payload;
      })
      .addCase(getSnippets.pending, (state, action) => {
        state.snippetsDetails = {
          ...initialState.snippetsDetails,
          details: action.payload || [],
        };
      })
      .addCase(getSnippets.fulfilled, (state, { payload: { userVideoDetails } }) => {
        state.snippetsDetails = {
          ...state.snippetsDetails,
          details: userVideoDetails || [],
        };
      })
      .addCase(getSnippets.rejected, (state, action) => {
        state.snippetsDetails = {
          ...initialState.snippetsDetails,
          error: action.payload || [],
        };
      })
      .addCase(getContentTabs.pending, (state, action) => {
        state.contentTabsDetails = {
          ...initialState.contentTabsDetails,
          details: action.payload || [],
        };
      })
      .addCase(getContentTabs.fulfilled, (state, { payload: { options } }) => {
        state.contentTabsDetails = {
          ...state.contentTabsDetails,
          details: options || [],
        };
      })
      .addCase(getContentTabs.rejected, (state, action) => {
        state.contentTabsDetails = {
          ...initialState.contentTabsDetails,
          error: action.payload || [],
        };
      })
      .addCase(getLessonResources.pending, (state, action) => {
        state.lessonResources = {
          ...state.lessonResources,
          details: action.payload || [],
          loading: true,
        };
      })
      .addCase(getLessonResources.fulfilled, (state, action) => {
        state.lessonResources = {
          ...state.lessonResources,
          details: action.payload || [],
          loading: false,
        };
      })
      .addCase(getLessonResources.rejected, (state, action) => {
        state.lessonResources = {
          ...initialState.lessonResources,
          error: action.payload || [],
          loading: false,
        };
      })
      .addCase(getTestSeries.pending, (state, action) => {
        state.testSeriesDetails = {
          ...state.testSeriesDetails,
          details: action.payload || [],
          loading: true,
        };
      })
      .addCase(getTestSeries.fulfilled, (state, action) => {
        state.testSeriesDetails = {
          ...state.testSeriesDetails,
          details: action.payload.data || [],
          loading: false,
        };
      })
      .addCase(getTestSeries.rejected, (state, action) => {
        state.testSeriesDetails = {
          ...initialState.testSeriesDetails,
          error: action.payload || [],
          loading: false,
        };
      })
      .addCase(saveUserPdfStatus.fulfilled, (state, action) => {
        state.saveUserPdf = action.payload;
      });
  },
});

export default videoDashboardSlice.reducer;

export const { videoPlayerDetail } = videoDashboardSlice.actions;
