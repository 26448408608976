/* eslint-disable no-plusplus, camelcase, function-paren-newline */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Cookie } from 'next-cookie';
import { phpApi } from '../../utils/AxiosInstance';
import { objToCamelCase } from '../../utils/helper';

const initialState = {
  loading: false,
  courseDetail: {
    data: {},
    loading: false,
    error: false,
  },
  modeData: null,
};

export const getuserDetails = async (course_slug) => {
  const cookies = new Cookie();
  const user_id = cookies.get('userID');
  const api_token = cookies.get('apiToken');
  let user_details = null;
  if (user_id && api_token) {
    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('api_token', api_token);
    formData.append('course_slug', course_slug);

    try {
      const userCourseDetailResponse = await phpApi.post('user_course_details', formData);
      if (userCourseDetailResponse?.data?.success === 1) {
        user_details = userCourseDetailResponse?.data?.data;
      }
    } catch (error) {
      //
    }
    return user_details;
  }
  return user_details;
};

export const getCourseDetails = createAsyncThunk('course/getCourseDetails', async ({
  courseNameSlug,
  selectedCourseLangage,
  modeData,
}, thunkAPI) => {
  try {
    const courseName = courseNameSlug;
    const cookies = new Cookie();
    const userID = cookies.get('userID');
    const apiToken = cookies.get('apiToken');
    const language = Number(selectedCourseLangage);
    let url = `courseview/${courseName}`;
    if (language && !modeData?.location) {
      url = `${url}?language_id=${language}`;
    } else if (modeData?.location && language && modeData?.mode === 'offline') {
      url = `${url}?language_id=${language}&offlineCentreId=${modeData?.location}`;
    } else if (!language && modeData?.location && modeData?.mode === 'offline') {
      url = `${url}?offlineCentreId=${modeData?.location}`;
    }
    const userRes = await getuserDetails(courseName);
    const response = await phpApi.get(url, { noAuth: true });
    if (response?.data?.success) {
      const { success, data: courseDetail } = response.data;
      courseDetail.userDetails = { purchased: false };
      if (userRes) {
        courseDetail.userDetails = objToCamelCase(userRes);
      }
      if (success && userID && apiToken) {
        const formData = new FormData();
        formData.append('user_id', userID);
        formData.append('api_token', apiToken);
        formData.append('course_slug', courseName);

        const userCourseDetailResponse = await phpApi.post('user_course_details', formData);
        if (userCourseDetailResponse?.data?.success === 1) {
          courseDetail.userDetails = objToCamelCase(userCourseDetailResponse?.data?.data);
        }
      } else {
        courseDetail.userDetails = { purchased: false };
      }
      return objToCamelCase(courseDetail);
    }
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

const courseDetailSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setChangeMode: (state, action) => {
      state.modeData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseDetails.pending, (state) => {
        state.courseDetail.loading = true;
        state.courseDetail.data = null;
      })
      .addCase(getCourseDetails.fulfilled, (state, action) => {
        state.courseDetail.loading = false;
        state.courseDetail.data = action?.payload;
      })
      .addCase(getCourseDetails.rejected, (state) => {
        state.loading = false;
        state.paymentMethods = null;
      });
  },
});
export const { setChangeMode } = courseDetailSlice.actions;
export default courseDetailSlice.reducer;
