import { combineReducers } from '@reduxjs/toolkit';

// Reducers
import authReducer from './slices/authSlice/reducer';
import bookMarkReducer from './slices/bookMarkSlice';
import cartReducer from './slices/cartSlice';
import checkoutReducer from './slices/checkoutSlice';
import coachMarkReducer from './slices/coachMarkSlice';
import courseConsumptionReducer from './slices/courseConsumption';
import examInfoReducer from './slices/examInfoSlice';
import fixedMockReducer from './slices/fixedMockSlice';
import footerReducer from './slices/footerSlice';
import homeReducer from './slices/homeSlice';
import libraryReducer from './slices/librarySlice';
import playListReducer from './slices/playListSlice';
import policyPageReducer from './slices/policyPageSlice';
import resourceDashboardReducer from './slices/resourceDashboard';
import videoDashboardReducer from './slices/videodashboardSlice';
import answerWritingReducer from './slices/answerWritingSlice';
import feedReducer from './slices/feedSlice';
import FeedbackReducer from './slices/feedbackSlice';
import testSeriesReducer from './slices/testSeriesSlice';
import studyiqAI from './slices/studyiqAI';
import freeLiveClassesReducer from './slices/freeLiveClassesSlice';
import notificationReducer from './slices/notificationSlice';
import accountInfoReducer from './slices/accountInfoSlice/reducer';
import courseDetailSlice from './slices/courseDetailSlice';

export default combineReducers({
  auth: authReducer,
  home: homeReducer,
  library: libraryReducer,
  checkout: checkoutReducer,
  footer: footerReducer,
  fixedMock: fixedMockReducer,
  courseConsumption: courseConsumptionReducer,
  resourceDashboard: resourceDashboardReducer,
  playList: playListReducer,
  videoDashboard: videoDashboardReducer,
  answerWriting: answerWritingReducer,
  cart: cartReducer,
  bookmark: bookMarkReducer,
  coachMark: coachMarkReducer,
  feed: feedReducer,
  feedback: FeedbackReducer,
  policyPage: policyPageReducer,
  testSeries: testSeriesReducer,
  examInfo: examInfoReducer,
  studyiqai: studyiqAI,
  freeLiveClasses: freeLiveClassesReducer,
  notification: notificationReducer,
  accountInfo: accountInfoReducer,
  course: courseDetailSlice,
});
