import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FormData from 'form-data';
import camelCase from 'lodash.camelcase';
import mapKeys from 'lodash.mapkeys';

import { javaApi, phpApi } from '../../utils/AxiosInstance';
import { getContentType, isResourceType } from '../../utils/helper';

const initialState = {
  videoTracking: {
    loading: false,
    details: null,
    error: null,
  },
  resource: {
    loading: false,
    detail: null,
    error: null,
  },
  quizMcqs: {
    loading: false,
    data: null,
    error: null,
  },
  currentTimeStamp: null,
};

// Actions
export const updateContinueWatchingVideos = createAsyncThunk(
  'resourceDashboard/updateContinueWatchingVideos',
  async (body, thunkAPI) => {
    try {
      const response = await javaApi.post('app-consumption-ws/api/v2/video/status', body);

      if (response?.data?.success) return response.data.data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const getCourseVideoTracking = createAsyncThunk(
  'resourceDashboard/getCourseVideoTracking',
  async (body, thunkAPI) => {
    try {
      const response = await javaApi.get('app-consumption-ws/api/v3/video/status/get', {
        params: body,
      });
      if (response?.data?.success) return response.data.data || false;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const updateTotalWatchingVideos = createAsyncThunk(
  'resourceDashboard/updateTotalWatchingVideos',
  async (body, thunkAPI) => {
    try {
      const response = await javaApi.post('user-analytics-ws/v1/mongo/save/video/status', body);
      if (response?.data?.success) return response.data.data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const fetchCourseResource = createAsyncThunk('resourceDashboard/fetchCourseResource', async (body, thunkAPI) => {
  try {
    const response = await phpApi.post('get_video_base_info', body);
    if (response?.data?.success) return response.data.results[0];

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const fetchVideoCipherVideoOTP = createAsyncThunk(
  'resourceDashboard/fetchVideoCipherVideoOTP',
  async (videoID, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append('video_id', videoID);

      const response = await phpApi.post('getcoursevideos', formData);
      if (response.data.success) return response.data.data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);
export const saveCurrentTimeStamp = createAsyncThunk('resourceDashboard/saveCurrentTimeStamp', (data) => data);

export const fetchResourceDetails = createAsyncThunk(
  'resourceDashboard/fetchResourceDetails',
  async (body, thunkAPI) => {
    const state = await thunkAPI.getState();
    const { elementContentType } = state.courseConsumption.courseDetails;
    const isResource = isResourceType(elementContentType);

    if (!isResource) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'Invalid Resource.',
      });
    }

    const resourceType = getContentType(elementContentType);

    try {
      let response = null;
      if (resourceType === 'VIDEO') {
        response = await phpApi.post('get_video_base_info', body);
      }

      if (response?.data?.success) {
        const resource = response?.data?.results?.[0] ?? response?.data?.data?.[0] ?? response?.data?.data;

        return {
          ...resource,
          resourceType,
        };
      }

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const fetchCourseQuizMCQs = createAsyncThunk(
  'resourceDashboard/fetchCourseQuizMCQs',
  async (mcqId, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append('mcq_title_id', mcqId);

      const response = await phpApi.post('getquizmcqs', formData);
      if (response.data.success) return response.data.data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

// Resource Dashboard Slice
const resourceDashboardSlice = createSlice({
  name: 'resourceDashboard',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCourseVideoTracking.pending, (state) => {
        state.videoTracking = {
          ...initialState.videoTracking,
          loading: true,
        };
      })
      .addCase(getCourseVideoTracking.fulfilled, (state, action) => {
        state.videoTracking = {
          ...initialState.videoTracking,
          details: action.payload,
        };
      })
      .addCase(getCourseVideoTracking.rejected, (state, action) => {
        state.videoTracking = {
          ...initialState.videoTracking,
          error: action.payload,
          details: false,
        };
      })
      .addCase(fetchResourceDetails.pending, (state) => {
        state.resource = {
          ...initialState.resource,
          loading: true,
        };
      })
      .addCase(fetchResourceDetails.fulfilled, (state, action) => {
        const resource = mapKeys(action.payload, (v, k) => camelCase(k));

        if (!resource.resourceType) {
          resource.resourceType = getContentType(resource.elementContentType);
        }

        if (resource.resourceType === 'VIDEO') {
          switch (resource.videoTypeId) {
            case 1:
              resource.player = 'VIMEO';
              break;
            case 2:
              resource.player = 'VIDEO_CIPHER';
              break;
            case 3:
              resource.player = 'SHAKA';
              break;
            case 4:
              resource.player = 'SHAKA';
              break;
            default:
              resource.player = 'SHAKA';
          }
        }

        state.resource = {
          ...initialState.resource,
          detail: resource,
        };
      })
      .addCase(fetchResourceDetails.rejected, (state) => {
        state.resource = initialState.resource;
      })
      .addCase(fetchCourseQuizMCQs.pending, (state) => {
        state.quizMcqs = {
          ...initialState.quizMcqs,
          loading: true,
        };
      })
      .addCase(fetchCourseQuizMCQs.fulfilled, (state, action) => {
        state.quizMcqs = {
          ...initialState.quizMcqs,
          data: action.payload,
        };
      })
      .addCase(fetchCourseQuizMCQs.rejected, (state, action) => {
        state.videoInfo = {
          ...initialState.quizMcqs,
          error: action.payload,
        };
      })
      .addCase(fetchVideoCipherVideoOTP.pending, (state) => {
        state.videoInfo = {
          ...state.videoInfo,
          loading: true,
        };
      })
      .addCase(fetchVideoCipherVideoOTP.fulfilled, (state, action) => {
        state.videoInfo = {
          ...state.videoInfo,
          otpInfo: action.payload,
        };
      })
      .addCase(fetchVideoCipherVideoOTP.rejected, (state, action) => {
        state.videoInfo = {
          ...state.videoInfo,
          error: action.payload,
        };
      })
      .addCase(saveCurrentTimeStamp.fulfilled, (state, action) => {
        state.currentTimeStamp = {
          ...state.currentTimeStamp,
          currentTimeStamp: action.payload,
        };
      });
  },
});

export default resourceDashboardSlice.reducer;
