/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';

import * as accountInfoActions from './actions';

const initialState = {
  userExamPreferenceDetails: {
    loading: true,
    data: [],
    error: null,
  },
  transactionsData: {
    loading: true,
    data: [],
    error: null,
  },
  impersonationData: {
    loading: true,
    data: {},
    error: null,
  },
  emailOtpTriggered: {
    loading: true,
    data: {},
    error: null,
  },
  emailOtpVerified: {
    loading: true,
    data: {},
    error: null,
  },
  mobileOtpTriggered: {
    loading: true,
    data: {},
    error: null,
  },
  mobileOtpVerified: {
    loading: true,
    data: {},
    error: null,
  },
  getCityDetails: {
    loading: true,
    data: {},
    error: null,
  },
  updatedProfileDetails: {
    loading: true,
    data: {},
    error: null,
  },
};

// Slice
const accountInfoSlice = createSlice({
  name: 'accountInfo',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(accountInfoActions.fetchExamPreferenceList.pending, (state) => {
        state.userExamPreferenceDetails = {
          ...state.userExamPreferenceDetails,
          loading: true,
        };
      })
      .addCase(accountInfoActions.fetchExamPreferenceList.fulfilled, (state, action) => {
        state.userExamPreferenceDetails = {
          ...state.userExamPreferenceDetails,
          loading: false,
          data: action.payload.data,
        };
      })
      .addCase(accountInfoActions.fetchExamPreferenceList.rejected, (state, action) => {
        state.userExamPreferenceDetails = {
          ...state.userExamPreferenceDetails,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(accountInfoActions.getTransactionsData.pending, (state) => {
        state.transactionsData = {
          ...state.transactionsData,
          loading: true,
        };
      })
      .addCase(accountInfoActions.getTransactionsData.fulfilled, (state, action) => {
        state.transactionsData = {
          ...state.transactionsData,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(accountInfoActions.getTransactionsData.rejected, (state, action) => {
        state.transactionsData = {
          ...state.transactionsData,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(accountInfoActions.impersonateUser.pending, (state) => {
        state.impersonationData = {
          ...state.impersonationData,
          loading: true,
        };
      })
      .addCase(accountInfoActions.impersonateUser.fulfilled, (state, action) => {
        state.impersonationData = {
          ...state.impersonationData,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(accountInfoActions.impersonateUser.rejected, (state, action) => {
        state.impersonationData = {
          ...state.impersonationData,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(accountInfoActions.triggerEmailOtp.pending, (state) => {
        state.emailOtpTriggered = {
          ...state.emailOtpTriggered,
          loading: true,
        };
      })
      .addCase(accountInfoActions.triggerEmailOtp.fulfilled, (state, action) => {
        state.emailOtpTriggered = {
          ...state.emailOtpTriggered,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(accountInfoActions.triggerEmailOtp.rejected, (state, action) => {
        state.emailOtpTriggered = {
          ...state.emailOtpTriggered,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(accountInfoActions.verifyEmailOtp.pending, (state) => {
        state.emailOtpVerified = {
          ...state.emailOtpVerified,
          loading: true,
        };
      })
      .addCase(accountInfoActions.verifyEmailOtp.fulfilled, (state, action) => {
        state.emailOtpVerified = {
          ...state.emailOtpVerified,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(accountInfoActions.verifyEmailOtp.rejected, (state, action) => {
        state.emailOtpVerified = {
          ...state.emailOtpVerified,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(accountInfoActions.triggerMobileOtp.pending, (state) => {
        state.mobileOtpTriggered = {
          ...state.mobileOtpTriggered,
          loading: true,
        };
      })
      .addCase(accountInfoActions.triggerMobileOtp.fulfilled, (state, action) => {
        state.mobileOtpTriggered = {
          ...state.mobileOtpTriggered,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(accountInfoActions.triggerMobileOtp.rejected, (state, action) => {
        state.mobileOtpTriggered = {
          ...state.mobileOtpTriggered,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(accountInfoActions.verifyMobileOtp.pending, (state) => {
        state.mobileOtpVerified = {
          ...state.mobileOtpVerified,
          loading: true,
        };
      })
      .addCase(accountInfoActions.verifyMobileOtp.fulfilled, (state, action) => {
        state.mobileOtpVerified = {
          ...state.mobileOtpVerified,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(accountInfoActions.verifyMobileOtp.rejected, (state, action) => {
        state.mobileOtpVerified = {
          ...state.mobileOtpVerified,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(accountInfoActions.getCityStateData.pending, (state) => {
        state.getCityDetails = {
          ...state.getCityDetails,
          loading: true,
        };
      })
      .addCase(accountInfoActions.getCityStateData.fulfilled, (state, action) => {
        state.getCityDetails = {
          ...state.getCityDetails,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(accountInfoActions.getCityStateData.rejected, (state, action) => {
        state.getCityDetails = {
          ...state.getCityDetails,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(accountInfoActions.updateUserProfile.pending, (state) => {
        state.updatedProfileDetails = {
          ...state.updatedProfileDetails,
          loading: true,
        };
      })
      .addCase(accountInfoActions.updateUserProfile.fulfilled, (state, action) => {
        state.updatedProfileDetails = {
          ...state.updatedProfileDetails,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(accountInfoActions.updateUserProfile.rejected, (state, action) => {
        state.updatedProfileDetails = {
          ...state.updatedProfileDetails,
          loading: false,
          error: action.payload,
        };
      });
  },
});

export default accountInfoSlice.reducer;
