import React from 'react';
import { Cookie } from 'next-cookie';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { javaApi } from '../utils/AxiosInstance';
import { getAllUTMParams, getBrowserDetails, getDeviceType, getOSDetails } from '../utils/helper';

export const EVENT_NAME = {
  VIEW_PACKAGE_ABORTED: 'view_package_aborted',
  PACKAGES_VISITED: 'packages_visited',
  BUY_EMI_TAPPED: 'buy_emi_tapped',
  EMI_CHECKED: 'emi_checked',
  COURSE_TAB_ENGAGED: 'course_tab_engaged',
  COURSE_TAB_DISENGAGED: 'offers_dismissied',
  RENEW_TAPPED: 'renew_tapped',
  START_MY_COURSE: 'start_my_course',
  PAY_EMI_TAPPED: 'pay_emi_tapped',
  REMOVE_TAPPED: 'remove_tapped',
  COURSE_CATEGORY_TAPPED: 'course_category_tapped',
  ACCOUNT_INFO_TAPPED: 'account_info_tapped',
  PROFILE_UPDATE_TAPPED: 'profile_update_tapped',
  EXAM_UPDATE_TAPPED: 'exam_update_tapped',
  MY_PROFILE_TAPPED: 'my_profile_tapped',
  MY_EXAM_TAPPED: 'my_exam_tapped',
  ACCOUNT_TAPPED: 'account_tapped',
  EMAIL_UPDATED: 'email_updated',
  MOBILE_UPDATED: 'mobile_updated',
  TRANSACTIONS_TAPPED: 'transactions_tapped',
  DOWNLOAD_INVOICE: 'download_invoice',
  ANS_WRITING_CLICKED: 'ans_writing_clicked',
  FEED_CLICKED: 'feed_clicked',
  MY_LIBRARY_CLICKED: 'my_library_clicked',
  FREE_QUIZZES_TAPPED: 'free_quizzes_tapped',
  FREE_COURSES_TAPPED: 'free_courses_tapped',
  FMT_CLICKED: 'fmt_clicked',
  NCERT_SUMMARY_TAPPED: 'ncert_summary_tapped',
  PDP_VISITED: 'pdp_visited',
  BUY_NOW_CLICKED: 'buy_now_clicked',
  FINAL_BUY_NOW_TAPPED: 'final_buy_now_tapped',
  PAYMENT_PAGE_VISITED: 'payment_page_visited',
  PAYMENT_INSTRUMENT: 'payment_instrument',
  ADDON_SELECTED: 'addon_selected',
  ADDON_REMOVED: 'addon_removed',
  PACKAGE_TAPPED: 'package_tapped',
  COUPON_APPLIED: 'coupon_applied',
  OFFERS_TAPPED: 'offers_tapped',
  PAYMENT_STATUS: 'payment_status',
  COUPON_REMOVED: 'coupon_removed',
  FAQS_CLICKED: 'faqs_clicked',
  COURSELANGUAGE_SELECTED: 'courselanguage_selected',
  PDP_ENGAGEMENT: 'pdp_engagement',
  PURCHASE_SUCCESS: 'purchase_success',
  PROCEED_TO_PAY: 'proceed_to_pay',
  RECENTLY_VIEWED: 'recently_viewed',
  CONTINUE_PURCHASE: 'continue_purchase',
  RECENTLY_VIEWED_CLICKED: 'recently_viewed_clicked',
  CONTINUE_PURCHASE_CLICKED: 'continue_purchase_clicked',
  HOMEPAGE_CONTAINER_CATEGORY_CLICKED: 'homepage_container_category_clicked',
  HOMEPAGE_CONTAINER_SUBCATEGORY_CLICKED: 'homepage_container_subcategory_clicked',
  HOMEPAGE_CONTAINER_PDP_CLICKED: 'homepage_container_pdp_clicked',
};

export const EVENT_ATTRIBUTE = {
  PAID_STATUS: 'paid_Status',
  EXPIRE_STATUS: 'expire_status',
  COURSE_ID: 'course_id',
  COURSE_NAME: 'course_name',
  LANGUAGE: 'language',
  LANGUAGE_ID: 'language_id',
  LANGUAGE_NAME: 'language_name',
  PACKAGES_COUNT: 'packages_count',
  COURSE_TYPE: 'course_type',
  COURSE_TYPE_NAME: 'course_type_name',
  COURSE_TYPE_ID: 'course_type_id',
  PACKAGE_NAME: 'package_name',
  PACKAGE_ID: 'package_id',
  PACKAGE_PRICE: 'package_price',
  PACKAGE_DISCOUNT: 'package_discount',
  PACKAGE_DISCOUNT_TYPE: 'package_discount_type',
  PACKAGE_DISCOUNT_VALUE: 'package_discount_value',
  PACKAGE_DISCOUNT_PERCENTAGE: 'package_discount_percentage',
  PACKAGE_DISCOUNT_AMOUNT: 'package_discount_amount',
  SELECTED_PACKAGE: 'selected_package',
  OFFER_APPLIED: 'offer_applied',
  FIRST_INSTALLMENT_AMOUNT: 'first_installment_amount',
  OFFER_TRIED: 'offer_tried',
  TAB_SELECTED: 'tab_selected',
  SOURCE_SCREEN: 'source_screen',
  PLATFORM: 'Platform',
  PLATFORM1: 'platform_1',
  OS: 'os_version',
  Browser: 'browser_details',
  VALIDITY: 'validity',
  COURSE_STATUS: 'course_status',
  COURSE_CATEGORY: 'course_category',
  TOTAL_COURSES_COUNT: 'total_courses_count',
  VALID_TILL: 'valid_till',
  COURSE_FOLDER: 'course_folder',
  FREE_COURSES_COUNT: 'free_courses_count',
  PAID_COURSES_COUNT: 'paid_courses_count',
  DEEPLINK_SOURCE: 'deeplink_source',
  EXAM_UPDATED: 'exam_updated',
  ERROR_MSG: 'error_msg',
  USER_EXAM_SELECTED: 'user_exam_selected',
  UPDATED_EMAIL: 'updated_email',
  OLD_EMAIL: 'old_email',
  UPDATED_MOBILE: 'updated_mobile',
  OLD_MOBILE: 'old_mobile',
  ORDER_ID: 'order_id',
  DOWNLOAD_STATUS: 'download_status',
  IS_PROFILE_UPDATED: 'is_profile_updated',
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm_medium',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_CONTENT: 'utm_content',
  UTM_TERM: 'utm_term',
  GCLID: 'gclid',
  CAMPAIGN_ID: 'campaign_id',
  SOURCE_CAMPAIGN_NAME: 'source_campaign_name',
  EXPIRES_IN: 'expires_in',
  TIME_SPENT: 'time_spent',
  POSITION: 'position',
  FEED_LANGUAGE: 'feed_language',
  BUTTON_SOURCE: 'button_source',
  CROSS_SELL_ID: 'cross_sell_id',
  CROSS_SELL_NAME: 'cross_sell_name',
  PDP_TIME_SPENT: 'pdp_time_spent',
  SCREEN_NAME: 'screen_name',
  COUPON_CODE: 'coupon_code',
  DISCOUNT_AMOUNT: 'discount_amount',
  SELECTED_GATEWAY: 'selected_gateway',
  SELECTED_OPTION: 'selected_option',
  FINAL_PRICE: 'final_price',
  PAYMENT_TYPE: 'payment_type',
  PRIMARY_COUPON_APPLIED: 'primary_coupon_applied',
  PRIMARY_COURSE_PRICE: 'primary_course_price',
  PRIMARY_COURSE_TITLE: 'primary_course_title',
  PRIMARY_VALIDITY: 'primary_validity',
  ADDON_COURSE_CATEGORY: 'addon_course_category',
  ADDON_COURSE_PRICE: 'addon_course_price',
  ADDON_COURSE_ID: 'addon_course_id',
  ADDON_COURSE_TITLE: 'addon_course_title',
  ADDON_VALIDITY: 'addon_validity',
  COUPON_STATUS: 'coupon_status',
  OFFER_TYPE: 'offer_type',
  IS_MANUAL: 'is_manual',
  ERRORMSG: 'error_msg',
  OFFERS_COUNT: 'offers_count',
  IS_EMI_APPLIED: 'is_emi_applied',
  ADDON_COUNT: 'addon_count',
  TOTAL_PRICE: 'total_price',
  TOTAL_DISCOUNT: 'total_discount',
  ADD_ON_TOTAL_PRICE: 'add_on_total_price',
  STATUS: 'status',
  IS_COURSE_FREE: 'is_course_free',
  COURSE_SELLINGPRICE: 'course_sellingprice',
  LANGUAGE_SELECTED: 'language_selected',
  USER_TYPE: 'user_type',
  COURSE_PURCHASED: 'course_purchased',
};

export const getCurrentUserProfile = async (isAuthenticated, dispatch = null) => {
  const cookies = new Cookie();
  const { userID, apiToken } = cookies.getAll();

  // const formData = new FormData();
  // formData.append('user_id', userID);
  // formData.append('api_token', apiToken);

  try {
    let response;
    if (userID && (apiToken !== 'null' || apiToken !== undefined) && !isAuthenticated) {
      response = await javaApi.get('app-content-ws/api/web/user/profile', {
        headers: { Authorization: apiToken },
      });
    }
    if (!userID || !apiToken) return false;

    if (!response && response?.data?.success !== 1) return false;

    if (dispatch) {
      dispatch({
        type: 'auth/authUserProfile/fulfilled',
        payload: {
          profile: response?.data?.data,
          purchaseHistory: response?.data?.purchaseHistory,
        },
      });
    }

    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const trackMoengageEvent = async (event, attributes, isAuthenticated) => {
  try {
    const timer = setInterval(async () => {
      if (
        typeof Moengage === 'undefined' ||
        // eslint-disable-next-line no-undef
        Moengage?.track_event === 'undefined'
      ) {
        return;
      }

      // Clear Interval
      clearInterval(timer);

      // eslint-disable-next-line no-param-reassign
      attributes = attributes || {};

      if (typeof attributes.is_paid_user === 'undefined') {
        const profile = await getCurrentUserProfile(isAuthenticated);

        if (profile) {
          attributes.user_id = profile.id;
          attributes.user_type = profile.userType;
          attributes.user_email = profile.email;
          attributes.user_mobile = profile.mobileNumber;
          attributes.expired_count = profile.expiredCount;
          attributes.active_count = profile.activeCount;
          attributes.is_paid_user = profile.paidStatus ? 'yes' : 'no';
          attributes.user_state = profile.state;
          attributes.user_name = `${profile?.firstName}${profile?.lastName ? ` ${profile?.lastName}` : ''}`;
          attributes.user_city = profile.city;
        }
      }

      attributes[EVENT_ATTRIBUTE.PLATFORM] = 'web';
      attributes[EVENT_ATTRIBUTE.PLATFORM1] = getDeviceType();
      attributes[EVENT_ATTRIBUTE.OS] = getOSDetails();
      attributes[EVENT_ATTRIBUTE.Browser] = getBrowserDetails();

      if (getAllUTMParams().utmSource) {
        attributes[EVENT_ATTRIBUTE.UTM_SOURCE] = getAllUTMParams().utmSource;
      }

      if (getAllUTMParams().utmMedium) {
        attributes[EVENT_ATTRIBUTE.UTM_MEDIUM] = getAllUTMParams().utmMedium;
      }

      if (getAllUTMParams().utmCampaign) {
        attributes[EVENT_ATTRIBUTE.UTM_CAMPAIGN] = getAllUTMParams().utmCampaign;
      }

      if (getAllUTMParams().utmContent) {
        attributes[EVENT_ATTRIBUTE.UTM_CONTENT] = getAllUTMParams().utmContent;
      }

      if (getAllUTMParams().utmTerm) {
        attributes[EVENT_ATTRIBUTE.UTM_TERM] = getAllUTMParams().utmTerm;
      }

      if (getAllUTMParams().gclid) {
        attributes[EVENT_ATTRIBUTE.GCLID] = getAllUTMParams().gclid;
      }

      if (getAllUTMParams().campaignId) {
        attributes[EVENT_ATTRIBUTE.CAMPAIGN_ID] = getAllUTMParams().campaignId;
      }

      if (getAllUTMParams().sourceCampaignName) {
        attributes[EVENT_ATTRIBUTE.SOURCE_CAMPAIGN_NAME] = getAllUTMParams().sourceCampaignName;
      }

      const removeNullUndefined = Object.keys(attributes).reduce((acc, key) => {
        const newObject = acc;
        if (attributes[key] !== undefined) newObject[key] = attributes[key];
        return newObject;
      }, {});

      // eslint-disable-next-line no-undef
      Moengage.track_event(event, removeNullUndefined);
    }, 100);
  } catch (e) {
    // continue regardless of error
  }
};

export const useMoengageEventTracker = (defaultAttrs = {}) => {
  const dispatch = useDispatch();

  const { authAction, isAuthenticated, authUser } = useSelector(
    (state) => ({
      isAuthenticated: state.auth.isAuthenticated,
      authAction: state.auth.authAction,
      authUser: state.auth.authUser,
    }),
    shallowEqual,
  );

  return React.useCallback(
    async (event, attributes, ...attrs) => {
      try {
        // eslint-disable-next-line no-param-reassign
        attributes = attributes || {};

        if (isAuthenticated) {
          attributes.user_id = authUser.id;
          attributes.user_type = authUser.userType;
          attributes.user_email = authUser.email;
          attributes.user_mobile = authUser.mobileNumber;
          attributes.expired_count = authUser.expiredCount;
          attributes.user_name = `${authUser?.firstName}${authUser?.lastName ? ` ${authUser?.lastName}` : ''}`;
          attributes.active_count = authUser.activeCount;
          attributes.is_paid_user = authUser.paidStatus ? 'yes' : 'no';
          attributes.user_state = authUser.state;
          attributes.user_city = authUser.city;
        }

        if (typeof authUser?.paidStatus === 'undefined') {
          const profile = await getCurrentUserProfile(isAuthenticated, dispatch);

          if (profile) {
            attributes.user_id = profile.id;
            attributes.user_type = profile.userType;
            attributes.user_email = profile.email;
            attributes.user_name = profile.firstName;
            attributes.user_mobile = profile.mobileNumber;
            attributes.expired_count = profile.expiredCount;
            attributes.active_count = profile.activeCount;
            attributes.user_name = `${profile?.firstName}${profile?.lastName ? ` ${profile?.lastName}` : ''}`;
            attributes.is_paid_user = profile.paidStatus ? 'yes' : 'no';
            attributes.user_state = profile.state;
            attributes.user_city = profile.city;
          }
        }
        attributes.user_type = attributes.user_id || authAction === 1 ? 'registered' : 'guest';

        trackMoengageEvent(event, { ...defaultAttrs, ...attributes }, ...attrs, isAuthenticated);
      } catch (e) {
        // continue regardless of error
      }
    },
    [
      authAction,
      authUser?.activeCount,
      authUser?.email,
      authUser?.expiredCount,
      authUser?.id,
      authUser?.mobileNumber,
      authUser?.paidStatus,
      authUser?.state,
      authUser?.userType,
      authUser?.city,
      authUser?.firstName,
      authUser?.lastName,
      dispatch,
      isAuthenticated,
      defaultAttrs,
    ],
  );
};
