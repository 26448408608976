/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { javaApi, lcsServiceAPI, mediaUploaderAPI } from '../../utils/AxiosInstance';
import { objToCamelCase } from '../../utils/helper';

const initialState = {
  loading: false,
  languageModal: false,
  showPinned: false,
  reportModal: {
    show: false,
    data: {},
  },
  deleteCommentModal: {
    show: false,
    data: {},
  },
  attachmentModal: {
    show: false,
    data: {},
  },
  answerWritingModal: {
    show: false,
    data: {},
  },
  attachmentErrorModal: {
    show: false,
    data: {},
  },
  shareFeedModal: {
    show: false,
    data: {},
  },
  feedExpandModal: {
    show: false,
    data: {},
    from: '',
  },
  feedLanguagesDetails: {
    data: [],
    loading: false,
    error: false,
  },
  feedTypeDetails: {
    data: [],
    loading: false,
    error: false,
  },
  trendingFeedDetails: {
    data: [],
    loading: false,
    error: false,
  },
  feedContentData: {
    data: [],
    loading: false,
    error: false,
  },
  selectedFeedContentData: {
    data: {},
    loading: true,
    error: false,
  },
  feedContentDataReducer: {
    data: [],
    loading: false,
    error: false,
  },
  feedContentCommentReducer: {
    data: [],
    loading: false,
    error: false,
  },
  reportReasonDetails: {
    data: [],
    loading: false,
    error: false,
  },
  reportComment: {
    data: {},
    loading: false,
    error: false,
  },
  deleteComment: {
    data: {},
    loading: false,
    error: false,
  },
  contentUpload: {
    data: {},
    loading: false,
    error: false,
  },
  likeReaction: {
    data: {},
    loading: false,
    error: false,
  },
  commentCreate: {
    data: {},
    loading: false,
    error: false,
  },
  updateComment: {
    data: {},
    loading: false,
    error: false,
  },
  postComment: {
    data: {},
    loading: false,
    error: false,
  },
  getAllSubComment: {
    data: {},
    loading: false,
    error: false,
  },
  reportSubComment: {
    data: {},
    loading: false,
    error: false,
  },
  deleteSubComment: {
    data: {},
    loading: false,
    error: false,
  },
  createSubComment: {
    data: {},
    loading: false,
    error: false,
  },
  replyCommentClick: {
    data: {},
    loading: false,
    error: false,
  },
  pinnedComment: {
    data: {},
    loading: false,
    error: false,
  },
  getAllComment: {
    data: {},
    loading: false,
    error: false,
  },
  error: null,
};
export const getFeedContent = createAsyncThunk(
  'feed/getFeedContent',
  async ({ selectedType, selectedLanguage, page }, thunkAPI) => {
    try {
      const res = await javaApi.get(`/app-content-ws/api/v4/feed/content?feedType=${selectedType?.type_id === 'ALL' ? '' : selectedType?.type_id}&languageId=${selectedLanguage?.toUpperCase()}&page=${page}&pageSize=20`);
      if (res?.data?.success) {
        return { ...res?.data };
      }
      return thunkAPI.rejectWithValue(res?.msg);
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);
export const getSelectedFeedContent = createAsyncThunk(
  'feed/getSelectedFeedContent',
  async ({ feedType, feedId }, thunkAPI) => {
    try {
      const res = await javaApi.get(`/app-content-ws/api/v4/feed/getone?feedId=${feedId}&feedType=${feedType}`);
      if (res?.data?.success) {
        const finalResponse = res?.data?.data;
        if (res?.data?.data?.content_link) {
          try {
            const response = await fetch(res?.data?.data?.content_link);
            const htmlData = await response.text();
            finalResponse.content_link_html = htmlData;
          } catch (error) {
            console.error(error);
          }
        }

        return objToCamelCase(res?.data?.data);
      }
      return thunkAPI.rejectWithValue(res?.data?.response);
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const fetchFeedLanguages = createAsyncThunk(
  'Feed/fetchFeedLanguages',
  async (payload, thunkAPI) => {
    try {
      const response = await javaApi.get('app-content-ws/api/v1/get/languages?isApp=false');
      if (response?.data?.success) return response.data.data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const fetchFeedTypes = createAsyncThunk(
  'Feed/fetchFeedTypes',
  async (payload, thunkAPI) => {
    try {
      const response = await javaApi.get('/app-content-ws/api/v2/feed/types');
      if (response?.data?.success) {
        const feedData = response.data.data;
        const feedTypes = feedData?.feed_types?.map((elem) => ({
          ...elem,
          type_slug: elem?.type_name?.toLowerCase()?.replace(/ /g, '-'),
        }));
        const feedDataWithAll = [
          {
            type_name: 'All',
            type_id: 'ALL',
            type_slug: 'all',
          },
          ...feedTypes,
        ];
        feedData.feed_types = feedDataWithAll;

        return feedData;
      }

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const fetchReportReasons = createAsyncThunk(
  'Feed/fetchReportReasons',
  async (payload, thunkAPI) => {
    try {
      const response = await lcsServiceAPI.get('reportReasons');
      if (response?.data?.response?.reportReasons) return response?.data?.response?.reportReasons;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const commentReport = createAsyncThunk(
  'Feed/commentReport',
  async ({ commentId, reasonId }, thunkAPI) => {
    try {
      const response = await lcsServiceAPI.post('comment/report', {
        commentId,
        reasonId,
      });
      if (response?.data?.status === 200) return response?.data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);
export const likePost = createAsyncThunk(
  'Feed/likePost',
  async ({ discussionId, active, likeType }, thunkAPI) => {
    try {
      const response = await lcsServiceAPI.post('discussion/like', { discussionId, active, likeType });
      if (response?.data?.status === 200) return response?.data?.response;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const fetchTrendingFeed = createAsyncThunk(
  'Feed/fetchTrendingFeed',
  async (payload, thunkAPI) => {
    try {
      const response = await javaApi.get('/app-content-ws/api/v3/trendingfeed');
      if (response?.data?.success) {
        const filterData = response.data.data.map((feed) => ({
          ...feed,
          feedId: feed?.feed_id,
          title: feed?.feed_title,
          feedType: feed?.feed_type,
          lcsData: feed?.lcs_data,
          trendingFeed: true,
          referenceId: feed?.reference_id,
        }));

        return filterData;
      }

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const commentDelete = createAsyncThunk(
  'Feed/commentDelete',
  async ({ commentId }, thunkAPI) => {
    try {
      const response = await lcsServiceAPI.delete(`comment/delete/${commentId}`);
      if (response?.data?.status === 200) return response?.data?.response;
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);
// mediaUploaderAPI

export const contentMediaUploader = createAsyncThunk(
  'Feed/contentMediaUploader',
  async (payload, thunkAPI) => {
    try {
      const formData = new FormData();
      payload.map((item) => formData.append('File', item));
      const attachmentArray = [];
      for (let i = 0; i < payload.length; i++) {
        const formDataImg = new FormData();
        formDataImg.append('File', payload[i]);
        try {
          const attachmentRes = await mediaUploaderAPI.post('/v1/media/upload', formDataImg);
          if (attachmentRes?.data?.status === 200) {
            attachmentArray.push({
              attachmentLink: attachmentRes?.data?.response?.optimizedMediaUrl,
              attachmentType: attachmentRes?.data?.response?.mediaType,
            });
          }
        } catch (err) {
          console.log('error::', err);
        }
      }
      if (attachmentArray?.length > 0) {
        return attachmentArray;
      }
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const contentUpload = createAsyncThunk(
  'Feed/contentUpload',
  async (payload, thunkAPI) => {
    try {
      const formData = new FormData();
      payload.map((item) => formData.append('files', item));
      const response = await lcsServiceAPI.post('content/upload', formData);
      if (response?.data?.status === 200) return response?.data?.response;
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const commentCreate = createAsyncThunk(
  'Feed/commentCreate',
  async ({ businessRefId, attachments, content }, thunkAPI) => {
    try {
      const payload = {
        businessRefId,
        attachments,
        content,
      };
      const response = await lcsServiceAPI.post('comment/create', payload);
      if (response?.data?.status === 200) return response?.data;
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const updateComment = createAsyncThunk(
  'Feed/updateComment',
  async ({ commentId, attachments, content }, thunkAPI) => {
    try {
      const payload = {
        commentId,
        attachments,
        content,
      };
      const response = await lcsServiceAPI.post(`update/comment/${commentId}`, payload);
      if (response?.data?.status === 200) return response?.data;
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const getCommentOnPost = createAsyncThunk(
  'Feed/getCommentOnPost',
  async (payload, thunkAPI) => {
    try {
      const response = await lcsServiceAPI.post('comments/find/refrenceId', payload);
      if (response?.data?.status === 200) return response?.data?.response;
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const subCommentCreate = createAsyncThunk(
  'Feed/subCommentCreate',
  async (payload, thunkAPI) => {
    try {
      const response = await lcsServiceAPI.post('subcomment/create', payload);
      if (response?.data?.status === 200) return response?.data;
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const getSubCommentOnPost = createAsyncThunk(
  'Feed/getSubCommentOnPost',
  async (payload, thunkAPI) => {
    try {
      const response = await lcsServiceAPI.post('subcomment/find/commentId', payload);
      if (response?.data?.status === 200) return response?.data?.response;
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const subCommentDelete = createAsyncThunk(
  'Feed/subCommentDelete',
  async ({ subCommentId }, thunkAPI) => {
    try {
      const response = await lcsServiceAPI.delete(`subComment/DeleteFromTable/${subCommentId}`);
      if (response?.data?.status === 200) return response?.data?.response;
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const getPinnedComment = createAsyncThunk(
  'Feed/getPinnedComment',
  async ({ businessReferenceId }, thunkAPI) => {
    try {
      const response = await lcsServiceAPI.post('getPinnedComment', {
        businessReferenceId,
      });
      if (response?.data?.status === 200) return response?.data?.response;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const subCommentReport = createAsyncThunk(
  'Feed/subCommentReport',
  async ({ subCommentId, reasonId }, thunkAPI) => {
    try {
      const response = await lcsServiceAPI.post('subComment/report', {
        subCommentId,
        reasonId,
      });
      if (response?.data?.status === 200) return response?.data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const getAllCommentList = createAsyncThunk(
  'Feed/getAllCommentList',
  async ({ limit, lastEvaluatedKey }, thunkAPI) => {
    try {
      const response = await lcsServiceAPI.post('getAllCommentList', {
        limit,
        lastEvaluatedKey,
      });
      if (response?.data?.status === 200) return response?.data?.response;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

// Slice
const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    getFeedContentData: (state, action) => {
      const { data } = action.payload;
      state.feedContentDataReducer.data = data;
    },
    replyCommentData: (state, action) => {
      const { data } = action.payload;
      state.replyCommentClick.data = data;
    },
    getFeedContentComment: (state, action) => {
      const { data } = action.payload;
      state.feedContentCommentReducer.data = data;
    },
    updateTrendingFeedData: (state, action) => {
      state.trendingFeedDetails.data = action.payload;
    },
    showLanguageModal: (state, action) => {
      const { show } = action.payload;
      state.languageModal = show;
    },
    showAttachmentModal: (state, action) => {
      const { show, data } = action.payload;
      state.attachmentModal.show = show;
      state.attachmentModal.data = data;
    },
    showReportModal: (state, action) => {
      const { show, data } = action.payload;
      state.reportModal.show = show;
      state.reportModal.data = data;
    },
    showDeleteCommentModal: (state, action) => {
      const { show, data } = action.payload;
      state.deleteCommentModal.show = show;
      state.deleteCommentModal.data = data;
    },
    showAnswerWritingModal: (state, action) => {
      const { show, data } = action.payload;
      state.answerWritingModal.show = show;
      state.answerWritingModal.data = data;
    },
    showAttachmentErrorModal: (state, action) => {
      const { show, data } = action.payload;
      state.attachmentErrorModal.show = show;
      state.attachmentErrorModal.data = data;
    },
    showShareFeedModal: (state, action) => {
      const { show, data } = action.payload;
      state.shareFeedModal.show = show;
      state.shareFeedModal.data = data;
    },
    showFeedExpandModal: (state, action) => {
      const { show, data, from } = action.payload;
      state.feedExpandModal.show = show;
      state.feedExpandModal.data = data;
      state.feedExpandModal.from = from;
    },
    showPinnedFun: (state, action) => {
      const { show } = action.payload;
      state.showPinned = show;
    },
    removePinnedComment: (state) => {
      state.pinnedComment = {};
    },
    resetSelectedFeedData: (state) => {
      state.selectedFeedContentData = {};
    },
    likeReactionCustom: () => {
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeedLanguages.pending, (state) => {
        state.feedLanguagesDetails = {
          ...state.feedLanguagesDetails,
          loading: true,
        };
      })
      .addCase(fetchFeedLanguages.fulfilled, (state, action) => {
        state.feedLanguagesDetails = {
          ...state.feedLanguagesDetails,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(fetchFeedLanguages.rejected, (state, action) => {
        state.feedLanguagesDetails = {
          ...state.feedLanguagesDetails,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(fetchFeedTypes.pending, (state) => {
        state.feedTypeDetails = {
          ...state.feedTypeDetails,
          loading: true,
        };
      })
      .addCase(fetchFeedTypes.fulfilled, (state, action) => {
        state.feedTypeDetails = {
          ...state.feedTypeDetails,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(fetchFeedTypes.rejected, (state, action) => {
        state.feedTypeDetails = {
          ...state.feedTypeDetails,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(fetchTrendingFeed.pending, (state) => {
        state.trendingFeedDetails = {
          ...state.trendingFeedDetails,
          loading: true,
        };
      })
      .addCase(fetchTrendingFeed.fulfilled, (state, action) => {
        state.trendingFeedDetails = {
          ...state.trendingFeedDetails,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(fetchTrendingFeed.rejected, (state, action) => {
        state.trendingFeedDetails = {
          ...state.trendingFeedDetails,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(getFeedContent.pending, (state) => {
        state.feedContentData = {
          ...state.feedContentData,
          loading: true,
          data: '',
        };
      })
      .addCase(getFeedContent.fulfilled, (state, action) => {
        state.feedContentData = {
          ...state.feedContentData,
          loading: false,
          data: action?.payload,
        };
      })
      .addCase(getFeedContent.rejected, (state, action) => {
        state.feedContentData = {
          ...state.feedContentData,
          loading: false,
          data: '',
          error: action?.payload,
        };
      })
      .addCase(getSelectedFeedContent.pending, (state) => {
        state.selectedFeedContentData = {
          ...state.selectedFeedContentData,
          loading: true,
          data: '',
        };
      })
      .addCase(getSelectedFeedContent.fulfilled, (state, action) => {
        state.selectedFeedContentData = {
          ...state.selectedFeedContentData,
          loading: false,
          data: action?.payload,
        };
      })
      .addCase(getSelectedFeedContent.rejected, (state, action) => {
        state.selectedFeedContentData = {
          ...state.selectedFeedContentData,
          loading: false,
          data: '',
          error: action?.payload,
        };
      })
      .addCase(fetchReportReasons.pending, (state) => {
        state.reportReasonDetails = {
          ...state.reportReasonDetails,
          loading: true,
        };
      })
      .addCase(fetchReportReasons.fulfilled, (state, action) => {
        state.reportReasonDetails = {
          ...state.reportReasonDetails,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(fetchReportReasons.rejected, (state, action) => {
        state.reportReasonDetails = {
          ...state.reportReasonDetails,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(commentReport.pending, (state) => {
        state.reportComment = {
          ...state.reportComment,
          loading: true,
        };
      })
      .addCase(commentReport.fulfilled, (state, action) => {
        state.reportComment = {
          ...state.reportComment,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(commentReport.rejected, (state, action) => {
        state.reportComment = {
          ...state.reportComment,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(commentDelete.pending, (state) => {
        state.deleteComment = {
          ...state.deleteComment,
          loading: true,
        };
      })
      .addCase(commentDelete.fulfilled, (state, action) => {
        state.deleteComment = {
          ...state.deleteComment,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(commentDelete.rejected, (state, action) => {
        state.deleteComment = {
          ...state.deleteComment,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(contentUpload.pending, (state) => {
        state.contentUpload = {
          ...state.contentUpload,
          loading: true,
        };
      })
      .addCase(contentUpload.fulfilled, (state, action) => {
        state.contentUpload = {
          ...state.contentUpload,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(contentUpload.rejected, (state, action) => {
        state.contentUpload = {
          ...state.contentUpload,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(contentMediaUploader.pending, (state) => {
        state.contentUpload = {
          ...state.contentUpload,
          loading: true,
        };
      })
      .addCase(contentMediaUploader.fulfilled, (state, action) => {
        state.contentUpload = {
          ...state.contentUpload,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(contentMediaUploader.rejected, (state, action) => {
        state.contentUpload = {
          ...state.contentUpload,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(commentCreate.pending, (state) => {
        state.commentCreate = {
          ...state.commentCreate,
          loading: true,
        };
      })
      .addCase(commentCreate.fulfilled, (state, action) => {
        state.commentCreate = {
          ...state.commentCreate,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(commentCreate.rejected, (state, action) => {
        state.commentCreate = {
          ...state.commentCreate,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(likePost.pending, (state) => {
        state.likeReaction = {
          ...state.likeReaction,
          loading: true,
        };
      })
      .addCase(likePost.fulfilled, (state, action) => {
        state.likeReaction = {
          ...state.likeReaction,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(likePost.rejected, (state, action) => {
        state.likeReaction = {
          ...state.likeReaction,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(updateComment.pending, (state) => {
        state.updateComment = {
          ...state.updateComment,
          loading: true,
        };
      })
      .addCase(updateComment.fulfilled, (state, action) => {
        state.updateComment = {
          ...state.updateComment,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(updateComment.rejected, (state, action) => {
        state.updateComment = {
          ...state.updateComment,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(getCommentOnPost.pending, (state) => {
        state.postComment = {
          ...state.postComment,
          loading: true,
        };
      })
      .addCase(getCommentOnPost.fulfilled, (state, action) => {
        state.postComment = {
          ...state.postComment,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(getCommentOnPost.rejected, (state, action) => {
        state.postComment = {
          ...state.postComment,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(getSubCommentOnPost.pending, (state) => {
        state.getAllSubComment = {
          ...state.getAllSubComment,
          loading: true,
        };
      })
      .addCase(getSubCommentOnPost.fulfilled, (state, action) => {
        state.getAllSubComment = {
          ...state.getAllSubComment,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(getSubCommentOnPost.rejected, (state, action) => {
        state.getAllSubComment = {
          ...state.getAllSubComment,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(subCommentDelete.pending, (state) => {
        state.deleteSubComment = {
          ...state.deleteSubComment,
          loading: true,
        };
      })
      .addCase(subCommentDelete.fulfilled, (state, action) => {
        state.deleteSubComment = {
          ...state.deleteSubComment,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(subCommentDelete.rejected, (state, action) => {
        state.deleteSubComment = {
          ...state.deleteSubComment,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(subCommentReport.pending, (state) => {
        state.reportSubComment = {
          ...state.reportSubComment,
          loading: true,
        };
      })
      .addCase(subCommentReport.fulfilled, (state, action) => {
        state.reportSubComment = {
          ...state.reportSubComment,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(subCommentReport.rejected, (state, action) => {
        state.reportSubComment = {
          ...state.reportSubComment,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(getPinnedComment.pending, (state) => {
        state.pinnedComment = {
          ...state.pinnedComment,
          loading: true,
        };
      })
      .addCase(getPinnedComment.fulfilled, (state, action) => {
        state.pinnedComment = {
          ...state.pinnedComment,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(getPinnedComment.rejected, (state, action) => {
        state.pinnedComment = {
          ...state.pinnedComment,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(subCommentCreate.pending, (state) => {
        state.createSubComment = {
          ...state.createSubComment,
          loading: true,
        };
      })
      .addCase(subCommentCreate.fulfilled, (state, action) => {
        state.createSubComment = {
          ...state.createSubComment,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(subCommentCreate.rejected, (state, action) => {
        state.createSubComment = {
          ...state.createSubComment,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(getAllCommentList.pending, (state) => {
        state.getAllComment = {
          ...state.getAllComment,
          loading: true,
        };
      })
      .addCase(getAllCommentList.fulfilled, (state, action) => {
        state.getAllComment = {
          ...state.getAllComment,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(getAllCommentList.rejected, (state, action) => {
        state.getAllComment = {
          ...state.getAllComment,
          loading: false,
          error: action.payload,
        };
      });
  },
});
// export const { showLanguageModal } = feedSlice.actions;

export const { replyCommentData, getFeedContentComment, getFeedContentData, showAttachmentModal, showLanguageModal, showReportModal, showDeleteCommentModal, showAnswerWritingModal, showShareFeedModal, showFeedExpandModal, likeReactionCustom, showPinnedFun, removePinnedComment, resetSelectedFeedData, updateTrendingFeedData, showAttachmentErrorModal } = feedSlice.actions;

export default feedSlice.reducer;
