/* eslint-disable max-len */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  getAuthState,
  javaAnswerWritingAPI,
  javaApi,
  javaAssessmentEvaluationAPI,
  javaQuestionServiceAPI,
  lcsServiceAPI,
  mediaUploaderAPI,
} from '../../utils/AxiosInstance';

const initialState = {
  assessmentList: {
    loading: true,
    data: [],
    error: null,
  },
  assessmentInstruction: {
    loading: true,
    data: [],
    error: null,
  },
  viewSubmission: {
    loading: true,
    data: {},
    error: null,
  },
  viewEvaluation: {
    loading: true,
    data: {},
    error: null,
  },
  mediaUploader: {
    loading: false,
    data: {},
    error: null,
  },
  submitExamData: {
    loading: true,
    data: {},
    error: null,
  },
  submitFeedBack: {
    loading: true,
    data: {},
    error: false,
  },
  modelAnswer: {
    loading: true,
    data: {},
    error: false,
  },
  denyReattemptEvl: {
    loading: true,
    data: {},
    error: false,
  },
  getAllOptionsData: {
    loading: true,
    data: {},
    error: false,
  },
  reviewCreateData: {
    loading: true,
    data: {},
    error: false,
  },
  ratingModal: {
    show: false,
    data: {},
  },
  getQuestionData: {
    loading: true,
    data: {},
    error: false,
  },
  userReviewData: {
    loading: true,
    data: {},
    error: false,
  },
};

// Actions
export const fetchAssessmentList = createAsyncThunk(
  'answerWriting/fetchAssessmentList',
  async ({ courseId, languageId, lessonId }, thunkAPI) => {
    try {
      const response = await javaApi.post('app-content-ws/answer/writing/assessmentList', {
        courseId,
        languageId,
        lessonId,
      });

      if (response?.data?.success) return response?.data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

// Actions
export const denyReattemptEvl = createAsyncThunk(
  'answerWriting/denyReattemptEvl',
  async ({ denied, denyReason, languageId, questionId, submissionId }, thunkAPI) => {
    const payload = {
      denied: denied || null,
      denyReason: denyReason || null,
      languageId: languageId || null,
      questionId: questionId || null,
      submissionId: submissionId || null,
    };
    try {
      const { authToken } = await getAuthState();
      const response = await javaAssessmentEvaluationAPI.post('api/v1/evaluation/deny/re-attempt', payload, {
        headers: {
          'jwt-token': authToken,
          'business-name': 'StudyIq',
          'Content-Type': 'application/json',
        },
      });
      if (response?.status === 200) return response?.data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const reviewCreate = createAsyncThunk(
  'answerWriting/reviewCreate',
  async ({ options, buisenessName }, thunkAPI) => {
    try {
      const response = await lcsServiceAPI.post('review/create', options, { buisenessName });
      if (response?.status === 200) return response?.data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const getAllOptions = createAsyncThunk(
  'answerWriting/getAllOptions',
  async ({ contentId, businessName }, thunkAPI) => {
    try {
      const response = await lcsServiceAPI.get(
        `review/fetch/all-options${contentId ? `?contentId=${contentId}` : ''}`,
        { buisenessName: businessName || 'StudyIQAssessmentService' },
      );
      if (response?.status === 200) return response?.data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const fetchModalVisibility = createAsyncThunk(
  'fetchModalVisibility',
  async ({ courseId, feedbackServiceId }, thunkAPI) => {
    try {
      const response = await javaApi.get(
        `app-content-ws/api/v1/getPurchasedCourse/schedule/rule?courseId=${courseId}&feedbackServiceId=${feedbackServiceId}`,
      );
      if (response?.status === 200) return response?.data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const getUserReview = createAsyncThunk(
  'answerWriting/getUserReview',
  async ({ userId, assessmentId, parentOptionId }, thunkAPI) => {
    try {
      const response = await lcsServiceAPI.get(
        `review/get-client-user-review?userId=${userId}&contentUniqueId=${assessmentId}-${parentOptionId}`,
        { buisenessName: 'StudyIQAssessmentService' },
      );
      if (response?.status === 200) return response?.data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const fetchAssessmentInstruction = createAsyncThunk(
  'answerWriting/fetchAssessmentInstruction',
  async ({ languageId, assessmentIds }, thunkAPI) => {
    const { authToken } = await getAuthState();
    try {
      const response = await javaAnswerWritingAPI.post(
        'api/v1/assessments/get/bulk',
        {
          languageId,
          assessmentIds,
        },
        {
          headers: {
            'jwt-token': authToken,
            'business-name': 'StudyIq',
            'Content-Type': 'application/json',
          },
        },
      );

      if (response?.data?.status === 200) return response?.data?.response;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const assessmentAttempt = createAsyncThunk('answerWriting/assessmentAttempt', async (payload, thunkAPI) => {
  const { authToken } = await getAuthState();
  try {
    const response = await javaAssessmentEvaluationAPI.post('api/v1/evaluation/attempt', payload, {
      headers: {
        'jwt-token': authToken,
        'business-name': 'StudyIq',
        'Content-Type': 'application/json',
      },
    });

    if (response?.data?.status === 200) {
      return response?.data;
    }

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const submitFeedback = createAsyncThunk('answerWriting/submitFeedback', async (payload, thunkAPI) => {
  const { authToken } = await getAuthState();
  try {
    const response = await javaAssessmentEvaluationAPI.post('api/v1/evaluation/feedback', payload, {
      headers: {
        'jwt-token': authToken,
        'business-name': 'StudyIq',
        'Content-Type': 'application/json',
      },
    });

    if (response?.data?.status === 200) return response?.data;

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const viewSubmission = createAsyncThunk('answerWriting/viewSubmission', async (payload, thunkAPI) => {
  const { authToken } = await getAuthState();
  try {
    const response = await javaAssessmentEvaluationAPI.post('api/v1/evaluation/view/submissionAndEvaluation', payload, {
      headers: {
        'jwt-token': authToken,
        'business-name': 'StudyIq',
        'Content-Type': 'application/json',
      },
    });

    if (response?.data?.status === 200) {
      return response?.data?.response?.response;
    }

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const viewEvaluation = createAsyncThunk('answerWriting/viewEvaluation', async (payload, thunkAPI) => {
  try {
    const response = await javaAssessmentEvaluationAPI.post('api/v1/evaluation/viewEvaluation', payload);

    if (response?.data?.success) return response?.data;

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const uploadAttachments = createAsyncThunk('answerWriting/uploadAttachments', async (payload, thunkAPI) => {
  try {
    const response = await mediaUploaderAPI.post('v1/media/upload', payload);

    if (response?.data?.status === 200) return response?.data?.response;

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const submitExam = createAsyncThunk('answerWriting/submitExam', async (payload, thunkAPI) => {
  const { authToken } = await getAuthState();
  try {
    const response = await javaAssessmentEvaluationAPI.post('api/v1/evaluation/submit', payload, {
      headers: {
        'jwt-token': authToken,
        'business-name': 'StudyIq',
        'Content-Type': 'application/json',
      },
    });

    if (response?.data?.status === 200) {
      return response?.data;
    }

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getModelAnswer = createAsyncThunk('answerWriting/modelAnswer', async (params, thunkAPI) => {
  const { authToken } = await getAuthState();
  try {
    const response = await javaQuestionServiceAPI.get('question/getQuestionAndAnswer', {
      params,
      headers: {
        'jwt-token': authToken,
        'business-name': 'StudyIq',
        'Content-Type': 'application/json',
      },
    });

    if (response?.data?.status === 200) {
      return response?.data?.response?.response;
    }

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const getQuestion = createAsyncThunk('answerWriting/getQuestion', async (params, thunkAPI) => {
  const { authToken } = await getAuthState();
  try {
    const response = await javaQuestionServiceAPI.get('question/getQuestion', {
      params,
      headers: {
        'jwt-token': authToken,
        'business-name': 'StudyIq',
        'Content-Type': 'application/json',
      },
    });
    if (response?.data?.success) {
      return response?.data?.data;
    }

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

// Slice
const answerWritingSlice = createSlice({
  name: 'answerWriting',
  initialState,
  reducers: {
    showRatingModal: (state, action) => {
      const { show } = action.payload;
      state.ratingModal.show = show;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssessmentList.pending, (state) => {
        state.assessmentList = {
          ...state.assessmentList,
          loading: true,
        };
      })
      .addCase(fetchAssessmentList.fulfilled, (state, action) => {
        state.assessmentList = {
          ...state.assessmentList,
          loading: false,
          data: action.payload.data,
        };
      })
      .addCase(fetchAssessmentList.rejected, (state, action) => {
        state.assessmentList = {
          ...state.assessmentList,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(fetchAssessmentInstruction.pending, (state) => {
        state.assessmentInstruction = {
          ...state.assessmentInstruction,
          loading: true,
        };
      })
      .addCase(fetchAssessmentInstruction.fulfilled, (state, action) => {
        state.assessmentInstruction = {
          ...state.assessmentInstruction,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(fetchAssessmentInstruction.rejected, (state, action) => {
        state.assessmentInstruction = {
          ...state.assessmentInstruction,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(viewSubmission.pending, (state) => {
        state.viewSubmission = {
          ...state.viewSubmission,
          loading: true,
        };
      })
      .addCase(viewSubmission.fulfilled, (state, action) => {
        state.viewSubmission = {
          ...state.viewSubmission,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(viewSubmission.rejected, (state, action) => {
        state.viewSubmission = {
          ...state.viewSubmission,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(viewEvaluation.pending, (state) => {
        state.viewEvaluation = {
          ...state.viewEvaluation,
          loading: true,
        };
      })
      .addCase(viewEvaluation.fulfilled, (state, action) => {
        state.viewEvaluation = {
          ...state.viewEvaluation,
          loading: false,
          data: action.payload.data,
        };
      })
      .addCase(viewEvaluation.rejected, (state, action) => {
        state.viewEvaluation = {
          ...state.viewEvaluation,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(uploadAttachments.pending, (state) => {
        state.mediaUploader = {
          ...state.mediaUploader,
          loading: true,
        };
      })
      .addCase(uploadAttachments.fulfilled, (state, action) => {
        state.mediaUploader = {
          ...state.mediaUploader,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(uploadAttachments.rejected, (state, action) => {
        state.mediaUploader = {
          ...state.mediaUploader,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(submitExam.pending, (state) => {
        state.submitExamData = {
          ...state.submitExamData,
          loading: true,
        };
      })
      .addCase(submitExam.fulfilled, (state, action) => {
        state.submitExamData = {
          ...state.submitExamData,
          loading: false,
          data: action.payload?.response?.response,
        };
      })
      .addCase(submitExam.rejected, (state, action) => {
        state.submitExamData = {
          ...state.submitExamData,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(submitFeedback.pending, (state) => {
        state.submitFeedBack = {
          ...state.submitFeedBack,
          loading: true,
        };
      })
      .addCase(submitFeedback.fulfilled, (state, action) => {
        state.submitFeedBack = {
          ...state.submitFeedBack,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(submitFeedback.rejected, (state, action) => {
        state.submitFeedBack = {
          ...state.submitFeedBack,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(getModelAnswer.pending, (state) => {
        state.modelAnswer = {
          ...state.modelAnswer,
          loading: true,
        };
      })
      .addCase(getModelAnswer.fulfilled, (state, action) => {
        state.modelAnswer = {
          ...state.modelAnswer,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(getModelAnswer.rejected, (state, action) => {
        state.modelAnswer = {
          ...state.modelAnswer,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(denyReattemptEvl.pending, (state) => {
        state.denyReattemptEvl = {
          ...state.denyReattemptEvl,
          loading: true,
        };
      })
      .addCase(denyReattemptEvl.fulfilled, (state, action) => {
        state.denyReattemptEvl = {
          ...state.denyReattemptEvl,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(denyReattemptEvl.rejected, (state, action) => {
        state.denyReattemptEvl = {
          ...state.denyReattemptEvl,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(getAllOptions.pending, (state) => {
        state.getAllOptionsData = {
          ...state.getAllOptionsData,
          loading: true,
        };
      })
      .addCase(getAllOptions.fulfilled, (state, action) => {
        state.getAllOptionsData = {
          ...state.getAllOptionsData,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(getAllOptions.rejected, (state, action) => {
        state.getAllOptionsData = {
          ...state.getAllOptionsData,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(reviewCreate.pending, (state) => {
        state.reviewCreateData = {
          ...state.reviewCreateData,
          loading: true,
        };
      })
      .addCase(reviewCreate.fulfilled, (state, action) => {
        state.reviewCreateData = {
          ...state.reviewCreateData,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(reviewCreate.rejected, (state, action) => {
        state.reviewCreateData = {
          ...state.reviewCreateData,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(getQuestion.pending, (state) => {
        state.getQuestionData = {
          ...state.getQuestionData,
          loading: true,
        };
      })
      .addCase(getQuestion.fulfilled, (state, action) => {
        state.getQuestionData = {
          ...state.getQuestionData,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(getQuestion.rejected, (state, action) => {
        state.getQuestionData = {
          ...state.getQuestionData,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(getUserReview.pending, (state) => {
        state.userReviewData = {
          ...state.userReviewData,
          loading: true,
        };
      })
      .addCase(getUserReview.fulfilled, (state, action) => {
        state.userReviewData = {
          ...state.userReviewData,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(getUserReview.rejected, (state, action) => {
        state.userReviewData = {
          ...state.userReviewData,
          loading: false,
          error: action.payload,
        };
      });
  },
});

export const { showRatingModal } = answerWritingSlice.actions;
export default answerWritingSlice.reducer;
