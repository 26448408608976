import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { javaApi } from '../../utils/AxiosInstance';

const initialState = {
  attachmentUrl: {
    loading: true,
    data: '',
    error: null,
  },
  issueList: {
    loading: true,
    data: [],
    error: null,
  },
  subIssuesList: {
    loading: true,
    data: [],
    error: null,
  },
};

export const uploadAttachment = createAsyncThunk(
  'upload-attachment',
  async (payload, thunkAPI) => {
    try {
      const response = await javaApi.post(
        'common-ws/v1/user-feedback/upload-attachment?productType=1',
        payload,
      );

      if (response?.status === 200) {
        return response?.data?.data;
      }
      return thunkAPI.rejectWithValue(response?.msg);
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const getIssues = createAsyncThunk('get-issues', async (thunkAPI) => {
  try {
    const response = await javaApi.get(
      'common-ws/v1/feedback-section/fetch?contentType=10&productType=1&source=USER',
    );

    if (response?.status === 200) {
      return response?.data?.data?.issues?.[0]?.subIssues;
    }
    return thunkAPI.rejectWithValue(response?.msg);
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const getSubIssues = createAsyncThunk(
  'get-subIssues',
  async (subIssueId, thunkAPI) => {
    try {
      const response = await javaApi.get(
        `common-ws/v1/feedback-section/getSubIssue?subIssueId=${subIssueId}&source=USER`,
      );

      if (response?.status === 200) {
        return response?.data?.data?.mappedSubIssues;
      }
      return thunkAPI.rejectWithValue(response?.msg);
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const saveFeedback = createAsyncThunk(
  'save-feedback',
  async (payload, thunkAPI) => {
    try {
      const response = await javaApi.post(
        'common-ws/v1/user-feedback/save',
        payload,
      );

      console.log('Error is this', response);

      if (response?.status === 200) {
        return response?.data;
      }
      return thunkAPI.rejectWithValue(response?.data);
    } catch (error) {
      return error?.response?.data;
    }
  },
);

const FeedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(uploadAttachment.pending, (state) => {
        state.attachmentUrl = {
          ...state.attachmentUrl,
          loading: true,
        };
      })
      .addCase(uploadAttachment.fulfilled, (state, action) => {
        state.attachmentUrl = {
          ...state.attachmentUrl,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(uploadAttachment.rejected, (state, action) => {
        state.attachmentUrl = {
          ...state.attachmentUrl,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(getIssues.pending, (state) => {
        state.issueList = {
          ...state.issueList,
          loading: true,
        };
      })
      .addCase(getIssues.fulfilled, (state, action) => {
        state.issueList = {
          ...state.issueList,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(getIssues.rejected, (state, action) => {
        state.issueList = {
          ...state.issueList,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(getSubIssues.pending, (state) => {
        state.subIssuesList = {
          ...state.subIssuesList,
          loading: true,
        };
      })
      .addCase(getSubIssues.fulfilled, (state, action) => {
        state.subIssuesList = {
          ...state.subIssuesList,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(getSubIssues.rejected, (state, action) => {
        state.subIssuesList = {
          ...state.subIssuesList,
          loading: false,
          error: action.payload,
        };
      });
  },
});

export default FeedbackSlice.reducer;
