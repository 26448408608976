/* eslint-disable no-restricted-syntax, no-plusplus, no-restricted-globals */
import camelCase from 'lodash.camelcase';
import mapKeys from 'lodash.mapkeys';

import { javaApi } from './AxiosInstance';
import { getOfflineCentreData } from './commonApiUtils';

export const EMAIL_ADDRESS_REGEX =
  // eslint-disable-next-line no-useless-escape,max-len
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmailAddress = (emailAddress) => EMAIL_ADDRESS_REGEX.test(emailAddress);

export const pluralVal = (val, single, plural, requiredValue = false) => {
  const absVal = parseInt(val, 10);
  if (absVal <= 0) return requiredValue ? `${absVal} ${single}` : null;

  return `${absVal} ${absVal > 1 ? plural : single}`;
};

export const ordinalSuffixOf = (i, sup = true) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return sup ? 'ˢᵗ' : 'st';
  }
  if (j === 2 && k !== 12) {
    return sup ? 'ⁿᵈ' : 'nd';
  }
  if (j === 3 && k !== 13) {
    return sup ? 'ʳᵈ' : 'rd';
  }
  return sup ? 'ᵗʰ' : 'th';
};

export const getParameterByName = (name) => {
  const query = window.location.search.toString();
  // eslint-disable-next-line no-param-reassign, no-useless-escape
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regexS = `[\\?&]${name}=([^&#]*)`;
  const regex = new RegExp(regexS);
  const results = regex.exec(query);
  if (results == null) return '-';

  return decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const courseExpireDate = (timestamp) => {
  try {
    if (!timestamp) return null;

    // Replace hyphens with slashes
    const formattedTimestamp = timestamp.replaceAll('-', '/');

    // Attempt to create a date object
    const date = new Date(formattedTimestamp);

    if (isNaN(date)) {
      throw new Error('Invalid timestamp format');
    }

    let dateStr = `${date.getDate()}`;
    dateStr += `${ordinalSuffixOf(date.getDate())} `;
    dateStr += date.toLocaleString('default', {
      month: 'long',
      year: 'numeric',
    });

    return dateStr;
  } catch (error) {
    return null; // Return null in case of an error
  }
};

export const getDeviceType = () => {
  if (typeof navigator !== 'undefined') {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua) &&
      screen?.width <= 767
    ) {
      return 'mweb';
    }

    return 'web';
  }

  return null;
};

export const getDeviceTypeAndroid = (userAgent) => /(android)/i.test(userAgent) && screen?.width <= 767;

export const getDeviceTypeMweb = (userAgent) => /(android|iphone)/i.test(userAgent) && screen?.width <= 767;

export const getOSDetails = () => {
  if (typeof navigator !== 'undefined') {
    const ua = navigator.userAgent;
    if (/(Win)/i.test(ua)) {
      return 'windows os';
    }
    if (/(Mac)/i.test(ua)) {
      return 'mac os';
    }
    if (/(X11)/i.test(ua)) {
      return 'unix os';
    }
    if (/(Linux)/i.test(ua)) {
      return 'linux os';
    }
  }

  return null;
};
export const getBrowserDetails = () => {
  if (typeof navigator !== 'undefined') {
    const ua = navigator.userAgent;
    const browserName = JSON.parse(localStorage.getItem('MOE_DATA'));
    if (browserName?.browserDetails?.name.toLowerCase() === 'edge') {
      return 'edge';
    }
    if (/(Chrome)/i.test(ua)) {
      return 'chrome';
    }
    if (/(Safari)/i.test(ua)) {
      return 'safari';
    }
    if (/(Firefox)/i.test(ua)) {
      return 'firefox';
    }
    if (/(MSIE)/i.test(ua)) {
      return 'internet explorer';
    }
    if (/(Brave)/i.test(ua)) {
      return 'brave';
    }
  }

  return null;
};

export function getAllUTMParams() {
  let utmSource = getParameterByName('utm_source');
  let utmMedium = getParameterByName('utm_medium');
  let utmContent = getParameterByName('utm_content');
  let utmCampaign = getParameterByName('utm_campaign');
  let gclid = getParameterByName('gclid');
  let utmTerm = getParameterByName('utm_term');
  let campaignId = getParameterByName('campaign_id');
  let sourceCampaignName = getParameterByName('source_campaign_name');

  if (utmSource && utmSource !== '-') {
    sessionStorage.setItem('utm_source', utmSource);
  } else {
    utmSource = sessionStorage.getItem('utm_source');
  }

  if (utmMedium && utmMedium !== '-') {
    sessionStorage.setItem('utm_medium', utmMedium);
  } else {
    utmMedium = sessionStorage.getItem('utm_medium');
  }

  if (utmContent && utmContent !== '-') {
    sessionStorage.setItem('utm_content', utmContent);
  } else {
    utmContent = sessionStorage.getItem('utm_content');
  }

  if (utmCampaign && utmCampaign !== '-') {
    sessionStorage.setItem('utm_campaign', utmCampaign);
  } else {
    utmCampaign = sessionStorage.getItem('utm_campaign');
  }

  if (utmTerm && utmTerm !== '-') {
    sessionStorage.setItem('utm_term', utmTerm);
  } else {
    utmTerm = sessionStorage.getItem('utm_term');
  }

  if (gclid && gclid !== '-') {
    sessionStorage.setItem('gclid', gclid);
  } else {
    gclid = sessionStorage.getItem('gclid');
  }

  if (campaignId && campaignId !== '-') {
    sessionStorage.setItem('campaign_id', campaignId);
  } else {
    campaignId = sessionStorage.getItem('campaign_id');
  }

  if (sourceCampaignName && sourceCampaignName !== '-') {
    sessionStorage.setItem('source_campaign_name', sourceCampaignName);
  } else {
    sourceCampaignName = sessionStorage.getItem('source_campaign_name');
  }

  return {
    utmSource: utmSource === null ? '' : utmSource,
    utmMedium: utmMedium === null ? '' : utmMedium,
    utmContent: utmContent === null ? '' : utmContent,
    utmCampaign: utmCampaign === null ? '' : utmCampaign,
    utmTerm: utmTerm === null ? '' : utmTerm,
    gclid: gclid === null ? '' : gclid,
    campaignId: campaignId === null ? '' : campaignId,
    sourceCampaignName: sourceCampaignName === null ? '' : sourceCampaignName,
  };
}

export const getFolderResources = (folder) => {
  const setResourceType = (type) => (resource) => ({
    ...resource,
    type,
  });

  return [].concat(
    (folder.videos || []).map(setResourceType('VIDEO')),
    (folder.text || []).map(setResourceType('TEXT')),
    (folder.quiz || []).map(setResourceType('QUIZ')),
    (folder.testseries_data || []).map(setResourceType('TEST')),
  );
};

export const findFolderOrResourceInFolders = (folders) => (folder) => {
  let currentFolder = folder;

  if (Array.isArray(folder)) {
    currentFolder = folder.shift();
  }

  if (!currentFolder) return folders;

  const folderOrResourse = folders.find((c) => c.ID === parseInt(currentFolder, 10));
  if (folderOrResourse && folderOrResourse?.folder && Array.isArray(folder) && folder.length) {
    return findFolderOrResourceInFolders(folderOrResourse?.folder)(folder);
  }

  return folderOrResourse;
};

export const flatCourseArray = (metaContentType, courseMetaFolder) => {
  try {
    if (metaContentType === 3) {
      return courseMetaFolder?.[0] || courseMetaFolder;
    }

    return courseMetaFolder
      ?.map((folder) => {
        if (folder?.sub_folder) {
          return flatCourseArray(metaContentType, folder?.sub_folder);
        }

        return folder;
      })
      .flat(Infinity);
  } catch (e) {
    return [];
  }
};

export const structureCourseFolder = (folders, folderIndex = null) =>
  folders.map((folder, index) => {
    const folderStrucureIndex = folderIndex ? `${folderIndex}.${index + 1}` : '1';

    if (folder?.sub_folder) {
      return {
        ID: folder.id,
        name: folder.folder_name,
        slug: folder.folder_slug,
        type: 'FOLDER',
        contentType: folder.element_content_type,
        folderIndex: folderStrucureIndex,
        folder: structureCourseFolder(folder.sub_folder, folderStrucureIndex),
      };
    }

    return {
      ID: folder.id,
      name: folder.folder_name || folder.sub_folder_name,
      slug: folder.folder_slug || folder.sub_folder_slug,
      type: 'FOLDER',
      contentType: folder.element_content_type,
      folderIndex: folderStrucureIndex,
      resources: getFolderResources(folder),
    };
  });

export const flatCourseDetails = (courseDetails) => {
  const folderKey = `meta_folders_type_${courseDetails.meta_content_type}`;
  const folders = courseDetails[folderKey] || [];

  return {
    contentType: courseDetails.meta_content_type,
    languageId: parseInt(courseDetails.language_id, 10),
    courseCategory: courseDetails.course_category,
    userData: courseDetails.user_data || [],
    defaultVideo: courseDetails.default_video || [],
    folder: structureCourseFolder(folders),
  };
};
export const countDuration = (totalSeconds, minify = false, hideSecondSection = false) => {
  const totalMinutes = Math.floor(totalSeconds / 60);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const seconds = totalSeconds % 60;

  const strings = {
    hours: minify ? 'hour' : 'hour',
    minutes: minify ? 'min' : 'minute',
    seconds: minify ? 'sec' : 'second',
  };

  let timeString = '';
  if (hours) {
    timeString += `${pluralVal(hours, strings.hours, `${strings.hours}s`)} `;
  }

  if (minutes) {
    timeString += `${pluralVal(minutes, strings.minutes, `${strings.minutes}s`)} `;
  }

  if (hours === 0 && minutes === 0 && seconds && hideSecondSection) {
    timeString = pluralVal('1', `${strings.minutes}s`);
  }

  if (seconds && !hideSecondSection) {
    timeString += pluralVal(seconds, strings.seconds, `${strings.seconds}s`);
  }

  return timeString.trim();
};

export const countDurationCourseConsumption = (totalSeconds) => {
  const totalMinutes = Math.floor(totalSeconds / 60);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const seconds = totalSeconds % 60;

  let timeString = '';
  if (hours) {
    timeString += `${hours} hr`;
  }

  if (minutes) {
    timeString += `${pluralVal(minutes, 'min', 'mins')} `;
  }

  if (hours === 0 && minutes === 0 && seconds) {
    timeString = pluralVal('1', 'mins');
  }

  return timeString.trim();
};

export const countDurationSortStartNow = (targetTime) => {
  const currentTime = new Date();
  const remainingTime = currentTime - targetTime;
  const seconds = Math.floor(remainingTime / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes - hours * 60;
  let timeString = '';
  if (minutesLeft > 0) {
    timeString += `${minutes} minute${minutes > 1 ? 's' : ''} `;

    timeString = `${hours} Hr${hours > 1 ? 's' : ''}  ${minutesLeft} ${minutesLeft > 1 ? 'Mins' : 'Min'} `;
  } else {
    timeString = `${hours} hours left`;
  }
  return timeString;
};

export const formatDate = (date, patternStr) => {
  if (date instanceof Date === false) {
    // eslint-disable-next-line no-param-reassign
    date = new Date(date);
  }

  if (!patternStr) {
    // eslint-disable-next-line no-param-reassign
    patternStr = 'M/d/yyyy';
  }

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const dayOfWeekNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  function twoDigitPad(num) {
    return num < 10 ? `0${num}` : num;
  }

  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  const miliseconds = date.getMilliseconds();
  const h = hour % 12;
  const hh = twoDigitPad(h);
  const HH = twoDigitPad(hour);
  const mm = twoDigitPad(minute);
  const ss = twoDigitPad(second);
  const aaa = hour < 12 ? 'AM' : 'PM';
  const EEEE = dayOfWeekNames[date.getDay()];
  const EEE = EEEE?.substr(0, 3);
  const dddd = `${twoDigitPad(day)}${ordinalSuffixOf(day)}`;
  const ddd = `${twoDigitPad(day)}${ordinalSuffixOf(day, false)}`;
  const dd = twoDigitPad(day);
  const M = month + 1;
  const MM = twoDigitPad(M);
  const MMMM = monthNames[month];
  const MMM = MMMM?.substr(0, 3);
  const yyyy = `${year}`;
  const yy = yyyy?.substr(2, 2);
  // checks to see if month name will be used

  // eslint-disable-next-line no-param-reassign
  patternStr = patternStr
    .replace('hh', `${hh === '00' ? '12' : hh}`)
    .replace('h', h)
    .replace('HH', HH)
    .replace('H', hour)
    .replace('mm', mm)
    .replace('m', minute)
    .replace('ss', ss)
    .replace('s', second)
    .replace('S', miliseconds)
    .replace('dddd', dddd)
    .replace('ddd', ddd)
    .replace('dd', dd)
    .replace('D', day)

    .replace('EEEE', EEEE)
    .replace('EEE', EEE)
    .replace('yyyy', yyyy)
    .replace('yy', yy);
  if (patternStr.indexOf('MMM') > -1) {
    // eslint-disable-next-line no-param-reassign
    patternStr = patternStr.replace('MMMM', MMMM).replace('MMM', MMM);
  } else {
    // eslint-disable-next-line no-param-reassign
    patternStr = patternStr.replace('MM', MM).replace('M', M);
  }

  return patternStr.replace('aaa', aaa);
};

export const fixedTestDateFormat = (dateTs) => {
  const date = new Date(dateTs);

  return formatDate(date, 'hh:mm aaa, ddd MMM');
};

export const fixedTestDateFormat2 = (dateTs) => {
  const date = new Date(dateTs);

  return formatDate(date, 'ddd MMM yyyy');
};

export const convertToSlug = (title) =>
  (title || '')
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');

export const expiryTimestamp = (ts) => new Date(ts).getTime() > 0;

export const onTimerExpire = (onExpire) => onExpire && typeof onExpire === 'function';

export const getTimeFromSeconds = (secs) => {
  const totalSeconds = Math.ceil(secs);
  const days = Math.floor(totalSeconds / (60 * 60 * 24));
  const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return {
    seconds,
    minutes,
    hours,
    days,
  };
};

export const getSecondsFromExpiry = (expiry, shouldRound) => {
  const now = new Date().getTime();
  const milliSecondsDistance = expiry - now;
  if (milliSecondsDistance > 0) {
    const val = milliSecondsDistance / 1000;
    return shouldRound ? Math.round(val) : val;
  }
  return 0;
};

export const getSecondsFromPrevTime = (prevTime, shouldRound) => {
  const now = new Date().getTime();
  const milliSecondsDistance = now - prevTime;
  if (milliSecondsDistance > 0) {
    const val = milliSecondsDistance / 1000;
    return shouldRound ? Math.round(val) : val;
  }
  return 0;
};

export const getSecondsFromTimeNow = () => {
  const now = new Date();
  const currentTimestamp = now.getTime();
  const offset = now.getTimezoneOffset() * 60;
  return currentTimestamp / 1000 - offset;
};

export const getFormattedTimeFromSeconds = (totalSeconds, format) => {
  const { seconds: secondsValue, minutes, hours } = getTimeFromSeconds(totalSeconds);
  let ampm = '';
  let hoursValue = hours;

  if (format === '12-hour') {
    ampm = hours >= 12 ? 'pm' : 'am';
    hoursValue = hours % 12;
  }

  return {
    seconds: secondsValue,
    minutes,
    hours: hoursValue,
    ampm,
  };
};

export const getContentType = (contentTypeId, withFolders = false) => {
  switch (contentTypeId) {
    case 1:
      return 'TEXT';
    case 2:
      return 'AUDIO';
    case 3:
      return 'VIDEO';
    case 4:
      return 'QUIZ';
    case 9:
      return 'TEST_SERIES';
    default:
      if (withFolders) {
        switch (contentTypeId) {
          case 5:
            return 'FOLDER';
          case 6:
            return 'SUB_FOLDER';
          default:
            return null;
        }
      }
      return null;
  }
};

export const formatCourseDetails = (courseDetails) =>
  courseDetails.map((courseDetail) => {
    const contentType = getContentType(courseDetail.elementContentType, true);

    return {
      ...courseDetail,
      contentType,
      isFolder: contentType === 'FOLDER' || contentType === 'SUB_FOLDER',
    };
  });

export const isResourceType = (contentTypeId) => getContentType(contentTypeId) !== null;

export const base64DecodeUint8Array = (input) => {
  const raw = window.atob(input);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }

  return array;
};

export const hmsToSecondsOnly = (str) => {
  const p = str.split(':');
  let s = 0;
  let m = 1;

  while (p.length > 0) {
    s += m * parseInt(p.pop(), 10);
    m *= 60;
  }

  return s;
};

export const calcPercentage = (current, total, decimal = 0) => ((current / total) * 100).toFixed(decimal);

export const formatDateCustom = (timestamp) => {
  const date = new Date(timestamp);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const suffixes = [
    'ˢᵗ',
    'ⁿᵈ',
    'ʳᵈ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ˢᵗ',
    'ⁿᵈ',
    'ʳᵈ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ᵗʰ',
    'ˢᵗ',
  ];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const suffix = suffixes[day - 1];
  const year = date.getFullYear();

  return `${month} ${day}${suffix},${year}`;
};
export const isDevice = (width) => {
  if (width >= 992) {
    return 'D';
  } if (width < 992 && width > 600) {
    return 'T';
  } if (width <= 600) {
    return 'M';
  }
  return 'D';
};
export const openInAppCheck = () => {
  let deviceType = '';
  let deviceOS = '';
  let showAppModal = false;
  if (typeof window !== 'undefined') {
    const { userAgent } = window.navigator;
    const { width } = window.screen;
    deviceOS = getOSDetails(userAgent);
    deviceType = isDevice(width);
  }
  if (deviceType === 'M' && deviceOS !== 'mac os') {
    showAppModal = true;
  } else {
    showAppModal = false;
  }
  return showAppModal;
};

export const formatElapsedTime = (timestamp) => {
  const now = new Date();
  const elapsedTime = now.getTime() - timestamp; // Time difference in milliseconds

  // Convert milliseconds to seconds, minutes, hours, days, and years
  const seconds = Math.floor(elapsedTime / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const years = Math.floor(days / 365);

  if (years >= 1) {
    return `${years} y`;
  }
  if (days >= 7) {
    const weeks = Math.floor(days / 7);
    if (weeks >= 4) {
      const months = Math.floor(weeks / 4);
      return `${months} m`;
    }
    return `${weeks} w`;
  }
  if (days > 0) {
    return `${days} d`;
  }
  if (hours > 0) {
    return `${hours} hr`;
  }
  if (minutes > 1) {
    return `${minutes} mins`;
  }
  if (minutes > 0 <= 1) {
    return `${minutes} min`;
  }
  return `${seconds} s`;
};

export const decipherString = (cipherText) => {
  const ALPHABET = 'abcABCdefghijkIJKlmnopqrstuvwxyzUVWXYZDEFGHLMNOPQRST';
  if (!cipherText || cipherText.length === 0) {
    return cipherText;
  }

  const newArr = [];

  for (let i = 0; i < cipherText.length; i++) {
    const charPosition = ALPHABET.indexOf(cipherText.charAt(i));
    if (charPosition < 0) {
      newArr.push(cipherText.charAt(i));
    } else {
      const keyVal = (ALPHABET.length + charPosition - 8) % ALPHABET.length;
      const replaceVal = ALPHABET.charAt(keyVal);
      newArr.push(replaceVal);
    }
  }

  let decipheredText = newArr.join('').toString();

  if (decipheredText.indexOf('<p>') !== -1) {
    decipheredText = decipheredText.replace('<p>', '').replace('</p>', '');
  }

  return decipheredText;
};

export const createResourceParentsPath = (contentId, parents = []) => {
  parents.push(contentId);

  // eslint-disable-next-line no-param-reassign
  return Array.from(new Set(parents.map((parent) => parseInt(parent, 10)))).join('/');
};

export const isEmoji = (str) => {
  const ranges = [
    // eslint-disable-next-line max-len
    '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])', // U+1F680 to U+1F6FF
  ];
  if (typeof str === 'string' && str?.match(ranges?.join('|'))) {
    return str?.includes('[');
  }

  return false;
};

export const haveSpecialChar = (string) => {
  const format = /[#`~;:\\|,\\?]+/;
  return format.test(string);
};

export const fetchCourseSlugData = async (courseId, languageId) => {
  try {
    const courseOfflineDataRes = await getOfflineCentreData(courseId);
    const response = await javaApi.get('app-content-ws/v1/course/getParentOfFirstFolder', {
      params: {
        courseId,
        languageId,
        isOfflineCourse: courseOfflineDataRes?.isOfflineEnabled,
        offlineCentreId: courseOfflineDataRes?.offlineCentreId || '',
      },
    });

    const { data } = response?.data || {};
    const { id = null, slug = null } = data?.[0] || {};

    return { id, slug };
  } catch (error) {
    return { id: null, slug: null };
  }
};

export const getCourseType = (courseTypeId) => {
  let courseType = 'COURSE';

  switch (parseInt(courseTypeId, 10)) {
    case 2:
    case 5:
      courseType = 'BOOK';
      break;
    default:
      courseType = 'COURSE';
  }

  return courseType;
};

export function htmlToText(html) {
  const temp = document.createElement('div');
  temp.innerHTML = html;
  const content = temp.textContent;
  temp.remove();

  return content;
}

export const getEmptyRequiredFields = (obj) => {
  for (const key in obj) {
    if (key !== 'landmark' && !obj[key]) {
      return false;
    }
  }
  return true;
};

export const mapQuestionRangeForSections = (sections) => {
  let prevSectionTarget = 0;

  return sections.map((section) => {
    const secQues = parseInt(section.secTotalq, 10);

    section.startQuestion = prevSectionTarget + 1;
    section.endQuestion = prevSectionTarget + secQues;

    prevSectionTarget = section.endQuestion;

    return section;
  });
};

export const inputFields = [
  { name: 'firstName', label: 'Your Name', placeholder: 'Enter your Name', errorMessage: 'First Name is required' },
  {
    name: 'mobile',
    label: 'Your Mobile Number',
    placeholder: 'Enter your Mobile Number',
    errorMessage: '',
    maxLength: 10,
    disabled: true,
  },
  { name: 'email', label: 'Your Email', placeholder: 'Enter your Email', errorMessage: '', disabled: true },
  {
    name: 'address',
    label: 'Your Flat no. House no. Building/Company',
    placeholder: 'Enter your Flat no. House no. Building/Company',
    errorMessage: 'Address is required',
  },
  {
    name: 'area',
    label: 'Your Area, Colony, Street, Sector, Village',
    placeholder: 'Enter your Area, Colony, Street, Sector, Village',
    errorMessage: 'Area is required',
  },
  { name: 'landmark', label: 'Your Landmark', placeholder: 'Enter your Landmark', errorMessage: '' },
  {
    name: 'pincode',
    label: 'Your Pin Code',
    placeholder: 'Enter your Pin Code',
    errorMessage: 'Pin Code is required',
    maxLength: 6,
  },
  { name: 'city', label: 'Your City', placeholder: 'Enter your City', errorMessage: 'City is required' },
  { name: 'state', label: 'Your State', placeholder: 'Enter your State', errorMessage: 'State is required' },
];

export const isIdentical = (obj1, obj2) => {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let i = 0; i < keys1.length; i++) {
    const key = keys1[i];
    if (!keys2.includes(key) ||
      typeof obj1[key] !== typeof obj2[key] ||
      obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const renderPrice = (amount, fraction = 2) =>
  parseFloat(amount || 0).toLocaleString('en-IN', {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  });

export const calculateDiscountPercent = (discountAmount, totalAmount) =>
  Math.round((discountAmount / totalAmount) * 100);

export const isCrmOrder = () => {
  const haveOrderInitiateId = getParameterByName('order_initiate_id');
  const haveOrderInitiateKey = getParameterByName('key');

  return Boolean(
    haveOrderInitiateId && haveOrderInitiateId !== '-' && haveOrderInitiateKey && haveOrderInitiateKey !== '-',
  );
};

export const objToCamelCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj?.map((val) => objToCamelCase(val));
  }

  return mapKeys(obj, (v, k) => camelCase(k));
};

export const addCourseToLocalStorage = (courseDetail, storageKey) => {
  let courses = window.localStorage.getItem(storageKey) || '[]';
  try {
    courses = JSON.parse(courses);
  } catch {
    courses = [];
  }

  const courseIndex = courses
    ?.findIndex((course) => parseInt(course.courseId, 10) === parseInt(courseDetail.courseId, 10));
  if (courseIndex > -1 && Array.isArray(courses)) {
    // remove the current product from its index
    courses.splice(courseIndex, 1);
  }

  // push current product
  courses.push(courseDetail);

  // remove last entry after 10 records
  if (courses.length > 10) {
    courses.shift();
  }

  window.localStorage.setItem(storageKey, JSON.stringify(courses));
};

export const removeCourseFromLocalStorage = (courseId, storageKey) => {
  let courses = window.localStorage.getItem(storageKey) || '[]';
  try {
    courses = JSON.parse(courses);
  } catch {
    courses = [];
  }

  const courseIndex = courses?.findIndex((course) => course.courseId === courseId);
  if (courseIndex > -1 && Array.isArray(courses)) {
    // remove the current product from its index
    courses.splice(courseIndex, 1);
  }

  // remove last entry after 10 records
  if (courses.length > 10) {
    courses.shift();
  }

  window.localStorage.setItem(storageKey, JSON.stringify(courses));
};

export const paymentGatewayName = (paymentType) => {
  switch (parseInt(paymentType, 10)) {
    case 1:
      return 'CC_AVENUE';
    case 2:
      return 'PAYTM';
    case 3:
      return 'RAZORPAY';
    default:
      return 'CC_AVENUE';
  }
};

export const getGrowthBookDeviceType = () => {
  if (typeof navigator !== 'undefined') {
    const ua = navigator?.userAgent;
    const isMobile = /Mobi|Android|iPhone/i.test(ua);
    const deviceType = isMobile ? 'mobile' : 'desktop';
    return deviceType;
  }

  return null;
};
