import React from 'react';

import { useRouter } from 'next/router';

import { faCirclePause, faCirclePlay, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import useMediaQuery from '../../hooks/useMediaQuery';
import usePlayerEventCapture from '../../hooks/usePlayerEventCapture';

import ExpandIcon from '../../assets/images/course-dashboard/icons/expand.svg';

import { createResourceParentsPath } from '../../utils/helper';

import VideoPlayer from '../VideoPlayer';
import styles from './MiniPlayer.module.scss';

function MiniPlayer(props, primaryPlayerRef) {
  const { courseId, languageId, video } = props;
  const router = useRouter();
  const isOnDesktop = useMediaQuery('(min-width: 1080px)');
  const [isVisible, setIsVisible] = React.useState(false);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const { playerRef, isPlaying: isPlayerPlaying } = usePlayerEventCapture(
    courseId,
    languageId,
    { ...video, source: 'mini-player' },
    {
      autoPlay: false,
      syncProgress: true,
    },
    primaryPlayerRef,
  );

  const handleBrowserMessage = React.useCallback((event) => {
    const miniPlayerEvent = event.data.type === 'stiq_mini_player';
    if (!miniPlayerEvent) return;

    // eslint-disable-next-line no-shadow
    const { playerState, isPlaying } = event.data;

    setIsVisible(playerState);
    setIsPlaying(isPlaying);
  }, [setIsVisible, setIsPlaying]);

  React.useEffect(() => {
    window.addEventListener('message', handleBrowserMessage);

    return () => window.removeEventListener('message', handleBrowserMessage);
  }, [handleBrowserMessage]);

  React.useEffect(() => {
    // eslint-disable-next-line react/destructuring-assignment
    if (isVisible && isPlaying) {
      // eslint-disable-next-line no-unused-expressions
      playerRef?.current?.playVideo && playerRef.current.playVideo();
    } else {
      // eslint-disable-next-line no-unused-expressions
      playerRef?.current?.pauseVideo && playerRef.current.pauseVideo();
    }
  }, [isPlaying, isVisible]);

  React.useEffect(() => {
    if (!isOnDesktop && isVisible) {
      setIsVisible(false);
    }
  }, [isOnDesktop, isVisible]);

  const lastChapter = React.useMemo(
    () =>
      [...(video?.parents || [])].reverse().find((parent) => parseInt(parent.id, 10) !== parseInt(video.contentId, 10)),
    [video?.contentId, video?.parents],
  );

  const handleClose = (event) => {
    event.preventDefault();

    setIsVisible(false);
  };

  const handleExpand = async (event) => {
    event.preventDefault();

    window.localStorage.removeItem('primaryPlayerState');
    window.localStorage.setItem('primaryPlayerState', JSON.stringify({
      isPlaying: playerRef.current.isPlaying,
    }));

    const parents = video?.parents?.map((parent) => parent.id);

    let videoDashboardUrl = `/course-content/${courseId}-${languageId}/`;
    videoDashboardUrl += createResourceParentsPath(video.contentId, parents || []);
    videoDashboardUrl += `/${video?.slug}/`;
    videoDashboardUrl += videoDashboardUrl.includes('?') ? '&' : '?';
    videoDashboardUrl += 'isPip=1';

    playerRef.current.pauseVideo();

    router.push(videoDashboardUrl);
  };

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.visible]: isVisible,
      })}
    >
      <div className={styles.playerWrapper}>
        <VideoPlayer ref={playerRef} {...props} />

        <div className={styles.playerControlsWrapper}>
          <button type="button" className={styles.expandBtn} onClick={handleExpand} title="Expand Player">
            <ExpandIcon id="expandPlayer" />
          </button>

          <button type="button" className={styles.closeBtn} onClick={handleClose} title="Close Playback">
            <FontAwesomeIcon icon={faTimes} id="closePlayer" />
          </button>

          {isPlayerPlaying ? (
            <button
              type="button"
              className={styles.playBtn}
              onClick={() => playerRef?.current?.pauseVideo()}
              title="Pause Video"
            >
              <FontAwesomeIcon icon={faCirclePause} />
            </button>
          ) : (
            <button
              type="button"
              className={styles.playBtn}
              onClick={() => playerRef?.current?.playVideo()}
              title="Play Video"
            >
              <FontAwesomeIcon icon={faCirclePlay} />
            </button>
          )}
        </div>
      </div>

      <div className={styles.videoInfoWrapper}>
        <h3 className={styles.videoName}>{video?.name}</h3>
        {lastChapter && <span className={styles.chapterName}>{lastChapter.title}</span>}
      </div>
    </div>
  );
}

export default React.memo(React.forwardRef(MiniPlayer));
