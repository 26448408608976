import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { notificationServiceApi } from '../../utils/AxiosInstance';

const initialState = {
  isBellRed: false,
  notifications: {
    loading: false,
    list: [],
    error: null,
  },
  lastNotificationId: null,
  lastNotificationCreatedAt: false,
};

export const fetchLatestNotifications = createAsyncThunk(
  'notification/fetchLatestNotifications',
  async (payload, thunkAPI) => {
    try {
      const response = await notificationServiceApi.get('api/user/list/app/notifications');

      if (response.data.success) {
        return thunkAPI.fulfillWithValue({
          isBellRed: response.data.data.isBellRed,
          notifications: response.data.data.userNotificationsHistory,
          lastNotificationId: response.data.data.lastEvaluatedKey?.notifId ?? null,
          lastNotificationCreatedAt: response.data.data.lastEvaluatedKey?.createdAt ?? null,
        });
      }

      return thunkAPI.rejectWithValue('No Notifications!');
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  },
);

export const loadMoreNotification = createAsyncThunk(
  'notification/loadMoreNotification',
  async ({ lastNotificationId, createdAt }, thunkAPI) => {
    try {
      const response = await notificationServiceApi.get('api/user/list/app/notifications', {
        params: {
          notifId: lastNotificationId,
          createdAt,
        },
      });

      if (response.data.success) {
        return thunkAPI.fulfillWithValue({
          isBellRed: response.data.data.isBellRed,
          notifications: response.data.data.userNotificationsHistory,
          lastNotificationId: response.data.data.lastEvaluatedKey?.notifId,
          lastNotificationCreatedAt: response.data.data.lastEvaluatedKey?.createdAt,
        });
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }

    return thunkAPI.rejectWithValue('No Notifications!');
  },
);

export const markNotificationRead = createAsyncThunk('notification/markRead', async (notificationId, thunkAPI) => {
  try {
    const response = await notificationServiceApi.post('api/user/read/notification', {
      notificationIds: [notificationId],
    });

    if (response.data.success) {
      return thunkAPI.fulfillWithValue(notificationId);
    }

    return thunkAPI.fulfillWithValue([]);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const markAllNotificationsRead = createAsyncThunk('notification/markAllRead', async (payload, thunkAPI) => {
  try {
    const state = thunkAPI.getState()?.notification?.notifications;
    const notifications = state.list;

    const unreadNotificationIds = notifications
      .filter((notification) => !notification?.isRead)
      .map((notification) => notification?.notificationId);

    if (unreadNotificationIds.length === 0) {
      return thunkAPI.fulfillWithValue([]);
    }

    const response = await notificationServiceApi.post('api/user/read/notification', {
      notificationIds: unreadNotificationIds,
    });

    if (response.data.success) {
      return thunkAPI.fulfillWithValue(response.data.data.userNotificationsHistory);
    }

    return thunkAPI.fulfillWithValue([]);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const onRealtimeNotification = createAsyncThunk(
  'notification/onRealtimeNotification',
  async (notification, thunkAPI) => {
    if (notification?.notificationId) {
      return thunkAPI.fulfillWithValue({
        isBellRed: true,
        notification,
      });
    }

    return thunkAPI.fulfillWithValue({
      isBellRed: false,
      notification: null,
    });
  },
);

export const changeBellStatus = createAction('notification/changeBellStatus', (status) => ({
  payload: status,
}));

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchLatestNotifications.pending, (state) => {
        state.notifications = {
          ...initialState.notifications,
          list: state.notifications.list,
          loading: true,
        };
      })
      .addCase(fetchLatestNotifications.fulfilled, (state, { payload }) => {
        state.notifications = {
          ...state.notifications,
          loading: false,
          list: payload.notifications.filter((notification) => notification?.metaData?.title),
        };
        state.lastNotificationId = payload.lastNotificationId;
        state.lastNotificationCreatedAt = payload.lastNotificationCreatedAt;
      })
      .addCase(fetchLatestNotifications.rejected, (state, { payload }) => {
        state.notifications = {
          ...state.notifications,
          loading: false,
          error: payload,
        };
        state.lastNotificationId = null;
        state.lastNotificationCreatedAt = null;
      })
      .addCase(loadMoreNotification.pending, (state) => {
        state.notifications = {
          ...state.notifications,
          loading: true,
        };
      })
      .addCase(loadMoreNotification.fulfilled, (state, { payload }) => {
        const newNotifications = payload.notifications.filter((notification) => notification?.metaData?.title);

        state.notifications = {
          ...state.notifications,
          loading: false,
          list: [].concat(state.notifications.list, newNotifications),
        };

        state.lastNotificationId = payload.lastNotificationId;
        state.lastNotificationCreatedAt = payload.lastNotificationCreatedAt;
      })
      .addCase(loadMoreNotification.rejected, (state, { payload }) => {
        state.notifications = {
          ...state.notifications,
          loading: false,
          error: payload,
        };
        state.lastNotificationId = null;
        state.lastNotificationCreatedAt = null;
      })
      .addCase(onRealtimeNotification.fulfilled, (state, { payload }) => {
        state.isBellRed = payload.isBellRed;
      })
      .addCase(onRealtimeNotification.rejected, (state) => {
        state.isBellRed = false;
      })
      .addCase(changeBellStatus, (state, { payload }) => {
        state.isBellRed = payload;
      })
      .addCase(markNotificationRead.fulfilled, (state, { payload }) => {
        const notificationIndex = (state.notifications.list ?? []).findIndex(
          (notification) => notification.notificationId === payload,
        );

        if (notificationIndex > -1) {
          state.notifications.list[notificationIndex] = {
            ...state.notifications.list[notificationIndex],
            isRead: true,
          };
        }
      });
  },
});

export default notificationSlice.reducer;
