import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { javaApi } from '../../utils/AxiosInstance';

export const addVideoToList = createAsyncThunk(
  'my_course_add_video_to_list_save',
  async ({ courseId, lessonID, listID, listName }, thunkAPI) => {
    try {
      const { userID, authToken } = thunkAPI.getState().auth;

      const response = await javaApi.post(
        'app-content-ws/api/web/addVideoToList/save',
        {
          userId: userID,
          courseId,
          videoLessonId: lessonID,
          listId: listID || 0,
          newListName: listName,
        },
        { headers: { Authorization: `Bearer ${authToken}` } },
      );

      const { success, ...courseAddVideoList } = response.data || {};
      if (response?.status === 200) {
        return {
          ...courseAddVideoList,
          success,
        };
      }
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response.data.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const fetchMyCourseAddVideoList = createAsyncThunk(
  'playList/fetchMyCourseAddVideoList',
  async ({ courseId }, thunkAPI) => {
    try {
      const { authToken } = thunkAPI.getState().auth;

      const response = await javaApi.post(
        '/app-content-ws/api/web/my-course/add-video-to-list',
        { courseId: parseInt(courseId, 10) || '' },
        { headers: { Authorization: `Bearer ${authToken}` } },
      );

      const { success, ...courseAddVideoList } = response.data || {};

      if (success) {
        return {
          ...courseAddVideoList,
        };
      }

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const fetchUserVideoList = createAsyncThunk(
  'playList/fetchUserVideoList',
  async ({ videoListId, courseId }, thunkAPI) => {
    try {
      const { userID, authToken } = thunkAPI.getState().auth;

      const response = await javaApi.post(
        '/app-content-ws/api/web/userVideoListData',
        {
          userId: userID,
          videoListId,
          courseId,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );
      const { success, ...data } = response.data || {};

      if (success) {
        return {
          ...data,
        };
      }

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

// Initial State
const initialState = {
  myCourseAddVideoList: {
    loading: false,
    details: null,
    error: null,
  },
  userVideoList: {
    loading: false,
    details: null,
    error: null,
  },
};

// Auth Slice
const playListSlice = createSlice({
  name: 'playList',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyCourseAddVideoList.pending, (state) => {
        state.myCourseAddVideoList = {
          ...initialState.myCourseAddVideoList,
          loading: true,
          details: state.myCourseAddVideoList.details,
        };
      })
      .addCase(fetchMyCourseAddVideoList.fulfilled, (state, action) => {
        state.myCourseAddVideoList = {
          ...initialState.myCourseAddVideoList,
          details: action.payload,
        };
      })
      .addCase(fetchMyCourseAddVideoList.rejected, (state, action) => {
        state.myCourseAddVideoList = {
          ...initialState.myCourseAddVideoList,
          details: action.payload,
          error: action.payload,
        };
      })
      .addCase(addVideoToList.pending, (state) => {
        state.myCourseAddVideoList = {
          ...state.myCourseAddVideoList,
          loading: true,
        };
      })
      .addCase(addVideoToList.fulfilled, (state) => {
        state.myCourseAddVideoList = {
          ...state.myCourseAddVideoList,
          loading: false,
        };
      })
      .addCase(addVideoToList.rejected, (state) => {
        state.myCourseAddVideoList = {
          ...state.myCourseAddVideoList,
          loading: false,
        };
      })
      .addCase(fetchUserVideoList.pending, (state) => {
        state.userVideoList = {
          ...initialState.userVideoList,
          loading: true,
          details: state.userVideoList.details,
        };
      })
      .addCase(fetchUserVideoList.fulfilled, (state, action) => {
        state.userVideoList = {
          ...initialState.userVideoList,
          details: action.payload,
        };
      })
      .addCase(fetchUserVideoList.rejected, (state, action) => {
        state.userVideoList = {
          ...initialState.userVideoList,
          details: action.payload,
          error: action.payload,
        };
      });
  },
});

export default playListSlice.reducer;
