/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FormData from 'form-data';

import { javaApi, phpApi } from '../../utils/AxiosInstance';

const initialState = {
  isLoading: false,
  categories: [],
  isSearched: false,
  searchResults: [],
  courseTypeLoading: false,
  courseTypes: [],
  linkMsgSent: false,
  linkMsg: '',
  isUPSCDetails: false,
  getUPSCDetails: [],
  getHomePageContent: {
    data: [],
    isLoading: false,
    isError: null,
  },
};

export const getAllCategories = createAsyncThunk('join', async () => {
  try {
    const res = await phpApi.get('coursecategories');
    return res?.data?.data;
  } catch (error) {
    /* empty */
  }
});

export const searchCourses = createAsyncThunk(
  'searchCourses',
  // eslint-disable-next-line consistent-return
  async (searchTerm) => {
    try {
      const res = await phpApi.get(`/searchbycourses?keyword=${searchTerm}`);
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { data } = res?.data;
      return data;
    } catch (error) {
      /* empty */
    }
  },
);

export const getCourseTypes = createAsyncThunk('courseTypes', async () => {
  try {
    const response = await phpApi.get('coursetypes');
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { data } = response?.data;
    return data;
  } catch (error) {
    /* empty */
  }
});

export const getLinkOnMobile = createAsyncThunk('getLinkOnMobile', async (mobile, thunkAPI) => {
  const formData = new FormData();

  formData.append('mobile', mobile.mobile);
  formData.append('action', mobile.actn);

  try {
    const response = await javaApi.post('common-ws/api/v1/web/join-now', formData);

    // eslint-disable-next-line no-unsafe-optional-chaining
    const { msg } = response?.data?.msg;

    return msg;
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const getUpscSectionDetails = createAsyncThunk('getUpscSectionDetails', async (thunkAPI) => {
  try {
    const response = await javaApi.get('common-ws/api/v1/web/homepage');
    return response?.data?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const getHomePageContentSection = createAsyncThunk(
  'getHomePageContentSection',
  async (thunkAPI) => {
    try {
      const response = await javaApi.get('app-content-ws/v1/homepage/content-sections?fetchActiveOnly=true');
      return response?.data?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

const homeSlice = createSlice({
  name: 'homepage',
  initialState,
  extraReducers: {
    // search courses
    [searchCourses.pending]: (state) => {
      state.isSearched = false;
      state.searchResults = [];
    },
    [searchCourses.fulfilled]: (state, action) => {
      state.isSearched = true;
      state.searchResults = action?.payload;
    },
    // get all categories
    [getAllCategories.pending]: (state) => {
      state.isLoading = true;
      state.categories = [];
    },
    [getAllCategories.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.categories = action?.payload || null;
    },
    // get course types
    [getCourseTypes.pending]: (state) => {
      state.courseTypeLoading = true;
      state.courseTypes = [];
    },
    [getCourseTypes.fulfilled]: (state, action) => {
      state.courseTypeLoading = false;
      state.courseTypes = action?.payload;
    },
    // get link on mobile
    [getLinkOnMobile.pending]: (state) => {
      state.linkMsgSent = false;
      state.linkMsg = '';
    },
    [getLinkOnMobile.fulfilled]: (state, action) => {
      state.linkMsgSent = true;
      state.linkMsg = action?.payload;
    },
    [getUpscSectionDetails.pending]: (state) => {
      state.isUPSCDetails = true;
      state.getUPSCDetails = [];
    },
    [getUpscSectionDetails.fulfilled]: (state, action) => {
      state.isUPSCDetails = false;
      state.getUPSCDetails = action?.payload;
    },
    [getHomePageContentSection.pending]: (state) => {
      state.getHomePageContent.isLoading = true;
    },
    [getHomePageContentSection.fulfilled]: (state, action) => {
      state.getHomePageContent.isLoading = false;
      state.getHomePageContent.data = action?.payload;
    },
  },
});

export default homeSlice.reducer;
