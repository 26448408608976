import React from 'react';
import dynamic from 'next/dynamic';

// Component
const VdoCipher = dynamic(() => import('./VdoCipher'), { ssr: false });
const VimeoPlayer = dynamic(() => import('./Vimeo'), { ssr: false });
const ShakaPlayer = dynamic(() => import('./Shaka'), { ssr: false });

function VideoPlayer(props, playerRef) {
  const { video } = props;

  if (video?.player === 'VIDEO_CIPHER') {
    return <VdoCipher playerRef={playerRef} {...props} />;
  }

  return (
    <div
      className="player"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {video?.player === 'SHAKA' && <ShakaPlayer playerRef={playerRef} {...props} />}
      {video?.player === 'VIMEO' && <VimeoPlayer url={video?.videoUrl} playerRef={playerRef} {...props} />}
    </div>
  );
}

export default React.forwardRef(VideoPlayer);
