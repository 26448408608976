import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FormData from 'form-data';
import cloneDeep from 'lodash.clonedeep';
import find from 'lodash.find';
import axios from 'axios';

import { getAuthState, javaApi } from '../../utils/AxiosInstance';
import { FIXED_MOCK_LAMBDA_API } from '../../constants/api';
import TEST_SERIES_CONSTANTS from '../constants/testSeries';
import { mapQuestionRangeForSections } from '../../utils/helper';

const initialState = {
  courseId: null,
  testId: null,
  isPaused: false,
  isFixedMock: null,
  isReattempt: false,
  isTestStarted: false,
  isFixedMockActive: null,
  isSectionalTiming: false,
  isCalculatorEnabled: false,
  isFixedMockResultDeclared: false,
  testDuration: null,
  testInfo: {
    loading: false,
    detail: null,
    error: null,
  },
  testState: {
    loading: false,
    detail: null,
    error: null,
  },
  selectedLanguage: null,
  activeTests: {},
  resultState: {
    loading: false,
    detail: null,
    error: null,
  },
  testSolution: {
    loading: false,
    detail: null,
    error: null,
  },
};

export const saveFixedMockState = async (
  postSignedUrl,
  postSignedFields,
  testStateData,
) => {
  try {
    const formData = new FormData();
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(postSignedFields)) {
      formData.append(key, postSignedFields[key]);
    }

    const fileData = new Blob([JSON.stringify(testStateData)], {
      type: 'application/json',
    });

    formData.append('file', fileData);

    return await axios.post(postSignedUrl, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } catch (error) {
    return false;
  }
};

export const fetchTestInfo = createAsyncThunk(
  'testSeries/fetchTestInfo',
  // eslint-disable-next-line consistent-return
  async ({ courseId, testId, isPurchased, haveResultDeclared = null }, thunkAPI) => {
    try {
      const response = await javaApi.post(
        'app-test-series-ws/api/v1/test-series/tests',
        {
          courseId,
          purchased: isPurchased,
          testIds: [testId],
        },
      );

      const { success, data: testResponse } = response?.data || {};
      if (!success) return thunkAPI.rejectWithValue('Api Error!');

      const testInfo = testResponse?.[0] || {};

      const testInfoRes = await axios.get(testInfo.sfJson);
      if (testInfoRes.data.meta) {
        testInfo.detail = testInfoRes.data;

        testInfo.isFixedMockActive = testInfo.isFixedMock;
        if (testInfo.isFixedMock) {
          testInfo.isFixedMockActive =
            testInfo.fixedMockSubmitTime > new Date().getTime();
          testInfo.isFixedMockResultDeclared =
            testInfo.fixedMockResultTime <= new Date().getTime();
        }

        testInfo.haveCalculator = testInfoRes.data.meta.isCalculator || false;
        testInfo.sections = mapQuestionRangeForSections(testInfoRes.data.meta?.sections ?? []);

        if (testInfo?.sections?.[0]?.secTime) {
          testInfo.isSectionalTiming = true;
        }
      }

      let testDuration = testInfo.duration;
      if (testInfo.isSectionalTiming) {
        testDuration = testInfo.duration;
      }

      if (haveResultDeclared !== null) {
        testInfo.haveResultDeclared = haveResultDeclared;
      }

      const isReattempt = testInfo.testStatus === 'COMPLETED';

      testInfo.languages = Object.keys(testInfo?.detail ?? {}).filter((lang) => lang !== 'meta');

      return thunkAPI.fulfillWithValue({ courseId, testId, testInfo, testDuration, isReattempt });
    } catch (e) {
      return thunkAPI.rejectWithValue('Api Error!');
    }
  },
);

export const selectTestLanguage = createAsyncThunk(
  'testSeries/selectTestLanguage',
  async (language, thunkAPI) => {
    const tests = thunkAPI.getState()?.testSeries?.testInfo?.detail?.detail || {};

    return thunkAPI.fulfillWithValue({
      language,
      test: tests[language],
    });
  },
);

export const fetchTestState = createAsyncThunk(
  'testSeries/fetchTestState',
  async ({ courseId, testId, isMockTest = false }, thunkAPI) => {
    const currentState = thunkAPI.getState();

    const { testInfo, isReattempt } = currentState.testSeries;
    const { numberOfQuestions } = testInfo.detail ?? {};

    if (isReattempt) {
      return thunkAPI.fulfillWithValue({
        testState: Array(numberOfQuestions).fill(null),
      });
    }

    try {
      let response = null;
      if (isMockTest) {
        const { authToken } = await getAuthState();

        response = await axios.get(
          `${FIXED_MOCK_LAMBDA_API}/get-fixed-mock-data`,
          {
            params: { courseId, mappingId: testId },
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          },
        );
      } else {
        response = await javaApi.get(
          'app-test-series-ws/api/v1/test-series/request-state',
          { params: { courseId, mappingId: testId } },
        );
      }

      if (isMockTest && response.data.success) {
        let testStateRes = null;
        try {
          testStateRes = await axios.get(response.data.data.getSignedUrl);
        } catch (error) {
          const { postSignedUrl } = response.data.data;
          await saveFixedMockState(postSignedUrl?.url, postSignedUrl?.fields);

          testStateRes = await axios.get(response.data.data?.getSignedUrl);
        }

        response.data.data.testState =
          typeof testStateRes?.data === 'object' ? testStateRes?.data : Array(numberOfQuestions).fill(null);
      }

      if (
        response.status === 200 &&
        response.data?.success &&
        response.data?.data?.testState
      ) {
        if (isMockTest !== true) {
          try {
            const oldTestState = JSON.parse(response.data?.data?.testState);

            // response.data.data.testState = (activeTests?.[selectedLanguage]?.ques?.list ?? []).map((ques) => {
            //   const oldState = oldTestState
            //       .find((quesState) => parseInt(ques?.qMapId, 10) === parseInt(quesState?.questionMapping, 10));
            //
            //   if (oldState) {
            //     return {
            //       ...oldState,
            //       questionMapping: String(oldState?.questionMapping),
            //       selectedAns: String(oldState.selectedAns),
            //     };
            //   }
            //
            //   return null;
            // });

            response.data.data.testState = Array(numberOfQuestions).fill(null);
            if (oldTestState.length === numberOfQuestions) {
              response.data.data.testState = oldTestState;
            }
          } catch (error) {
            response.data.data.testState = Array(numberOfQuestions).fill(null);
          }
        }

        return thunkAPI.fulfillWithValue(response.data.data);
      }

      return thunkAPI.rejectWithValue('Api Error!');
    } catch (e) {
      return thunkAPI.rejectWithValue('Api Error!');
    }
  },
);

export const startTest = createAsyncThunk(
  'testSeries/startTest',
  async (payload, thunkAPI) => {
    try {
      const { courseId, testId, isFixedMockActive } = thunkAPI.getState().testSeries;

      const requestData = {
        courseId,
        mappingId: testId,
      };

      let response = null;
      if (isFixedMockActive) {
        const { authToken } = await getAuthState();

        response = await axios.post(
          `${FIXED_MOCK_LAMBDA_API}/start-fixed-mock`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          },
        );
      } else {
        response = await javaApi.get('app-test-series-ws/api/v1/test-series/start-test', {
          params: requestData,
        });
      }

      try {
        // Fetch Test Series State
        await thunkAPI.dispatch(fetchTestState({
          courseId,
          testId,
          isMockTest: isFixedMockActive,
        }));
      } catch (e) { /* empty */ }

      if (response.status === 200 && response.data?.success) {
        if (isFixedMockActive) {
          const postSignedUrl = response.data.data?.postSignedUrl;
          await saveFixedMockState(postSignedUrl?.url, postSignedUrl?.fields);
        }

        return thunkAPI.fulfillWithValue({
          testData: response.data?.data,
        });
      }

      return thunkAPI.rejectWithValue('API Error!');
    } catch (e) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const saveTestState = createAsyncThunk(
  'testSeries/saveTestState',
  async (payload, thunkAPI) => {
    try {
      const {
        courseId, testId, testState, testInfo, selectedLanguage,
        activeTests, isFixedMockActive, isReattempt,
      } = thunkAPI.getState().testSeries;
      const testStateDraft = testState.detail ? cloneDeep(testState.detail) : testState.detail;
      const postSignedUrl = testInfo?.detail?.detail?.postSignedUrl;

      if (payload?.questionMapping) {
        const currentQuestionIndex = (activeTests?.[selectedLanguage]?.ques?.list ?? [])
          .findIndex((question) => payload?.questionMapping === question?.qMapId);
        const currentQuestionState = testStateDraft[currentQuestionIndex] ?? {};

        testStateDraft[currentQuestionIndex] = {
          ...currentQuestionState,
          ...payload,
          timeTaken: payload.timeTaken * 1000,
          visited: currentQuestionState?.visited ?? payload?.visited,
        };
      }

      if (isReattempt) return thunkAPI.fulfillWithValue(testStateDraft);

      let response = null;
      if (isFixedMockActive) {
        response = await saveFixedMockState(
          postSignedUrl?.url,
          postSignedUrl?.fields,
          testStateDraft,
        );
      } else {
        response = await javaApi.post(
          'app-test-series-ws/api/v1/test-series/save-state',
          {
            courseId,
            mappingId: testId,
            testState: JSON.stringify(testStateDraft),
          },
        );
      }

      if (response?.data?.testState) {
        return thunkAPI.fulfillWithValue(JSON.parse(response?.data?.testState));
      }

      return thunkAPI.fulfillWithValue(testStateDraft);
    } catch (e) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const viewResultState = createAsyncThunk(
  'testSeries/viewResultState',
  async ({ courseId, testId }, thunkAPI) => {
    try {
      const response = await javaApi.get(
        `app-test-series-ws/api/v1/test-series/view-result?courseId=${courseId}&mappingId=${testId}`,
      );

      if (response?.data?.success) {
        return thunkAPI.fulfillWithValue(response?.data?.data);
      }

      return thunkAPI.fulfillWithValue(response?.data?.data);
    } catch (e) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const markForReview = createAsyncThunk(
  'testSeries/markForReview',
  async ({ questionId, status }, thunkAPI) => {
    try {
      const { testState } = thunkAPI.getState().testSeries;
      const testStateDraft = testState.detail ? cloneDeep(testState.detail) : testState.detail;

      const currentQuestionIndex = testStateDraft
        .findIndex((question) => parseInt(questionId, 10) === parseInt(question?.questionMapping, 10));
      if (currentQuestionIndex >= 0) {
        testStateDraft[currentQuestionIndex].markedForReview = status;

        if (
          status === false &&
          testStateDraft[currentQuestionIndex].selectedAns === TEST_SERIES_CONSTANTS.MARKED_FOR_REVIEW
        ) {
          testStateDraft[currentQuestionIndex].selectedAns = TEST_SERIES_CONSTANTS.NOT_ANSWERED;
        }
      }

      return thunkAPI.fulfillWithValue(testStateDraft);
    } catch (e) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const pauseTest = createAsyncThunk(
  'testSeries/pauseTest',
  async (payload, thunkAPI) => {
    thunkAPI.dispatch(saveTestState());

    return thunkAPI.fulfillWithValue(true);
  },
);

export const resumeTest = createAction('testSeries/resumeTest');

export const submitTest = createAsyncThunk(
  'testSeries/submitTest',
  async (payload, thunkAPI) => {
    const {
      courseId, testId, testState, selectedLanguage,
      activeTests, isFixedMockActive,
    } = thunkAPI.getState().testSeries;
    const testStateDraft = testState.detail ? cloneDeep(testState.detail) : testState.detail;

    if (payload?.questionMapping) {
      const currentQuestionIndex = (activeTests?.[selectedLanguage]?.ques?.list ?? [])
        .findIndex((question) => payload?.questionMapping === question?.qMapId);
      const currentQuestionState = testStateDraft[currentQuestionIndex] ?? {};

      testStateDraft[currentQuestionIndex] = {
        ...currentQuestionState,
        ...payload,
        timeTaken: payload.timeTaken,
        visited: currentQuestionState?.visited ?? payload?.visited,
      };
    }

    await thunkAPI.dispatch(saveTestState());

    const requestPayload = {
      courseId,
      mappingId: testId,
      testState: testStateDraft,
    };

    try {
      if (isFixedMockActive) {
        await javaApi.post(
          '/app-test-series-ws/api/v1/test-series/submitFixedMock',
          requestPayload,
        );
      } else {
        await javaApi.post(
          '/app-test-series-ws/api/v1/test-series/submit',
          requestPayload,
        );
      }

      // if (response?.data?.success) {
      //   await thunkAPI.dispatch(fetchTestInfo({
      //     courseId,
      //     testId,
      //     isPurchased: true,
      //     haveResultDeclared: response.data.resultTime <= new Date().getTime(),
      //   }));
      // }

      return thunkAPI.fulfillWithValue(true);
    } catch (e) {
      return thunkAPI.rejectWithValue(true);
    }
  },
);

export const fetchTestSolution = createAsyncThunk(
  'testSeries/fetchTestSolution',
  async ({ courseId, testId }, thunkAPI) => {
    try {
      const { auth: { authUser }, testSeries: { selectedLanguage, activeTests, testState } } = thunkAPI.getState();
      const currentTestData = activeTests[selectedLanguage] ?? {};

      const response = await javaApi.get(
        '/app-test-series-ws/api/v1/test-series/view-submitted-state',
        {
          params: {
            courseId,
            mappingId: testId,
            email: authUser.email,
          },
        },
      );

      const currentTestQuestions = currentTestData?.ques?.list ?? [];
      const userResponse = response?.data?.data?.testState || [];
      const userTestState = testState?.detail || [];

      const finalUserResult = currentTestQuestions.map((ques) => {
        const userAnswer = userResponse
          .find((userAns) => parseInt(userAns?.questionMapping, 10) === parseInt(ques?.qMapId, 10));
        const questionState = userTestState
          .find((quesState) => parseInt(quesState?.questionMapping, 10) === parseInt(ques?.qMapId, 10));

        let correctAns = ques?.opt?.findIndex((opt) => opt?.co);
        if (ques?.questionType === 2) {
          correctAns = find(
            ques?.fitbAnswer ?? [],
            (ans) => String(ans).trim().toLowerCase() === String(userAnswer?.textAns).trim().toLowerCase(),
          );

          if (!correctAns) {
            correctAns = ques?.fitbAnswer[0];
          }
        }

        return {
          quesId: parseInt(ques?.qMapId, 10),
          userAns: userAnswer?.textAns ?? userAnswer?.selectedAns ?? null,
          correctAns,
          markedForReview: questionState?.markedForReview ?? false,
        };
      });

      return thunkAPI.fulfillWithValue(finalUserResult);
    } catch (e) {
      return thunkAPI.rejectWithValue(false);
    }
  },
);

// Slice
const testSeriesSlice = createSlice({
  name: 'testSeries',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestInfo.pending, (state) => {
        state.testInfo = {
          ...initialState.testInfo,
          loading: true,
        };
      })
      .addCase(fetchTestInfo.fulfilled, (state, { payload }) => {
        state.courseId = payload.courseId;
        state.testId = payload.testId;
        state.isSectionalTiming = payload.testInfo.isSectionalTiming ?? false;
        state.testDuration = payload.testDuration;
        state.isReattempt = payload.isReattempt;
        state.isFixedMock = payload.testInfo.isFixedMock;
        state.isFixedMockActive = payload.testInfo.isFixedMockActive;
        state.isFixedMockResultDeclared = payload.testInfo.isFixedMockResultDeclared;
        state.isCalculatorEnabled = payload.testInfo.haveCalculator;

        state.testInfo = {
          ...initialState.testInfo,
          detail: payload.testInfo,
        };
      })
      .addCase(fetchTestInfo.rejected, (state, { payload }) => {
        state.testInfo = {
          ...initialState.testInfo,
          error: payload,
        };
      })
      .addCase(selectTestLanguage.fulfilled, (state, { payload }) => {
        state.selectedLanguage = payload.language;
        state.activeTests[payload.language] = payload.test;
      })
      .addCase(startTest.pending, (state) => {
        state.isLoading = true;
        state.isTestStarted = false;
      })
      .addCase(startTest.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isTestStarted = true;
        state.testInfo.detail.detail = payload.testData;
      })
      .addCase(startTest.rejected, (state) => {
        state.isLoading = false;
        state.isTestStarted = false;
      })
      .addCase(fetchTestState.pending, (state) => {
        state.testState = {
          ...initialState.testState,
          loading: true,
        };
      })
      .addCase(fetchTestState.fulfilled, (state, { payload }) => {
        state.testState = {
          ...initialState.testState,
          detail: payload.testState,
        };
      })
      .addCase(fetchTestState.rejected, (state, { payload }) => {
        state.testState = {
          ...initialState.testState,
          error: payload,
          detail: Array(state.testInfo?.detail?.numberOfQuestions ?? 0).fill(null),
        };
      })
      .addCase(saveTestState.pending, (state) => {
        state.testState = {
          ...initialState.testState,
          detail: state.testState.detail,
          loading: true,
        };
      })
      .addCase(saveTestState.fulfilled, (state, { payload }) => {
        state.testState = {
          ...initialState.testState,
          detail: payload,
        };
      })
      .addCase(saveTestState.rejected, (state, { payload }) => {
        state.testState = {
          ...initialState.testState,
          detail: state.testState.detail,
          error: payload,
        };
      })
      .addCase(viewResultState.pending, (state) => {
        state.resultState = {
          ...initialState.resultState,
          detail: state.resultState.detail,
          loading: true,
        };
      })
      .addCase(viewResultState.fulfilled, (state, { payload }) => {
        state.resultState = {
          ...initialState.resultState,
          detail: payload,
        };
      })
      .addCase(viewResultState.rejected, (state, { payload }) => {
        state.resultState = {
          ...initialState.resultState,
          detail: state.resultState.detail,
          error: payload,
        };
      })
      .addCase(pauseTest.fulfilled, (state) => {
        state.isPaused = true;
      })
      .addCase(resumeTest, (state) => {
        state.isPaused = false;
      })
      .addCase(markForReview.fulfilled, (state, { payload }) => {
        state.testState = {
          ...initialState.testState,
          detail: payload,
        };
      })
      .addCase(fetchTestSolution.pending, (state) => {
        state.testSolution = {
          ...initialState.testSolution,
          loading: true,
        };
      })
      .addCase(fetchTestSolution.fulfilled, (state, { payload }) => {
        state.testSolution = {
          ...initialState.testSolution,
          detail: payload,
        };
      })
      .addCase(fetchTestSolution.rejected, (state, { payload }) => {
        state.testSolution = {
          ...initialState.testSolution,
          error: payload,
        };
      });
  },
});

export default testSeriesSlice.reducer;
