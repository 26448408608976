/* eslint-disable no-unsafe-optional-chaining, no-undef */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { javaApi } from '../../utils/AxiosInstance';

const initialState = {
  loading: false,
  policyContent: '',
  error: null,
};

export const getPolicyPageContent = createAsyncThunk('policy_content', async (thunkAPI) => {
  try {
    const response = await javaApi.get('app-content-ws/v1/html/pages/getAllPages');

    console.log('Policy Page Response', response);

    if (response?.data?.success === 1) {
      return { ...response?.data?.data };
    }
    return thunkAPI.rejectWithValue(response?.msg);
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

const policyPageSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPolicyPageContent.pending, (state) => {
        state.loading = true;
        state.policyContent = '';
        state.error = null;
      })
      .addCase(getPolicyPageContent.fulfilled, (state, action) => {
        state.loading = false;
        state.policyContent = action?.payload;
        state.error = null;
      })
      .addCase(getPolicyPageContent.rejected, (state, action) => {
        state.loading = false;
        state.policyContent = '';
        state.error = action?.payload;
      });
  },
});

export default policyPageSlice.reducer;
