/* eslint-disable no-plusplus, camelcase, function-paren-newline */
import find from 'lodash.find';
import sumBy from 'lodash.sumby';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getUserTokens } from '../../utils/AnswerWritingUtil';
import { javaApi, javaStoreApi, phpApi } from '../../utils/AxiosInstance';
import { addCourseToLocalStorage, isCrmOrder, objToCamelCase, removeCourseFromLocalStorage } from '../../utils/helper';
import { showLogin } from './authSlice/actions';
import { EVENT_NAME, trackMoengageEvent } from '../../hooks/useMoengage';
import {
  CONTINUE_PURCHASE_PRODUCTS_STORAGE_KEY,
  RECENT_VISITED_PRODUCTS_STORAGE_KEY,
} from '../../constants/api';

const initialState = {
  loading: false,
  currentCourse: {
    courseId: null,
    languageId: null,
    checkoutAction: null,
  },
  currentScene: 'PACKAGE', // PACKAGE | ADDRESS | CHECKOUT
  sceneHistory: ['PACKAGE'],
  paymentType: null,
  currentPackage: null,
  paymentMethods: [],
  appliedCoupon: {
    loading: false,
    detail: null,
    error: null,
  },
  crmData: {
    loading: false,
    detail: null,
    error: null,
  },
  coursePurchase: {
    loading: false,
    detail: null,
    error: null,
  },
  crmOrder: {
    loading: false,
    detail: null,
    error: null,
  },
  orderPayment: {
    loading: false,
    detail: null,
    error: null,
  },
  validatePincode: {
    loading: false,
    detail: null,
    error: null,
  },
  paymentProcess: {
    loading: false,
    processor: null, // PAYTM | CC_AVENUE
    detail: null,
    error: null,
  },
  orderAddons: [],
};

// Actions
export const changeCheckoutLoading = createAction('checkout/changeLoading', (isLoading) => ({
  payload: isLoading,
}));

export const changeSceneHistory = createAction('checkout/changeSceneHistory', (sceneHistory) => ({
  payload: sceneHistory,
}));

export const changeScene = createAction('checkout/changeScene', (scene) => ({
  payload: scene ?? 'PACKAGE',
}));

export const changePaymentType = createAction('checkout/changePaymentType', (paymentType) => ({
  payload: paymentType,
}));

export const resetPaymentProcess = createAction('checkout/resetPaymentProcess');

export const startBuyNowProcess = createAsyncThunk('checkout/startBuyNowProcess', async (payload, thunkAPI) => {
  try {
    const { userID, apiToken } = getUserTokens();

    if (payload.checkoutAction === 1) {
      let ContinuePurchaseProducts = window.localStorage.getItem(RECENT_VISITED_PRODUCTS_STORAGE_KEY) || '[]';
      try {
        ContinuePurchaseProducts = JSON.parse(ContinuePurchaseProducts);
      } catch {
        ContinuePurchaseProducts = [];
      }

      const courseDetail = find(ContinuePurchaseProducts, { courseId: payload.courseId });
      if (courseDetail) {
        addCourseToLocalStorage(courseDetail, CONTINUE_PURCHASE_PRODUCTS_STORAGE_KEY);
        removeCourseFromLocalStorage(payload.courseId, RECENT_VISITED_PRODUCTS_STORAGE_KEY);
        trackMoengageEvent(EVENT_NAME.CONTINUE_PURCHASE, courseDetail, userID && apiToken);
      }
    }

    if (!userID || !apiToken) {
      thunkAPI.dispatch(showLogin());

      return thunkAPI.rejectWithValue('Not Authenticated');
    }

    return thunkAPI.fulfillWithValue(payload);
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const getCrmOrderInitiate = createAsyncThunk(
  'checkout/getCrmOrderInitiate',
  async ({ orderInitiateId, userKey }, thunkAPI) => {
    try {
      const res = await phpApi.post(`/crm_order_initiate?order_initiate_id=${orderInitiateId}&key=${userKey}`,
      );
      if (res?.status === 200 && res?.data?.success) {
        const data = res?.data?.data;
        if (data?.crm_data) {
          let selling_price = data?.course_package_detail?.selling_price;
          let base_price = data?.course_package_detail?.base_price;
          let initial_price = data?.course_package_detail?.initial_price;
          for (let i = 0; i < data.crm_data.length; i++) {
            selling_price += data.crm_data[i].selling_price;
            base_price += data.crm_data[i].base_price;
            initial_price += data.crm_data[i].initial_price;
          }
          const crm_multi_price = {
            selling_price,
            base_price,
            initial_price,
            discount_price: base_price - selling_price,
          };
          data.crm_multi_price = crm_multi_price;
          const courseTypeId = data?.course_detail?.course_type_id;
          if (courseTypeId === 2 || courseTypeId === 5) {
            data.crmCourseType = 'BOOK';
          } else {
            data.crmCourseType = 'NON_BOOK';
          }
          if (data?.crm_data?.length > 0) {
            const courseTypeIdArr = data?.crm_data?.map((elem) => elem?.course_type_id);
            if (courseTypeIdArr?.includes(2) || courseTypeIdArr?.includes(5)) {
              data.crmCourseType = 'BOOK';
            }
          }
        }
        return data;
      }

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: res?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const fetchPaymentMethods = createAsyncThunk('checkout/fetchPaymentMethods', async (payload, thunkAPI) => {
  try {
    const response = await javaStoreApi.post('v1/payment/methods');
    if (response?.data?.success) return response?.data?.data;

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const courseAddon = createAsyncThunk(
  'checkout/courseAddon',
  async ({ courseId, orderInitiateId, action }, thunkAPI) => {
    try {
      const response = await javaStoreApi.post('v1/buyNow/addon/add-remove', { courseId, orderInitiateId, action });

      const { success, data: { secondKey } } = response.data;
      if (success) return thunkAPI.fulfillWithValue({ courseId, action, secondKey });

      return thunkAPI.rejectWithValue('API Error!');
    } catch (e) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);
export const validatePincode = createAsyncThunk(
  'checkout/validatePincode',
  async ({ pinCode }, thunkAPI) => {
    try {
      const res = await javaApi.post(`user-auth-ws/api/v1/pincode?pincode=${pinCode}`);
      if (res?.data?.success === 1) return res?.data;
      return thunkAPI.rejectWithValue('API Error!');
    } catch (e) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);
export const courseAddonAdd = (payload) => courseAddon({ ...payload, action: 2 });
export const courseAddonRemove = (payload) => courseAddon({ ...payload, action: 1 });

export const applyDiscountCoupon = createAsyncThunk(
  'checkout/applyDiscountCoupon',
  async ({ courseId, packageId, couponCode, languageId, paymentType, checkoutAction }, thunkAPI) => {
    try {
      const response = await javaStoreApi.post('v1/buyNow/apply/coupon', {
        courseId,
        packageId,
        couponCode,
        languageId,
        paymentType,
        action: checkoutAction,
      });

      if (response?.data?.success) return thunkAPI.fulfillWithValue(response?.data?.data);

      return thunkAPI.rejectWithValue(response?.data?.msg);
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const crmOrderInitiate = createAsyncThunk(
  'checkout/crmOrderInitiate',
  async ({ orderInitiateId, userKey }, thunkAPI) => {
    try {
      const response = await phpApi.get('crm_order_initiate', {
        params: { order_initiate_id: orderInitiateId, key: userKey },
      });

      const { success, data } = response.data;
      if (success) {
        const { crm_data: crmData, course_package_detail: coursePackageDetail } = data;
        if (crmData && coursePackageDetail) {
          let { selling_price: sellingPrice, base_price: basePrice, initial_price: initialPrice } = coursePackageDetail;
          // eslint-disable-next-line no-plusplus,no-restricted-syntax
          for (const crm of crmData) {
            sellingPrice += crm.selling_price;
            basePrice += crm.base_price;
            initialPrice += crm.initial_price;
          }

          data.multiPrice = {
            selling_price: sellingPrice,
            base_price: basePrice,
            initial_price: initialPrice,
            discount_price: basePrice - sellingPrice,
          };

          data.paymentAction = coursePackageDetail.action;
        }

        return thunkAPI.fulfillWithValue(data);
      }

      return thunkAPI.rejectWithValue('API Error!');
    } catch (e) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const purchaseCourse = createAsyncThunk(
  'checkout/purchaseCourse',
  async (
    {
      courseId,
      packageId,
      couponCode,
      languageId,
      paymentType,
      checkoutAction,
      courseTypeId,
      userAddress = null,
      updatedUserProfile = false,
      modeData,
    },
    thunkAPI,
  ) => {
    try {
      const requestData = {
        courseId,
        packageId,
        languageId,
        paymentType,
        courseTypeId,
        isCrm: isCrmOrder(),
        action: checkoutAction,
      };

      if (couponCode) {
        requestData.couponCode = couponCode;
      }
      const state = thunkAPI.getState();
      if (userAddress?.pinCode || state?.checkout?.crmData?.detail?.crmCourseType === 'BOOK') {
        requestData.courseTypeId = 2;
        requestData.fullName = userAddress.name?.trim();
        requestData.mobile = userAddress.phone;
        requestData.emailAddress = userAddress.email;
        requestData.address = userAddress.address?.trim() || '';
        requestData.area = userAddress.area?.trim() || '';
        requestData.city = userAddress.city?.trim() || '';
        requestData.state = userAddress.state?.trim() || '';
        requestData.pinCode = userAddress.pin_code || '';
        requestData.landmark = userAddress.landmark || '';
      }
      if (modeData?.mode === 'offline' && modeData?.location) {
        requestData.offlineCentreId = parseInt(modeData.location, 10);
      }

      const response = await javaStoreApi.post(
        'v1/buyNow/course/purchase',
        requestData,
        { extraHeaders: { platform: 'WEB', version: '1.1.1' } },
      );

      if (response.data.success) {
        if (updatedUserProfile) {
          await javaApi.post('user-auth-ws/api/v1/update/user/profile', requestData);
        }

        return thunkAPI.fulfillWithValue(response?.data?.data);
      }

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const processOrderPayment = createAsyncThunk(
  'checkout/processOrderPayment',
  async ({ bookLanguageId, addOnBookLanguageId, orderInitiateId, paymentStream, orderVia = 'Web' }, thunkAPI) => {
    try {
      const response = await javaStoreApi.post(
        'v1/buyNow/create/order',
        {
          orderVia,
          paymentStream,
          orderInitiateId,
          bookLanguageId,
          addOnBookLanguageId,
          isCrm: isCrmOrder(),
        },
        {
          params: {
            platform: 'web',
          },
        },
      );

      const { success, data, msg } = response.data;
      if (success) return thunkAPI.fulfillWithValue(data);

      return thunkAPI.rejectWithValue(msg);
    } catch (e) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const removeDiscountCoupon = createAction('checkout/removeDiscountCoupon');

export const setCurrentPackage = createAction('checkout/setCurrentPackage');

export const startCcAvenuePaymentProcess = createAsyncThunk(
  'checkout/startCcAvenuePaymentProcess',
  async ({ orderId, orderPrice, orderAction, paymentOption }, thunkAPI) => {
    try {
      const { userID, apiToken } = getUserTokens();

      const formData = new FormData();
      formData.append('order_id', orderId);
      formData.append('order_price', orderPrice);
      formData.append('action', orderAction);
      formData.append('payment_option', paymentOption || 'OPTNBK');
      formData.append('user_id', userID);
      formData.append('api_token', apiToken);

      const response = await phpApi.post('postpayment', formData);

      const { success, data, msg } = response.data;
      if (success) return thunkAPI.fulfillWithValue(data);

      return thunkAPI.rejectWithValue(msg);
    } catch (e) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const startPaytmPaymentProcess = createAsyncThunk(
  'checkout/startPaytmPaymentProcess',
  async ({ orderId, orderAction }, thunkAPI) => {
    try {
      const response = await javaStoreApi.post(
        `v1/paytm/initiate/transaction?orderId=${orderId}&source=web`,
        { orderId, action: orderAction },
      );
      const data = response.data?.data;
      const success = response?.data?.success;
      const submit = response?.data?.data?.submit;
      const msg = response?.data?.message;
      if (success) return thunkAPI.fulfillWithValue({ data, submit });

      return thunkAPI.rejectWithValue(msg);
    } catch (e) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const createRazorpayOrder = createAsyncThunk(
  'checkout/createRazorpayOrder',
  async ({ orderId, orderPrice }, thunkAPI) => {
    try {
      const { authUser } = thunkAPI.getState().auth || {};
      const { email, mobileNumber } = authUser;

      const formData = new FormData();
      formData.append('orderId', orderId);
      formData.append('amount', orderPrice);
      formData.append('email', email);
      formData.append('phone', mobileNumber);
      formData.append('origin', 'web');

      const response = await javaStoreApi.post('razorpay/createOrder', formData);

      const { success, data, message } = response.data;
      if (success) return thunkAPI.fulfillWithValue(data);

      return thunkAPI.rejectWithValue(message);
    } catch (e) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const resetCheckout = createAction('checkout/reset');

// Slice
const checkoutSlice = createSlice({
  name: 'Checkout',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(startBuyNowProcess.fulfilled, (state, { payload }) => {
        state.currentCourse = payload;
      })
      .addCase(changeCheckoutLoading, (state, { payload }) => {
        state.loading = payload;
      })
      .addCase(changeScene, (state, { payload }) => {
        let scene = payload;
        if (payload === -1) {
          scene = state.sceneHistory[state.sceneHistory.length - 2];
        }

        state.currentScene = scene;

        const indexOfScene = state.sceneHistory.indexOf(scene);
        if (indexOfScene > -1) {
          state.sceneHistory.splice(indexOfScene);
        }

        state.sceneHistory.push(scene);
      })
      .addCase(changeSceneHistory, (state, { payload }) => {
        state.sceneHistory = payload;
      })
      .addCase(changePaymentType, (state, { payload }) => {
        state.paymentType = payload;
      })
      .addCase(fetchPaymentMethods.pending, (state) => {
        state.loading = true;
        state.paymentMethods = [];
      })
      .addCase(fetchPaymentMethods.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentMethods = action?.payload;
      })
      .addCase(fetchPaymentMethods.rejected, (state) => {
        state.loading = false;
        state.paymentMethods = [];
      })
      .addCase(applyDiscountCoupon.pending, (state) => {
        state.appliedCoupon = {
          ...initialState.appliedCoupon,
          loading: true,
        };
      })
      .addCase(applyDiscountCoupon.fulfilled, (state, { payload }) => {
        state.appliedCoupon = {
          ...initialState.appliedCoupon,
          detail: payload,
        };
      })
      .addCase(applyDiscountCoupon.rejected, (state, { payload }) => {
        state.appliedCoupon = {
          ...initialState.appliedCoupon,
          error: payload,
        };
      })
      .addCase(removeDiscountCoupon, (state) => {
        state.appliedCoupon = initialState.appliedCoupon;
      })
      .addCase(setCurrentPackage, (state, { payload }) => {
        state.currentPackage = payload;
      })
      .addCase(purchaseCourse.pending, (state, { meta: { arg: { courseId, packageId, languageId } } }) => {
        if (
          state.currentCourse.courseId !== courseId ||
          state.currentCourse.packageId !== packageId ||
          state.currentCourse.languageId !== languageId
        ) {
          state.coursePurchase = {
            ...initialState.coursePurchase,
            loading: true,
          };

          state.paymentMethods = initialState.paymentMethods;
        }
      })
      .addCase(purchaseCourse.fulfilled, (state, { payload }) => {
        const { paymentOptions, ...rest } = objToCamelCase(payload);
        const includedAddons = (rest.addons || []).filter((addon) => addon.included);

        state.coursePurchase = {
          ...initialState.coursePurchase,
          detail: {
            ...rest,
            finalSellingPrice: rest.sellingPrice + sumBy(includedAddons, 'sellingPrice'),
          },
        };

        state.paymentMethods = paymentOptions;
        state.orderAddons = includedAddons.map((addon) => addon.courseId);
      })
      .addCase(purchaseCourse.rejected, (state, { payload }) => {
        state.coursePurchase = {
          ...initialState.coursePurchase,
          error: payload,
        };
      })
      .addCase(crmOrderInitiate.pending, (state) => {
        state.crmOrder = {
          ...initialState.crmOrder,
          loading: true,
        };
      })
      .addCase(crmOrderInitiate.fulfilled, (state, { payload }) => {
        state.crmOrder = {
          ...initialState.crmOrder,
          detail: objToCamelCase(payload),
        };
      })
      .addCase(crmOrderInitiate.rejected, (state, { payload }) => {
        state.crmOrder = {
          ...initialState.crmOrder,
          error: payload,
        };
      })
      .addCase(processOrderPayment.pending, (state) => {
        state.orderPayment = {
          ...initialState.orderPayment,
          loading: true,
        };
      })
      .addCase(processOrderPayment.fulfilled, (state, { payload }) => {
        state.orderPayment = {
          ...initialState.orderPayment,
          detail: objToCamelCase(payload),
        };
      })
      .addCase(processOrderPayment.rejected, (state, { payload }) => {
        state.orderPayment = {
          ...initialState.orderPayment,
          error: payload,
        };
      })
      .addCase(validatePincode.pending, (state) => {
        state.validatePincode = {
          ...initialState.validatePincode,
          loading: true,
        };
      })
      .addCase(validatePincode.fulfilled, (state, { payload }) => {
        state.validatePincode = {
          ...initialState.validatePincode,
          detail: payload,
        };
      })
      .addCase(validatePincode.rejected, (state, { payload }) => {
        state.validatePincode = {
          ...initialState.validatePincode,
          error: payload,
        };
      })
      .addCase(startCcAvenuePaymentProcess.pending, (state) => {
        state.paymentProcess = {
          ...initialState.paymentProcess,
          processor: 'CC_AVENUE',
          loading: true,
        };
      })
      .addCase(startCcAvenuePaymentProcess.fulfilled, (state, { payload }) => {
        state.paymentProcess = {
          ...initialState.paymentProcess,
          processor: 'CC_AVENUE',
          detail: payload,
        };
      })
      .addCase(startCcAvenuePaymentProcess.rejected, (state, { payload }) => {
        state.paymentProcess = {
          ...initialState.paymentProcess,
          processor: 'CC_AVENUE',
          error: payload,
        };
      })
      .addCase(startPaytmPaymentProcess.pending, (state) => {
        state.paymentProcess = {
          ...initialState.paymentProcess,
          processor: 'PAYTM',
          loading: true,
        };
      })
      .addCase(startPaytmPaymentProcess.fulfilled, (state, { payload: { data, submit } }) => {
        state.paymentProcess = {
          ...initialState.paymentProcess,
          processor: 'PAYTM',
          detail: {
            requestUrl: submit,
            payload: data,
          },
        };
      })
      .addCase(startPaytmPaymentProcess.rejected, (state, { payload }) => {
        state.paymentProcess = {
          ...initialState.paymentProcess,
          processor: 'PAYTM',
          error: payload,
        };
      })
      .addCase(createRazorpayOrder.pending, (state) => {
        state.paymentProcess = {
          ...initialState.paymentProcess,
          processor: 'RAZORPAY',
          loading: true,
        };
      })
      .addCase(createRazorpayOrder.fulfilled, (state, { payload }) => {
        state.paymentProcess = {
          ...initialState.paymentProcess,
          processor: 'RAZORPAY',
          detail: payload,
        };
      })
      .addCase(createRazorpayOrder.rejected, (state, { payload }) => {
        state.paymentProcess = {
          ...initialState.paymentProcess,
          processor: 'RAZORPAY',
          error: payload,
        };
      })
      .addCase(resetPaymentProcess, (state) => {
        state.paymentProcess = initialState.paymentProcess;
      })
      .addCase(getCrmOrderInitiate.pending, (state) => {
        state.crmData = {
          ...initialState.crmData,
          loading: true,
        };
      })
      .addCase(getCrmOrderInitiate.fulfilled, (state, { payload }) => {
        state.crmData = {
          ...initialState.crmData,
          detail: payload,
        };
      })
      .addCase(getCrmOrderInitiate.rejected, (state, { payload }) => {
        state.crmData = {
          ...initialState.crmData,
          error: payload,
        };
      })
      .addCase(courseAddon.fulfilled, (state, { payload: { action, courseId, secondKey } }) => {
        if (action === 2) {
          state.orderAddons.push(courseId);
        } else if (action === 1) {
          state.orderAddons = state.orderAddons.filter((addonId) => addonId !== courseId);
        }

        const orderAddons = state.coursePurchase.detail.addons.map((addon) => ({
          ...addon,
          included: state.orderAddons.includes(addon.courseId),
        }));
        const includedAddons = orderAddons.filter((orderAddon) => orderAddon.included);

        state.coursePurchase.detail.addons = orderAddons;
        state.coursePurchase.detail.finalSellingPrice =
          state.coursePurchase.detail.sellingPrice + sumBy(includedAddons, 'sellingPrice');

        state.coursePurchase.detail.addonSecondKey = parseInt(secondKey, 10);
      })
      .addCase(resetCheckout, () => initialState);
  },
});

export default checkoutSlice.reducer;
