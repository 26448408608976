export * from './api.common';

export const API_DOMAIN = 'https://www.studyiq.net/api';
export const PHP_API_BASE_URL = 'https://www.studyiq.net/api/web/';
export const JAVA_API_BASE_URL = 'https://backend.studyiq.net/';
export const JAVA_STORE_API_BASE_URL = 'https://backend.studyiq.net/store-ws/';
export const IMAGE_PREFIX = 'https://www.studyiq.net/images';
export const FRONTEND_DOMAIN = 'https://www.studyiq.com';
export const DE_API_DOMAIN = 'https://dataengg.studyiq.com';
export const JAVA_ANSWER_WRITING_API_BASE_URL =
  'https://assessment-service-api-prod.adda247.com/';
export const JAVA_QUESTION_SERVICE_API_BASE_URL =
'https://question-service-prod.adda247.com/';
export const JAVA_ANSWER_WRITING_ASSESSMENT_API_BASE_URL =
  'https://assessment-evaluation-api-prod.adda247.com/';
export const MEDIA_UPLOADER_API_DOMAIN =
  'https://media-uploader-prod.adda247.com';
export const LIVE_CLASS_STUDENT_JOIN_URL = 'https://www.studyiq.com/adda-live-class';
export const CC_AVENUE_URL = 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction';
export const CC_AVENUE_ACCESS_CODE = 'AVLQ70EE69AU47QLUA';

// Moengage
export const MOENGAGE_SDK_CONFIG = {
  app_id: 'KEOV3LEQVEDCYW3DTP9H7LTT',
  debug_logs: 0,
};

export const JAVA_API_TOKEN =
  '9A3BDDEEBD4DFB213D3C15D29126151206E47E4F';

export const LEAD_SQUARED_BASE_URL = 'https://api-in21.leadsquared.com/v2/';
export const LEAD_SQUARED_ACCESS_KEY = 'u$r97865798689454f71d2b109b9d9a6b4e';
export const LEAD_SQUARED_SECRET_KEY = 'ff706084867a23832bbd05e8fa5c117bb9dea056';
export const LCS_SERVICE_BASE_URL = 'https://lcs-service-prod.adda247.com/v1/';
export const FIXED_MOCK_LAMBDA_API = 'https://dfpaaic5ywuso.cloudfront.net/fixed-mock';
export const FIREBASE_VAPID_KEY = 'BHtIyEtWdwfIP3D-5VQCqL8g-pUu2BcXuQ6NckYrILgLGp1OOte1GQVLzFHlx-DP_8EyTKRBb22uJp6XsYGsH_0';
export const NOTIFICATION_SERVICE_BASE_URL = 'https://notification-service-prod.adda247.com/v1/';
export const USER_AUTH_SERVICE_BASE_URL = 'https://user-auth-api-prod.adda247.com/v1/';

export const LANGUAGE_SERVICE_BASE_URL = 'https://languageai-api-prod.adda247.com/v2/';

export const STIQ_ROBOTS_URL = 'https://d2tyahevw7sfp3.cloudfront.net/robots.txt';

// Timeout Constant

export const TIMEOUT_DURATION = 5000;