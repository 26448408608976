/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FormData from 'form-data';

import { phpApi } from '../../utils/AxiosInstance';

const initialState = {
  loading: false,
  coursePackageLoader: true,
  coursePackage: null,
  courseAddLoader: false,
  courseAddedToCart: false,
  cartLoader: false,
  cart: null,
  orderID: '',
  courseRemoved: false,
  paymentProcessStarted: false,
  paymentData: '',
  coupons: null,
  orderHistory: null,
  cross_sell_details: null,
  orderStatus: '',
  cartCoupons: null,
  isCouponValid: false,
  isCouponInValid: false,
  couponAmount: '',
  isAddressSaved: false,
  orderIDAfterPayment: '',

  isPaymentGatewayMethodsRequest: false,
  isPaymentGatewayMethodsSuccess: false,
  paymentGatewayMethods: '',

  isPayTmProcessStarted: false,
  paytmData: '',
  paytmUrl: '',
};

// Course Consumption Actions
export const startPaytmPaymentProcess = createAsyncThunk(
  'cartSlice/paytmPaymentProcessRequest',
  async ({ orderID, action }, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append('order_id', `${orderID}`);
      formData.append('action', `${action}`);
      const response = await phpApi.post('/initiate_token', formData);

      if (response?.data?.success) return response?.data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const startPaymentProcess = createAsyncThunk(
  'cartSlice/startPaymentProcess',
  async ({ orderID, orderPrice, action, payment_option }, thunkAPI) => {
    try {
      // const { userID, apiToken } = getUserTokens();
      // if (!userID || !apiToken) {
      //   await dispatch({ type: actions.SHOW_LOGIN, payload: true });
      //   await dispatch({ type: actions.PAYMENT_PROCESS_FAILURE });
      //   return;
      // }

      const formData = new FormData();
      formData.append('order_id', `${orderID}`);
      formData.append('order_price', `${orderPrice}`);
      formData.append('action', `${action}`);
      formData.append('payment_option', payment_option || 'OPTNBK');

      const res = await phpApi.post('/postpayment', formData);
      if (res.status === 200) return res?.data?.data;
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

const courseConsumptionSlice = createSlice({
  name: 'cart',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(startPaymentProcess.pending, (state) => {
        state.paymentProcessStarted = false;
        state.paymentData = '';
      })
      .addCase(startPaymentProcess.fulfilled, (state, action) => {
        state.paymentProcessStarted = true;
        state.paymentData = action.payload;
      })
      .addCase(startPaytmPaymentProcess.pending, (state) => {
        state.isPayTmProcessStarted = false;
        state.paytmData = '';
        state.paytmUrl = '';
      })
      .addCase(startPaytmPaymentProcess.fulfilled, (state, action) => {
        state.isPayTmProcessStarted = true;
        state.paytmData = action.payload.data;
        state.paytmUrl = action.payload.submit;
      });
  },
});

export default courseConsumptionSlice.reducer;
