/* eslint-disable camelcase */
import { Cookie } from 'next-cookie';

import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import FormData from 'form-data';
import moment from 'moment';
// eslint-disable-next-line import/no-unresolved
import { JAVA_API_BASE_URL } from 'src/constants/api';

import { javaApi, phpApi } from '../../utils/AxiosInstance';

import { logoutUser } from './authSlice/actions';

const initialState = {
  loading: false,
  activeCount: 0,
  courses: [],
  currentCourseType: null,
  courseTypes: [],
  courseTypesAll: [],
  continueWatch: {
    loading: false,
    list: [],
    error: null,
  },
  allLiveClass: {
    loading: false,
    list: [],
    error: null,
  },
  courseEMIDetails: {
    loading: false,
    courseEMIData: null,
    error: null,
  },
  emiModal: { show: false, courseId: null },
  paymentModal: false,
  selectedEMI: false,
  courseAmount: null,
  courseOrderNumber: null,
  liveClassDetails: {
    show: false,
    liveClassList: null,
  },
};

// Actions
export const fetchUserLibrary = createAsyncThunk('library/fetchUserLibrary', async (payload, thunkAPI) => {
  try {
    const response = await javaApi.get('/app-content-ws/api/v1/getAllPurchasedCourses?source=WEB');

    if (response?.data?.data) return response?.data?.data;

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const fetchCourseEmiDetails = createAsyncThunk('library/fetchCourseEmiDetails', async (payload, thunkAPI) => {
  try {
    return thunkAPI.rejectWithValue('API Error!');
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const removeCourseFromLibrary = createAsyncThunk(
  'library/removeCourseFromLibrary',
  async ({ courseID, courseAction }, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append('course_id', courseID);
      formData.append('action', courseAction);
      const cookies = new Cookie();
      const userID = cookies.get('userID');
      const apiToken = cookies.get('apiToken');

      const config = {
        method: 'delete',
        maxBodyLength: Infinity,
        // eslint-disable-next-line max-len
        url: `${JAVA_API_BASE_URL}app-content-ws/v1/course/deletecourse?courseId=${courseID}&action=${courseAction}&userId=${userID}`,
        headers: {
          source: 'web',
          token: apiToken,
        },
      };
      const response = await axios.request(config);
      if (response.data.success) {
        thunkAPI.dispatch(fetchUserLibrary({ preLoad: true }));
        return thunkAPI.fulfillWithValue(courseID);
      }

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const fetchContinueWatchingList = createAsyncThunk('library/fetch', async ({ courseId }, thunkAPI) => {
  try {
    const { authToken } = thunkAPI.getState().auth;

    const response = await javaApi.get('/app-consumption-ws/api/v1/video/videos/testseries/status?source=web', {
      params: {
        courseIds: courseId,
      },
      headers: { Authorization: `Bearer ${authToken}` },
    });

    if (response?.data?.data) return response?.data?.data;

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});
export const fetchAllLiveClassList = createAsyncThunk('library/fetchAllLiveClassList', async (thunkAPI) => {
  let date = new Date();
  date = moment(date).format('YYYY-MM-DD');
  try {
    const response = await javaApi.get(`${JAVA_API_BASE_URL}app-content-ws/api/v1/schedule/getCalendarLiveClass`, {
      params: {
        startDate: date,
        endDate: date,
        status: '1,2,3',
      },
    });
    const returnData = response?.data?.liveClasses && response?.data?.liveClasses[date];
    if (response?.data?.status === 200) return returnData;

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const getParentsForMultipleCourses = async (payload, thunkAPI) => {
  try {
    const response = await javaApi.post('/app-content-ws/v1/course/getParentsForMultipleCourses', payload);

    if (response?.data?.data) return response?.data?.data;

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
};

export const getUserEmiValidity = createAsyncThunk(
  'library/getUserEmiValidity',
  async ({ courseId, lanugageId }, thunkAPI) => {
    try {
      const cookie = new Cookie();
      const { apiToken: authToken, userID } = cookie.getAll();
      const formData = new FormData();
      formData.append('courseId', courseId);
      formData.append('languageId', lanugageId);
      formData.append('userId', userID);
      formData.append('apiToken', authToken);
      const response = await javaApi.post('app-content-ws/api/v1/get/user/emi/validity', formData);

      if (response?.data?.success) return response?.data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const generateCourseEMIOrder = createAsyncThunk(
  'library/generateCourseEMIOrder',
  async ({ courseID, languageID, emiID, amount, action, selectedPaymentStream }, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append('course_id', `${courseID}`);
      formData.append('language_id', `${languageID}`);
      formData.append('order_course_emi_id', `${emiID}`);
      formData.append('emi_amount', `${amount}`);
      formData.append('action', `${action}`);
      formData.append('payment_stream', `${selectedPaymentStream}`);

      const res = await phpApi.post('/generate_emi_order', formData);
      if (res.data.success === 1) return res?.data;
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: res?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const changeCurrentCourseType = createAction('library/changeCurrentCourseType', (payload) => ({ payload }));

// Slice
const librarySlice = createSlice({
  name: 'Library',
  initialState,
  reducers: {
    showEmiModal: (state, action) => {
      const { show, courseId } = action.payload;
      state.emiModal.show = show;
      state.emiModal.courseId = courseId;
    },
    showPaymentModal: (state, action) => {
      const { show } = action.payload;
      state.paymentModal = show;
    },
    selectedEMI: (state, action) => {
      const { emiData } = action.payload;
      state.selectedEMI = emiData;
    },
    liveClassContent: (state, action) => {
      const { details, show } = action.payload;
      state.liveClassDetails = {
        show,
        liveClassList: details,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserLibrary.pending, (state, action) => {
        state.loading = !action.meta?.arg?.preLoad;

        if (state.loading) {
          state.courses = [];
          state.courseTypes = [];
        }
      })
      .addCase(fetchUserLibrary.fulfilled, (state, action) => {
        state.loading = false;
        state.courses = action.payload;
      })
      .addCase(fetchUserLibrary.rejected, (state) => {
        state.loading = false;
        state.courses = [];
        state.courseTypes = [];
      })
      .addCase(fetchContinueWatchingList.pending, (state) => {
        state.continueWatch.loading = true;
        state.continueWatch.list = initialState.continueWatch.list;
        state.continueWatch.error = initialState.continueWatch.error;
      })
      .addCase(fetchContinueWatchingList.fulfilled, (state, action) => {
        state.continueWatch.loading = true;
        state.continueWatch.list = action.payload;
        state.continueWatch.error = initialState.continueWatch.error;
      })
      .addCase(fetchContinueWatchingList.rejected, (state) => {
        state.continueWatch.loading = true;
        state.continueWatch.list = [];
        state.continueWatch.error = 'Api Error!';
      })
      .addCase(fetchAllLiveClassList.pending, (state) => {
        state.allLiveClass.loading = true;
        state.allLiveClass.list = initialState.continueWatch.list;
        state.allLiveClass.error = initialState.continueWatch.error;
      })
      .addCase(fetchAllLiveClassList.fulfilled, (state, action) => {
        state.allLiveClass.loading = true;
        state.allLiveClass.list = action.payload;
        state.allLiveClass.error = initialState.allLiveClass.error;
      })
      .addCase(fetchAllLiveClassList.rejected, (state, action) => {
        state.allLiveClass.loading = true;
        state.allLiveClass.list = [];
        state.allLiveClass.error = action.payload;
      })
      .addCase(changeCurrentCourseType, (state, action) => {
        state.currentCourseType = action.payload;
      })
      .addCase(removeCourseFromLibrary.fulfilled, (state, action) => {
        state.courses = action.payload?.data;
        state.courseTypes = state.courses?.map((courseCat) => ({
          ID: courseCat?.course_type_id,
          type: courseCat?.course_type_name,
          title: courseCat?.course_display_name,
        }));
      })
      .addCase(logoutUser, (state) => {
        state.currentCourseType = null;
      })
      .addCase(getUserEmiValidity.pending, (state, action) => {
        state.courseEMIDetails.loading = true;
        state.courseEMIDetails.courseEMIData = action.payload;
      })
      .addCase(getUserEmiValidity.fulfilled, (state, action) => {
        state.courseEMIDetails.loading = false;
        state.courseEMIDetails.courseEMIData = action.payload;
      })
      .addCase(getUserEmiValidity.rejected, (state, action) => {
        state.courseEMIDetails.loading = false;
        state.courseEMIDetails.error = action.payload;
      })
      .addCase(generateCourseEMIOrder.pending, (state) => {
        state.courseAmount = '';
        state.courseOrderNumber = '';
      })
      .addCase(generateCourseEMIOrder.fulfilled, (state, action) => {
        state.courseAmount = action.payload.amount;
        state.courseOrderNumber = action.payload.order_no;
      });
  },
});

export const { showEmiModal, showPaymentModal, selectedEMI, liveClassContent } = librarySlice.actions;

export default librarySlice.reducer;
