import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { javaApi } from '../../utils/AxiosInstance';

const initialState = {
  loading: false,
  footerData: '',
  error: null,
};

export const getFooterData = createAsyncThunk('footer_section', async (thunkAPI) => {
  try {
    const response = await javaApi.get('common-ws/api/v1/web/footer-section');

    if (response?.data?.success === 1) {
      return { ...response?.data?.data };
    }
    return thunkAPI.rejectWithValue(response?.msg);
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

const footerSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getFooterData.pending, (state) => {
        state.loading = true;
        state.footerData = '';
        state.error = null;
      })
      .addCase(getFooterData.fulfilled, (state, action) => {
        state.loading = false;
        state.footerData = action?.payload;
        state.error = null;
      })
      .addCase(getFooterData.rejected, (state, action) => {
        state.loading = false;
        state.footerData = '';
        state.error = action?.payload;
      });
  },
});

export default footerSlice.reducer;
