import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { javaApi } from '../../utils/AxiosInstance';

const initialState = {
  loading: false,
  coachMarkData: [],
  error: null,
  message: null,
};

export const getCoachMarkStatus = createAsyncThunk('coachmark_status', async (thunkAPI) => {
  try {
    const response = await javaApi.get('user-auth-ws/v1/coach-marks-mappings/fetch');

    if (response?.data?.success) {
      return response?.data?.data;
    }
    return thunkAPI.rejectWithValue(response?.msg);
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const updateCoachMarkStatus = createAsyncThunk(
  'coachmark_status_update',
  async ({ sectionTypeId, isActiveID }, thunkAPI) => {
    try {
      const response = await javaApi.post(
        `user-auth-ws/v1/coach-marks-mappings/save?sectionTypeId=${sectionTypeId}&isActive=${isActiveID}`,
      );

      if (response?.data?.success) {
        await thunkAPI.dispatch(getCoachMarkStatus());
        return response?.data?.message;
      }
      return thunkAPI.rejectWithValue(response?.data?.message);
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

const coachMarkSlice = createSlice({
  name: 'coachmark',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCoachMarkStatus.pending, (state) => {
        state.loading = true;
        state.coachMarkData = '';
        state.error = null;
      })
      .addCase(getCoachMarkStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.coachMarkData = action?.payload;
        state.error = null;
      })
      .addCase(getCoachMarkStatus.rejected, (state, action) => {
        state.loading = false;
        state.coachMarkData = '';
        state.error = action?.payload;
      })
      .addCase(updateCoachMarkStatus.pending, (state) => {
        state.loading = true;
        state.message = '';
        state.error = null;
      })
      .addCase(updateCoachMarkStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action?.payload;
        state.error = null;
      })
      .addCase(updateCoachMarkStatus.rejected, (state, action) => {
        state.loading = false;
        state.message = '';
        state.error = action?.payload;
      });
  },
});

export default coachMarkSlice.reducer;
