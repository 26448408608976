import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { javaApi } from '../../utils/AxiosInstance';

const initialState = {
  onGoingClasses: {
    loading: false,
    items: [],
    error: null,
  },
  upComingClasses: {
    loading: false,
    items: [],
    error: null,
  },
};

export const fetchOnGoingClasses = createAsyncThunk(
  'freeLiveClasses/fetchOnGoingClasses',
  async (payload, thunkAPI) => {
    try {
      const response = await javaApi.get(
        '/app-content-ws/api/v1/schedule/getFreeLiveClasses',
        {
          params: {
            requestType: 1,
            pageSize: 10,
          },
        },
      );

      if (!response?.data?.success) return thunkAPI.rejectWithValue('No Data!');

      return thunkAPI.fulfillWithValue(response.data.data);
    } catch (e) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const fetchUpcomingClasses = createAsyncThunk(
  'freeLiveClasses/fetchUpcomingClasses',
  async ({ pageNumber = 1 }, thunkAPI) => {
    try {
      const response = await javaApi.get(
        '/app-content-ws/api/v1/schedule/getFreeLiveClasses',
        {
          params: {
            requestType: 2,
            pageSize: 10,
            pageNumber,
          },
        },
      );

      if (!response?.data?.success) return thunkAPI.rejectWithValue('No Data!');

      return thunkAPI.fulfillWithValue(response.data.data);
    } catch (e) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const subscribeToClassNotification = createAsyncThunk(
  'free/',
  async ({ courseId, subscribe }, thunkAPI) => {
    try {
      // eslint-disable-next-line no-shadow
      const response = await javaApi.post(
        '/liveclass-ws/v1/live-class/notification/save/status',
        {
          courseId,
          isActive: subscribe,
        },
      );

      if (!response?.data?.success) {
        return thunkAPI.rejectWithValue(`Unable to ${subscribe ? 'Subscribe' : 'UnSubscribe'} to Notifications!`);
      }

      return thunkAPI.fulfillWithValue(`${subscribe ? 'Subscribed' : 'UnSubscribed'} to Notifications!`);
    } catch (e) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

const freeLiveClassesSlice = createSlice({
  name: 'freeLiveClasses',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnGoingClasses.pending, (state) => {
        state.onGoingClasses = {
          ...state.onGoingClasses,
          loading: true,
        };
      })
      .addCase(fetchOnGoingClasses.fulfilled, (state, { payload }) => {
        state.onGoingClasses = {
          ...state.onGoingClasses,
          items: payload,
        };
      })
      .addCase(fetchOnGoingClasses.rejected, (state, { payload }) => {
        state.onGoingClasses = {
          ...state.onGoingClasses,
          error: payload,
        };
      })
      .addCase(fetchUpcomingClasses.pending, (state) => {
        state.upComingClasses = {
          ...state.upComingClasses,
          loading: true,
        };
      })
      .addCase(fetchUpcomingClasses.fulfilled, (state, { payload, meta }) => {
        const items = meta.arg.pageNumber === 1 ? [] : state.upComingClasses.items;

        state.upComingClasses = {
          ...state.upComingClasses,
          items: [...items, ...payload],
        };
      })
      .addCase(fetchUpcomingClasses.rejected, (state, { payload }) => {
        state.upComingClasses = {
          ...state.upComingClasses,
          error: payload,
        };
      });
  },
});

export default freeLiveClassesSlice.reducer;
