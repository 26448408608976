/* eslint-disable prefer-destructuring, camelcase */
import { Cookie } from 'next-cookie';

export function LanguageMapping(languageID) {
  switch (languageID) {
    case 1:
      return 'en';
    case 2:
      return 'hn'; // en ?
    case 3:
      return 'od';
    case 4:
      return 'mh';
    case 5:
      return 'kan'; // kn ?
    case 6:
      return 'gj';
    case 7:
      return 'tm';
    case 8:
      return 'hi';
    case 9:
      return 'en'; // ml->en
    case 10:
      return 'bg';
    default:
      return null;
  }
}
export const getCategoryType = (categoryId) => {
  if (categoryId === 1) {
    return 'Smart';
  } if (categoryId === 2) {
    return 'Micro';
  } if (categoryId === 3) {
    return 'Quiz Book';
  } if (categoryId === 4) {
    return 'eBooks';
  } if (categoryId === 5) {
    return 'Books';
  } if (categoryId === 6) {
    return 'Testseries';
  } if (categoryId === 7) {
    return 'Live Classes';
  }
  return 'Course category';
};
export function languageMappingToInt(languageID) {
  switch (languageID) {
    case 'en':
      return 1;
    case 'hn':
      return 2; // en ?
    case 'od':
      return 3;
    case 'mh':
      return 4;
    case 'kan':
      return 5; // kn ?
    case 'gj':
      return 6;
    case 'tm':
      return 7;
    case 'hi':
      return 8;
    case 'bg':
      return 10;
    default:
      return null;
  }
}

export const serviceList = [
  {
    buisenessName: 'StudyIQMentorService',
    label: 'MentorShip',
    contentType: 'Mentor',
  },
  {
    buisenessName: 'StudyIQContentService',
    label: 'Content',
    contentType: 'Content',
  },
  {
    buisenessName: 'StudyIQTestSeries',
    label: 'Test Series',
    contentType: 'Test Series',
  },
  {
    buisenessName: 'StudyIQAssessmentService',
    label: 'Answer Writing',
    contentType: 'Assessment',
  },
];
export const setMediaDataFunction = (mediaData) => {
  const fileObj = {
    file: '',
    type: '',
    mediaUrls: [],
    optimisedUrl: '',
  };
  fileObj.file = mediaData?.fileName;
  fileObj.type = mediaData?.mediaType;
  fileObj.mediaUrls = mediaData?.pdfImageMediaIds;
  fileObj.optimisedUrl = mediaData?.optimizedMediaUrl;
  return fileObj;
};

export const getModifiedInstruction = (htmlContent) => {
  let modifiedContent = '';

  const replacement = '<span>Instructions: </span>';

  // Find the first opening tag in the HTML content
  const match = htmlContent?.match(/<(\w+[^>]*?)>/);

  if (match) {
    // Extract the tag name and its position
    const tagName = match[0];
    const startPos = match.index + tagName.length;

    // Construct the modified HTML content
    modifiedContent = htmlContent.slice(0, startPos) + replacement + htmlContent.slice(startPos);

    return modifiedContent;
  }
  modifiedContent = `<p><span>Instructions:</span>${htmlContent}<p/>`;

  return modifiedContent;
};

export const getModifiedQuestion = (htmlContent, questionString) => {
  let modifiedContent = '';
  const replacement = '<span>Question: </span>';

  // Find the first opening tag in the HTML content
  const match = htmlContent?.match(/<(\w+[^>]*?)>/);

  if (match) {
    // Extract the tag name and its position
    const tagName = match[0];
    const startPos = match.index + tagName.length;

    // Construct the modified HTML content
    modifiedContent = htmlContent.slice(0, startPos) + replacement + htmlContent.slice(startPos);

    return modifiedContent;
  }
  modifiedContent = `<p><span>Question:</span>${questionString}<p/>`;

  return modifiedContent;
};

export const removeParam = (key) => {
  if ((new RegExp(`(\\?|\\&)?${key}=`, 'i')).test(window.location.href) === false) return;

  const sourceURL = window.location.href;
  let rtn = sourceURL.split('?')[0];
  let param;
  let params_arr = [];
  const queryString = (sourceURL.indexOf('?') !== -1) ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
    params_arr = queryString.split('&');
    for (let i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split('=')[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    if (params_arr.length) rtn = `${rtn}?${params_arr.join('&')}`;

    if (window.history.replaceState) {
      window.history.replaceState(null, '', rtn);
    }
  }
};
export const getUserTokens = (isCrm) => {
  const cookies = new Cookie();
  const tokenData = {};

  const queryArr = window.location.search?.slice(1)?.replace(/&/g, '=')?.split('=');
  if ((queryArr.length > 0 && queryArr.includes('order_initiate_id') && queryArr.includes('key')) || isCrm === 'true') {
    tokenData.name = cookies.get('CRM_name');
    tokenData.email = cookies.get('CRM_email');
    tokenData.userID = cookies.get('CRM_userID');
    tokenData.apiToken = cookies.get('CRM_apiToken');
    tokenData.deviceID = cookies.get('CRM_deviceID');
    tokenData.isCRM = 'true';
  } else {
    tokenData.name = cookies.get('name');
    tokenData.email = cookies.get('email');
    tokenData.userID = cookies.get('userID');
    tokenData.apiToken = cookies.get('apiToken');
    tokenData.deviceID = cookies.get('deviceID');
    tokenData.isCRM = 'false';
  }

  const userId = cookies.get('authUserId');
  const authToken = cookies.get('authToken');
  const authUserDeviceId = cookies.get('authUserDeviceId');
  if (userId && authToken && authUserDeviceId) {
    tokenData.userID = cookies.get('userID');
    tokenData.apiToken = cookies.get('apiToken');
    tokenData.deviceID = cookies.get('authUserDeviceId');
  }

  return tokenData;
};

export const changeCouponCrm = ({ crmData, coupon_code }) => {
  const cloneCrmData = JSON.parse(JSON.stringify(crmData));
  if (cloneCrmData?.orderData) {
    cloneCrmData.orderData.course_detail.coupon_code = coupon_code;
  }
  return cloneCrmData;
};

export const supportNumber = (courseId) => {
  let number = {
    dash: '076-4000-3000',
    num: '7640003000',
  };

  switch (String(courseId)) {
    case '3671':
    case '3672':
    case '3673':
    case '3674':
    case '3675':
    case '3676':
    case '3677':
    case '3678':
      number = {
        dash: '076-7676-4682',
        num: '7676764682',
      };
      break;
    default:
      number = {
        dash: '076-4000-3000',
        num: '7640003000',
      };
  }

  return number;
};
