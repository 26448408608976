/* eslint-disable import/prefer-default-export */
import { javaApi } from './AxiosInstance';

export const getOfflineCentreData = async (courseId, authToken) => {
  try {
    const response = await javaApi.get(
      `app-content-ws/v1/offline-courses/course/offlineCentres?courseId=${courseId}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    if (response?.data?.success) {
      return response?.data?.data;
    }
    return null;
  } catch (error) {
    console.log('error:', error);
    return error?.response;
  }
};
