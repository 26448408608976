import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { javaApi } from '../../utils/AxiosInstance';

const initialState = {
  loading: false,
  getBookMark: null,
  error: null,
  message: null,
};

export const getBookMarkStatus = createAsyncThunk(
  'bookmark_status',
  async ({ courseId, languageId, contentId }, thunkAPI) => {
    try {
      const response = await javaApi.get('/app-content-ws/v1/api/bookmark/coursecontent/status?src=web', {
        params: {
          contentId,
          courseId,
          languageId,
        },
      });

      if (response?.data?.success) {
        return response?.data?.data?.isBookmarked;
      }
      return thunkAPI.rejectWithValue(response?.msg);
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const updateBookMarkStatus = createAsyncThunk(
  'bookmark_status_update',
  async ({ courseId, languageId, contentId, isActive }, thunkAPI) => {
    try {
      const response = await javaApi.post('app-content-ws/v1/api/bookmark/add/coursecontent?src=web', {
        courseId,
        languageId,
        contentId,
        isActive,
        timestamp: Math.floor(Date.now() / 1000),
      });

      if (response?.data?.success) {
        await thunkAPI.dispatch(
          getBookMarkStatus({
            courseId,
            languageId,
            contentId,
          }),
        );
        return response?.data?.message;
      }
      return thunkAPI.rejectWithValue(response?.data?.message);
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

const bookMarkSlice = createSlice({
  name: 'bookmark',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getBookMarkStatus.pending, (state) => {
        state.loading = true;
        state.bookMarkData = '';
        state.error = null;
      })
      .addCase(getBookMarkStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.bookMarkData = action?.payload;
        state.error = null;
      })
      .addCase(getBookMarkStatus.rejected, (state, action) => {
        state.loading = false;
        state.bookMarkData = '';
        state.error = action?.payload;
      })
      .addCase(updateBookMarkStatus.pending, (state) => {
        state.loading = true;
        state.message = '';
        state.error = null;
      })
      .addCase(updateBookMarkStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action?.payload;
        state.error = null;
      })
      .addCase(updateBookMarkStatus.rejected, (state, action) => {
        state.loading = false;
        state.message = '';
        state.error = action?.payload;
      });
  },
});

export default bookMarkSlice.reducer;
