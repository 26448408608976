import Axios from 'axios';
import FormData from 'form-data';
import { Cookie } from 'next-cookie';

import {
  JAVA_ANSWER_WRITING_API_BASE_URL,
  JAVA_ANSWER_WRITING_ASSESSMENT_API_BASE_URL,
  JAVA_API_BASE_URL, JAVA_API_TOKEN,
  JAVA_QUESTION_SERVICE_API_BASE_URL,
  LANGUAGE_SERVICE_BASE_URL, LCS_SERVICE_BASE_URL,
  MEDIA_UPLOADER_API_DOMAIN, NOTIFICATION_SERVICE_BASE_URL,
  PHP_API_BASE_URL, USER_AUTH_SERVICE_BASE_URL,
  TIMEOUT_DURATION, JAVA_STORE_API_BASE_URL,
} from '../constants/api';
import { getUserTokens } from './AnswerWritingUtil';

export const getAuthState = (context) => {
  let cookies = new Cookie();

  if (context) {
    cookies = new Cookie(context);
  }

  const userID = cookies.get('userID') ?? null;
  const authToken = cookies.get('apiToken') ?? null;

  return { userID, authToken };
};

export const phpApi = Axios.create({
  baseURL: PHP_API_BASE_URL,
});

phpApi.defaults.timeout = TIMEOUT_DURATION;

// Api Request Interceptor
phpApi.interceptors.request.use((config) => {
  const { userID, authToken } = getAuthState();

  // add auth fields if exists in cookies
  if (typeof window !== 'undefined' && userID && authToken) {
    if (config.data instanceof FormData) {
      config.data.append('user_id', userID);
      config.data.append('api_token', authToken);
    } else if (typeof config.data === 'object') {
      config.data.user_id = userID;
      config.data.api_token = authToken;
    } else if (typeof config.data === 'undefined') {
      config.data = new FormData();
      config.data.append('user_id', userID);
      config.data.append('api_token', authToken);

      config.headers['Content-Type'] = 'multipart/form-data';
    }
  }

  return config;
});

// Api Response Interceptor
phpApi.interceptors.response.use((response) => {
  // If server response unauthorized
  if (response.status === 401) {
    global.store.dispatch({ type: 'auth/showLogin' });
  }

  return response;
});

export const javaApi = Axios.create({
  baseURL: JAVA_API_BASE_URL,
});

javaApi.defaults.timeout = TIMEOUT_DURATION;

// Api Request Interceptor
javaApi.interceptors.request.use(async (config) => {
  let { authToken: apiToken } = getAuthState();

  const cookies = new Cookie();
  const isCRM = cookies.get('isCRM');
  if (isCRM === 'true') {
    apiToken = cookies.get('CRM_apiToken');
  }
  // add auth fields if exists in cookies
  config.headers.Platform = 'WEB';
  config.headers.token = JAVA_API_TOKEN;

  if (apiToken) {
    config.headers.Authorization = `Bearer ${apiToken}`;
  }

  return config;
});

// Api Response Interceptor
javaApi.interceptors.response.use(
  (response) => response,
  (error) => {
    // If server response unauthorized
    if (error?.response?.status === 401) {
      global.store.dispatch({ type: 'auth/showLogin' });
    }

    return Promise.reject(error);
  },
);

export const javaAnswerWritingAPI = Axios.create({
  baseURL: JAVA_ANSWER_WRITING_API_BASE_URL,
});

javaAnswerWritingAPI.defaults.timeout = TIMEOUT_DURATION;

// Api Request Interceptor
javaAnswerWritingAPI.interceptors.request.use((config) => {
  config.headers.token = JAVA_API_TOKEN;

  return config;
});

// Api Response Interceptor
javaAnswerWritingAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    // If server response unauthorized
    if (error.response.status === 401) {
      global.store.dispatch({ type: 'auth/showLogin' });
    }

    return Promise.reject(error);
  },
);

export const javaAssessmentEvaluationAPI = Axios.create({
  baseURL: JAVA_ANSWER_WRITING_ASSESSMENT_API_BASE_URL,
});

javaAssessmentEvaluationAPI.defaults.timeout = TIMEOUT_DURATION;

// Api Request Interceptor
javaAssessmentEvaluationAPI.interceptors.request.use(async (config) => {
  const { authToken } = getAuthState();
  // add auth fields if exists in cookies

  config.headers.token = JAVA_API_TOKEN;
  config.headers.Authorization = `Bearer ${authToken}`;

  return config;
});

// Api Response Interceptor
javaAssessmentEvaluationAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    // If server response unauthorized
    if (error.response.status === 401) {
      global.store.dispatch({ type: 'auth/showLogin' });
    }

    return Promise.reject(error);
  },
);

export const mediaUploaderAPI = Axios.create({
  baseURL: MEDIA_UPLOADER_API_DOMAIN,
});

mediaUploaderAPI.defaults.timeout = 60000;

// Api Request Interceptor
mediaUploaderAPI.interceptors.request.use(async (config) => {
  const { authToken } = await getAuthState();
  // add auth fields if exists in cookies

  config.headers['jwt-token'] = authToken;
  config.headers['service-name'] = 'StudyIq-LCSService';

  return config;
});

// Api Response Interceptor
mediaUploaderAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    // If server response unauthorized
    if (error.response.status === 401) {
      global.store.dispatch({ type: 'auth/showLogin' });
    }

    return Promise.reject(error);
  },
);

export const javaQuestionServiceAPI = Axios.create({
  baseURL: JAVA_QUESTION_SERVICE_API_BASE_URL,
});

javaQuestionServiceAPI.defaults.timeout = TIMEOUT_DURATION;

// Api Request Interceptor
javaQuestionServiceAPI.interceptors.request.use(async (config) => {
  const { authToken } = await getAuthState();
  // add auth fields if exists in cookies

  config.headers.token = JAVA_API_TOKEN;
  config.headers.Authorization = `Bearer ${authToken}`;

  return config;
});

// Api Response Interceptor
javaQuestionServiceAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    // If server response unauthorized
    if (error.response.status === 401) {
      global.store.dispatch({ type: 'auth/showLogin' });
    }

    return Promise.reject(error);
  },
);

export const lcsServiceAPI = Axios.create({
  baseURL: LCS_SERVICE_BASE_URL,
});

lcsServiceAPI.defaults.timeout = TIMEOUT_DURATION;

// Api Request Interceptor
lcsServiceAPI.interceptors.request.use(async (config) => {
  const { authToken } = await getAuthState();
  // add auth fields if exists in cookies

  config.headers['jwt-token'] = authToken;
  if (config.headers['business-name'] !== 'StudyIQNCERT') {
    config.headers['business-name'] = 'StudyIQFeedService';
    config.headers['client-name'] = 'admin-panel';
  }

  if (config?.buisenessName) {
    config.headers['business-name'] = config?.buisenessName;
  }

  return config;
});

// Api Response Interceptor
lcsServiceAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    // If server response unauthorized
    if (error.response.status === 401) {
      global.store.dispatch({ type: 'auth/showLogin' });
    }

    return Promise.reject(error);
  },
);

export const languageAiService = Axios.create({
  baseURL: LANGUAGE_SERVICE_BASE_URL,
});

languageAiService.defaults.timeout = TIMEOUT_DURATION;

// Api Request Interceptor
languageAiService.interceptors.request.use(async (config) => {
  const { authToken } = await getAuthState();
  // add auth fields if exists in cookies

  config.headers['business-name'] = 'StudyIq';
  config.headers['jwt-token'] = authToken;

  return config;
});

// Api Response Interceptor
languageAiService.interceptors.response.use(
  (response) => response,
  (error) => {
    // If server response unauthorized
    if (error.response.status === 401) {
      global.store.dispatch({ type: 'auth/showLogin' });
    }

    return Promise.reject(error);
  },
);

export const notificationServiceApi = Axios.create({
  baseURL: NOTIFICATION_SERVICE_BASE_URL,
});

notificationServiceApi.defaults.timeout = TIMEOUT_DURATION;

notificationServiceApi.interceptors.request.use(async (config) => {
  const { authToken } = await getAuthState();
  // add auth fields if exists in cookies

  config.headers['jwt-token'] = authToken;
  config.headers['business-name'] = 'StudyIq';

  return config;
});

export const authServiceApi = Axios.create({
  baseURL: USER_AUTH_SERVICE_BASE_URL,
});

authServiceApi.defaults.timeout = TIMEOUT_DURATION;

authServiceApi.interceptors.request.use(async (config) => {
  const { authToken } = await getAuthState();
  // add auth fields if exists in cookies

  if (authToken) {
    config.headers['jwt-token'] = `Bearer ${authToken}`;
  }

  config.headers['business-name'] = 'StudyIq';

  return config;
});

export const javaStoreApi = Axios.create({
  baseURL: JAVA_STORE_API_BASE_URL,
});

javaStoreApi.interceptors.request.use(async (config) => {
  const { apiToken } = getUserTokens();
  // add auth fields if exists in cookies

  config.headers.Platform = 'WEB';
  config.headers.token = JAVA_API_TOKEN;
  if (config?.extraHeaders) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(config?.extraHeaders)) {
      config.headers[key] = value;
    }
  }
  if (apiToken) {
    config.headers.Authorization = `Bearer ${apiToken}`;
  }

  return config;
});
