import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { languageAiService, lcsServiceAPI } from '../../utils/AxiosInstance';

const initialState = {
  loading: false,
  allBook: {
    details: [],
    error: null,
    loading: false,
  },
  summary: {
    details: null,
    error: null,
    loading: false,
  },
  submitFeedBack: {
    loading: false,
    data: null,
    error: false,
  },
};

export const fetchAllBook = createAsyncThunk('studyiqai/fetchAllBook', async (thunkAPI) => {
  try {
    const response = await languageAiService.get('ncert/book/all');
    if (response?.data?.data) return response?.data?.data;

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const fetchSummary = createAsyncThunk('studyiqai/fetchSummary', async ({ index, body }, thunkAPI) => {
  try {
    if (body === null) return { index, data: null };
    const response = await languageAiService.post('ncert/summary/fetch', body);
    if (response?.data?.data) {
      return { index, data: response.data.data };
    }
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const submitFeedback = createAsyncThunk('studyiqai/submitFeedback', async (payload, thunkAPI) => {
  try {
    const response = await lcsServiceAPI.post('review/create', payload, {
      headers: {
        'business-name': 'StudyIQNCERT',
      },
    });
    if (response?.status) {
      return response?.data?.response?.Message;
    }
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

const studyiqai = createSlice({
  name: 'studyiqai',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBook.pending, (state) => {
        state.allBook = {
          ...initialState.allBook,
          loading: true,
        };
      })
      .addCase(fetchAllBook.fulfilled, (state, action) => {
        state.allBook = {
          ...initialState.allBook,
          details: action.payload ?? [],
        };
      })
      .addCase(fetchAllBook.rejected, (state, action) => {
        state.allBook = {
          ...initialState.allBook,
          error: action.payload || null,
          details: null,
        };
      })
      .addCase(fetchSummary.pending, (state) => {
        state.summary = {
          ...initialState.summary,
          loading: true,
          details: {
            ...state.summary.details,
          },
        };
      })
      .addCase(fetchSummary.fulfilled, (state, action) => {
        const { index, data } = action.payload;
        state.summary = {
          ...initialState.summary,
          details: {
            ...state.summary.details,
            [index]: data || null,
          },
        };
      })
      .addCase(fetchSummary.rejected, (state, action) => {
        state.summary = {
          ...initialState.summary,
          error: action.payload || null,
          details: null,
        };
      })
      .addCase(submitFeedback.pending, (state) => {
        state.submitFeedBack = {
          ...initialState.submitFeedBack,
          loading: true,
        };
      })
      .addCase(submitFeedback.fulfilled, (state, action) => {
        state.submitFeedBack = {
          ...initialState.submitFeedBack,
          data: action.payload || null,
        };
      })
      .addCase(submitFeedback.rejected, (state, action) => {
        state.submitFeedBack = {
          ...initialState.submitFeedBack,
          error: action.payload || null,
          data: null,
        };
      });
  },
});

export default studyiqai.reducer;
