import { Cookie } from 'next-cookie';
import FormData from 'form-data';

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

// eslint-disable-next-line import/no-cycle
import { fetchExamPreferenceList } from '../accountInfoSlice/actions';

import { authServiceApi, javaApi, phpApi } from '../../../utils/AxiosInstance';
import { validateEmailAddress } from '../../../utils/helper';

// Auth Actions
export const showLogin = createAction('auth/showLogin', (authView = 'AUTHENTICATION') => ({
  payload: authView,
}));

export const setAuthView = createAction('auth/setAuthView', (authView) => ({
  payload: authView,
}));

export const hideLogin = createAction('auth/hideLogin');
export const updateProfile = createAction('auth/updateProfile');

export const setAuthUserAndToken = createAction('auth/setAuthUserAndToken', (userID, authToken) => ({
  payload: { userID, authToken },
}));

export const authUserProfile = createAsyncThunk('auth/authUserProfile', async (payload, thunkAPI) => {
  const cookies = new Cookie();

  let authApiToken = payload?.apiToken || cookies.get('apiToken');

  const { authToken } = thunkAPI.getState().auth;
  if (authToken) {
    authApiToken = authToken;
  }

  try {
    const response = await javaApi.get(
      'app-content-ws/api/web/user/profile',
      { headers: { Authorization: `Bearer ${authApiToken}` } },
    );

    if (!response?.data?.success) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    }

    const responsePayload = {
      profile: response?.data?.data,
      purchaseHistory: response?.data?.purchaseHistory,
    };

    try {
      const authResponse = await authServiceApi.post(
        'auth/validate-any-token',
        { tokenToValidate: authApiToken },
        {
          headers: {
            'jwt-token': `Bearer ${authApiToken}`,
          },
        },
      );

      if (authResponse.data.httpStatusCode === 200) {
        responsePayload.authUserId = authResponse.data.data.userId;
      }
    } catch (e) {
      // Do Nothing
    }

    return thunkAPI.fulfillWithValue(responsePayload);
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const validateAppAuth = createAsyncThunk(
  'auth/validateAppAuth',
  // eslint-disable-next-line default-param-last
  async ({ userID, apiToken, forceLogin = true } = {}, thunkAPI) => {
    try {
      const cookies = new Cookie();

      const authUserID = userID || cookies.get('userID') || null;
      const authApiToken = apiToken || cookies.get('apiToken') || null;

      // Don't go forward if no userID or apiToken passed
      if (!authUserID || !authApiToken) {
        // eslint-disable-next-line no-unused-expressions
        forceLogin && thunkAPI.dispatch(showLogin());

        return thunkAPI.rejectWithValue('Auth Failed - No Creds!');
      }

      const formData = new FormData();
      formData.append('user_id', authUserID);
      formData.append('api_token', authApiToken);

      const response = await javaApi.post(
        'user-auth-ws/v1/auth/verify/userToken',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authApiToken}`,
          },
        },
      );

      if (response?.data?.success) {
        await thunkAPI.dispatch(authUserProfile({ userID: authUserID, apiToken: authApiToken }));
        return response?.data?.data;
      }

      // Show login modal on force
      if (forceLogin) thunkAPI.dispatch(showLogin());

      return thunkAPI.rejectWithValue('Auth Failed!');
    } catch (error) {
      return thunkAPI.rejectWithValue('API Error!');
    }
  },
);

export const sendUserLoginOtp = createAsyncThunk('auth/sendUserLoginOtp', async (formData, thunkAPI) => {
  const phoneNumber = formData.get('mobile');
  const cookies = new Cookie();
  if (!/^([0-9]{10})$/.test(phoneNumber)) {
    return thunkAPI.rejectWithValue({
      field: 'mobile',
      message: 'Invalid Phone Number!',
    });
  }

  try {
    const response = await phpApi.post('userlogin', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response?.data?.success) {
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 365 * 24 * 60 * 60 * 1000);
      cookies.set('mobileNumber', phoneNumber, {
        expires: expirationDate,
        path: '/',
      });
      return { ...response?.data?.data, phoneNumber };
    }

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const sendUserLoginOtpOnEmail = createAsyncThunk('auth/sendUserLoginOtpOnEmail', async (payload, thunkAPI) => {
  const state = thunkAPI.getState()?.auth;
  const cookies = new Cookie();

  const formData = new FormData();
  formData.append('action', 3);

  try {
    formData.append('mobile', state?.userPhoneNumber);

    const response = await phpApi.post('userlogin', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response?.data?.success) {
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 365 * 24 * 60 * 60 * 1000);
      cookies.set('mobileNumber', state?.userPhoneNumber, {
        expires: expirationDate,
        path: '/',
      });
      return response?.data?.data;
    }

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const getAboutStats = createAsyncThunk('auth/getAboutStats', async (payload, thunkAPI) => {
  try {
    const response = await javaApi.get('user-analytics-ws/v1/consumption/aboutStats');
    if (response?.data?.success) {
      return response?.data?.data;
    }

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const getLeaderBoardData = createAsyncThunk('auth/getLeaderBoardData', async (payload, thunkAPI) => {
  try {
    const response = await javaApi.get('user-analytics-ws/v1/consumption/leaderboard');
    if (response?.data?.success) {
      return response?.data?.data;
    }

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const getConsumedData = createAsyncThunk('auth/getConsumedData', async (payload, thunkAPI) => {
  try {
    const response = await javaApi.get('user-analytics-ws/v1/consumption/analysis');
    if (response?.data?.success) {
      return response?.data?.data;
    }

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const resendUserLoginOtp = createAsyncThunk('auth/resendUserLoginOtp', async (payload, thunkAPI) => {
  const state = thunkAPI.getState()?.auth;

  const phoneNumber = state?.userPhoneNumber;
  if (!/^([0-9]{10})$/.test(phoneNumber)) {
    return thunkAPI.rejectWithValue({
      field: 'mobile',
      message: 'Invalid Phone Number!',
    });
  }

  try {
    const formData = new FormData();
    formData.append('action', state?.authAction);
    formData.append('user_id', state?.userId);

    const response = await phpApi.post('resend_otp', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response?.data?.success) {
      return { ...response?.data?.data, phoneNumber };
    }
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const validateLoginOtpCrmOrder =
  // eslint-disable-next-line max-len
  createAsyncThunk('auth/validateLoginOtpCrmOrder', async ({ otp, userID, orderData, orderInitiateId, crmLink }, thunkAPI) => {
    try {
      const formData = new FormData();
      const cookies = new Cookie();
      formData.append('user_id', `${userID}`);
      formData.append('otp', `${otp}`);

      const res = await phpApi.post('web_user_login', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (res?.data?.success === 1) {
        const { data } = res.data;
        const userId = data.user_id;
        const apiToken = data.api_token;
        const deviceID = data.device_id;
        const name = data.first_name;
        const { email } = data;
        const originaUserId = cookies.get('userID');
        const expirationDate = new Date();
        expirationDate.setTime(
          expirationDate.getTime() + 365 * 24 * 60 * 60 * 1000,
        );
        if (parseInt(originaUserId, 10) === parseInt(userId, 10)) {
          cookies.set('apiToken', apiToken, {
            expires: expirationDate,
            path: '/',
          });
        }
        cookies.set('CRM_userID', userId, {
          expires: expirationDate,
          path: '/',
        });
        cookies.set('CRM_name', JSON.stringify(name), {
          expires: expirationDate,
          path: '/',
        });
        cookies.set('CRM_email', JSON.stringify(email), {
          expires: expirationDate,
          path: '/',
        });
        cookies.set('CRM_apiToken', apiToken, {
          expires: expirationDate,
          path: '/',
        });
        cookies.set('CRM_deviceID', deviceID, {
          expires: expirationDate,
          path: '/',
        });
        cookies.set('isCRM', 'true', { expires: expirationDate, path: '/' });
        cookies.set('crmLink', crmLink, { expires: expirationDate, path: '/' });
        return {
          data,
          orderData,
          orderInitiateId,
        };
      }
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: res?.data?.msg || 'API Error!',
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  });

export const validateLoginOtp = createAsyncThunk('auth/validateLoginOtp', async (formData, thunkAPI) => {
  const state = thunkAPI.getState()?.auth;

  const userId = state?.userId;
  if (!userId) {
    return thunkAPI.rejectWithValue({
      field: 'userId',
      message: 'Invalid User!',
    });
  }

  formData.append('user_id', userId);

  const loginOtp = formData.get('otp');

  if (loginOtp?.length === 0) {
    return thunkAPI.rejectWithValue({
      field: 'otp',
      message: 'Please Enter OTP',
    });
  }
  if (!/^([0-9]{6})$/.test(loginOtp)) {
    return thunkAPI.rejectWithValue({
      field: 'otp',
      message: 'Invalid OTP Provided',
    });
  }

  try {
    const response = await phpApi.post('web_user_login', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response?.data?.success) {
      thunkAPI.dispatch(
        authUserProfile({
          userID: response?.data?.data?.user_id,
          apiToken: response?.data?.data?.api_token,
        }),
      );
      return response?.data?.data;
    }

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const registerUser = createAsyncThunk('auth/registerUser', async (formData, thunkAPI) => {
  const state = thunkAPI.getState()?.auth;

  const userId = state?.userId;
  if (!userId) {
    return thunkAPI.rejectWithValue({
      field: 'userId',
      message: 'Invalid User!',
    });
  }

  formData.append('user_id', userId);

  // Validate OTP
  const loginOtp = formData.get('otp');

  if (loginOtp?.length === 0) {
    return thunkAPI.rejectWithValue({
      field: 'otp',
      message: 'Please Enter OTP',
    });
  }
  if (!/^([0-9]{6})$/.test(loginOtp)) {
    return thunkAPI.rejectWithValue({
      field: 'otp',
      message: 'Invalid OTP Provided',
    });
  }

  // Validate Your Name
  if (!formData.get('name')) {
    return thunkAPI.rejectWithValue({
      field: 'name',
      message: 'Your Name is required!',
    });
  }

  if (/^[a-z\s\\.]+$/i.test(formData.get('name')) === false) {
    return thunkAPI.rejectWithValue({
      field: 'name',
      message: 'Invalid Name.',
    });
  }

  // Validate Email Address
  const userEmail = formData.get('email');
  if (!userEmail) {
    return thunkAPI.rejectWithValue({
      field: 'email',
      message: 'Your Email is required!',
    });
  }

  if (!validateEmailAddress(userEmail)) {
    return thunkAPI.rejectWithValue({
      field: 'email',
      message: 'Invalid Email Address!',
    });
  }

  try {
    const response = await phpApi.post('web_user_reg', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response?.data?.success) {
      window.dataLayer.push({
        userID: response?.data?.data?.user_id,
      });
      return response?.data?.data;
    }

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const setUserExamPreference = createAsyncThunk('auth/setUserExamPreference', async (categoryId, thunkAPI) => {
  const cookies = new Cookie();

  const userID = cookies.get('userID');
  const apiToken = cookies.get('apiToken');
  if (!userID || !apiToken) {
    return thunkAPI.rejectWithValue({
      field: 'auth',
      message: 'User not Authorized.',
    });
  }

  try {
    const response = await javaApi.post('/app-content-ws/api/web/exam/preference', {
      categoryId,
    });

    if (response?.data?.success) {
      await thunkAPI.dispatch(fetchExamPreferenceList());
      return response?.data?.data || true;
    }

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const updateUserEmail = createAsyncThunk('auth/updateUserEmail', async (email, thunkAPI) => {
  try {
    if (!validateEmailAddress(email)) {
      return thunkAPI.rejectWithValue({
        field: 'email',
        message: 'Invalid Email Address!',
      });
    }

    const response = await javaApi.post('user-auth-ws/v1/auth/user/update', {
      email,
    });

    if (response?.data?.success) return response?.data?.data;

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const getSeoData = createAsyncThunk('auth/getSeoData', async (url, thunkAPI) => {
  try {
    const seoData = await javaApi.get(`/content-ws/v1/seo/detail/get?url=${url}`);
    if (seoData?.data?.status === 200) {
      return seoData?.data;
    }
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: seoData?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({
      field: 'server',
      message: 'API Error!',
    });
  }
});

export const resetAuthState = createAction('auth/resetState', (payload) => ({
  payload: payload || {},
}));

export const logoutUser = createAction('auth/logoutUser');
export const clearCRMData = createAction('auth/clearCRMData');
export const changeCrmCoupon = createAction('auth/changeCrmCoupon');
export const clearAuthError = createAction('auth/clearError');
export const clearUpdateUserEmailError = createAction('auth/clearUpdateUserEmailError');
