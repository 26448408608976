/* eslint-disable import/no-cycle, no-unsafe-optional-chaining, no-undef */
import { Cookie } from 'next-cookie';

import { createSlice } from '@reduxjs/toolkit';

import { trackMoengageEvent } from '../../../hooks/useMoengage';
import { removeParam } from '../../../utils/AnswerWritingUtil';

import * as authActions from './actions';

const initialState = {
  loading: false,
  showLogin: false,
  authView: 'AUTHENTICATION', // AUTHENTICATION | EXAM_PREF
  authAction: null,
  isAuthenticated: false,
  userId: null,
  authUserId: null,
  userPhoneNumber: null,
  authToken: null,
  authUser: null,
  examPreference: null,
  error: null,
  userPurchaseHistory: [],
  userEmailUpdate: {
    loading: false,
    isUpdated: null,
    error: null,
  },
  seo: {
    loading: false,
    data: null,
    error: null,
  },
  isProfileLoaded: false,
  isAuthState: null,
  getAboutStats: {
    loading: false,
    data: null,
    error: null,
  },
  leaderBoardData: {
    loading: false,
    data: null,
    error: null,
  },
  consumedData: {
    loading: false,
    data: null,
    error: null,
  },
};

// Auth Slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(authActions.clearCRMData, (state) => {
        const cookies = new Cookie();
        cookies.set('CRM_userID', '', { path: '/' });
        cookies.set('CRM_name', '', { path: '/' });
        cookies.set('CRM_email', '', { path: '/' });
        cookies.set('CRM_apiToken', '', { path: '/' });
        cookies.set('CRM_deviceID', '', { path: '/' });
        cookies.set('isCRM', 'false', { path: '/' });
        cookies.set('crmLink', '', { path: '/' });
        removeParam('order_initiate_id');
        removeParam('key');

        state.crmOrderInitiate = {};
      })
      .addCase(authActions.changeCrmCoupon, (state, action) => {
        state.crmOrderInitiate = action?.payload;
      })
      .addCase(authActions.setAuthUserAndToken, (state, action) => {
        state.userId = action.payload.userID;
        state.authToken = action.payload.authToken;
      })
      .addCase(authActions.setAuthView, (state, { payload }) => {
        state.showLogin = true;
        state.authView = payload;
      })
      .addCase(authActions.showLogin, (state, action) => {
        state.showLogin = true;
        state.authView = action.payload || initialState.authView;

        // Remove Cookies
        const cookies = new Cookie();

        cookies.remove('userID');
        cookies.remove('apiToken');
        cookies.remove('deviceID');
      })
      .addCase(authActions.hideLogin, (state) => {
        state.showLogin = false;
        state.authAction = null;
        state.authView = initialState.authView;
        state.isProfileLoaded = false;
        state.isAuthState = null;
      })
      .addCase(authActions.validateLoginOtpCrmOrder.pending, (state) => {
        state.crmOrderInitiate = {};
      })
      .addCase(authActions.validateLoginOtpCrmOrder.fulfilled, (state, action) => {
        state.crmOrderInitiate = action.payload;
        state.CRM_userAuthorized = true;
      })
      .addCase(authActions.validateLoginOtpCrmOrder.rejected, (state) => {
        state.crmOrderInitiate = {};
        state.CRM_userAuthorized = false;
      })
      .addCase(authActions.updateProfile, (state, action) => {
        state.authUser = {
          ...state.authUser,
          ...action.payload,
        };
      })
      .addCase(authActions.sendUserLoginOtp.pending, (state) => {
        state.loading = true;
        state.isAuthenticated = false;
        state.userId = null;
        state.authAction = null;
        state.userPhoneNumber = null;
        state.authToken = null;
        state.error = null;
        state.isProfileLoaded = false;
        state.isAuthState = null;
      })
      .addCase(authActions.sendUserLoginOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.userId = action?.payload?.user_id;
        state.userPhoneNumber = action?.payload?.phoneNumber;
        state.authAction = action?.payload?.action;
        state.error = null;
        state.isProfileLoaded = false;
        state.isAuthState = null;
      })
      .addCase(authActions.sendUserLoginOtp.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.userId = null;
        state.userPhoneNumber = null;
        state.authAction = null;
        state.error = action?.payload;
        state.isProfileLoaded = false;
        state.isAuthState = null;
      })
      .addCase(authActions.validateLoginOtp.pending, (state) => {
        state.loading = true;
        state.isAuthenticated = false;
        state.authUser = null;
        state.examPreference = null;
        state.error = null;
        state.isProfileLoaded = false;
        state.isAuthState = null;

        // Remove Cookies
        const cookies = new Cookie();

        cookies.remove('userID');
        cookies.remove('apiToken');
        cookies.remove('deviceID');
      })
      .addCase(authActions.validateLoginOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.authUser = action.payload;
        state.authToken = action.payload?.api_token || null;
        state.examPreference = action?.payload?.exam_preference;
        state.showLogin = action?.payload?.exam_preference !== 0;
        state.error = null;
        state.isProfileLoaded = false;
        state.isAuthState = 'login';

        if (action?.payload?.exam_preference) {
          state.authView = 'EXAM_PREF';
        }

        // Store Cookies
        const cookies = new Cookie();
        const expirationDate = new Date();
        expirationDate?.setTime(expirationDate?.getTime() + 365 * 24 * 60 * 60 * 1000);

        cookies.set('userID', action?.payload?.user_id, {
          expires: expirationDate,
          path: '/',
        });
        cookies.set('apiToken', action?.payload?.api_token, {
          expires: expirationDate,
          path: '/',
        });
        cookies.set('deviceID', action?.payload?.device_id, {
          expires: expirationDate,
          path: '/',
        });
        cookies.set('cp_user_name', `${action?.payload?.first_name} ${action?.payload?.last_name}`.trim(), {
          expires: expirationDate,
          path: '/',
        });
        cookies.set('cp_user_phone', action?.payload?.mobile_number, {
          expires: expirationDate,
          path: '/',
        });
      })
      .addCase(authActions.validateLoginOtp.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.authUser = null;
        state.authToken = null;
        state.examPreference = null;
        state.error = action.payload;
        state.isProfileLoaded = false;
        state.isAuthState = null;
      })
      .addCase(authActions.registerUser.pending, (state) => {
        state.loading = true;
        state.isAuthenticated = false;
        state.authUser = null;
        state.authToken = null;
        state.error = null;
        state.isProfileLoaded = false;
        state.isAuthState = null;

        // Remove Cookies
        const cookies = new Cookie();

        cookies.remove('userID');
        cookies.remove('apiToken');
        cookies.remove('deviceID');
      })
      .addCase(authActions.registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.authUser = action.payload;
        state.authView = 'EXAM_PREF';
        state.authToken = action?.payload?.api_token;
        state.userId = action?.payload?.user_id;
        state.error = null;
        state.isProfileLoaded = false;
        state.isAuthState = 'signup';

        // Store Cookies
        const cookies = new Cookie();
        setTimeout(() => {
          Adjust?.trackEvent({
            eventToken: '9gpgni',
            user_id: action.payload?.user_id?.toString(),
            callbackParams: [
              {
                key: 'user_id',
                value: action.payload ? action.payload?.user_id?.toString() : '',
              },
              { key: 'email_id', value: action.payload?.email || '' },
              {
                key: 'mobile_number',
                value: action.payload?.mobile_number || '',
              },
            ],
          });
        });
        trackMoengageEvent('signup', {
          signup_via: 'otp',
        });

        const expirationDate = new Date();
        expirationDate?.setTime(expirationDate?.getTime() + 365 * 24 * 60 * 60 * 1000);
        cookies.set('userID', action?.payload?.user_id, {
          expires: expirationDate,
          path: '/',
        });
        cookies.set('apiToken', action?.payload?.api_token, {
          expires: expirationDate,
          path: '/',
        });
        cookies.set('deviceID', action?.payload?.device_id, {
          expires: expirationDate,
          path: '/',
        });
        cookies.set('cp_user_name', `${action?.payload?.first_name} ${action?.payload?.last_name}`.trim(), {
          expires: expirationDate,
          path: '/',
        });
        cookies.set('cp_user_phone', action?.payload?.mobile_number, {
          expires: expirationDate,
          path: '/',
        });
      })
      .addCase(authActions.registerUser.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.authToken = null;
        state.authUser = null;
        state.error = action.payload;
        state.isProfileLoaded = false;
        state.isAuthState = null;
      })
      .addCase(authActions.validateAppAuth.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isProfileLoaded = false;
        state.isAuthState = null;
      })
      .addCase(authActions.validateAppAuth.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.userId = action.meta?.arg?.userID;
        state.authToken = action.meta?.arg?.apiToken;
        state.error = null;
        state.isProfileLoaded = false;
        state.isAuthState = null;
      })
      .addCase(authActions.validateAppAuth.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = action.payload;
        state.isAuthState = null;

        // Remove Cookies
        const cookies = new Cookie();

        cookies.remove('userID');
        cookies.remove('apiToken');
        cookies.remove('deviceID');
      })
      .addCase(authActions.authUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(authActions.authUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.userId = action.payload?.profile?.id;
        state.authUserId = action.payload?.authUserId ?? null;
        state.userPhoneNumber = action.payload?.profile?.mobileNumber;
        state.authUser = action.payload?.profile;
        state.userPurchaseHistory = action.payload?.purchaseHistory;
        state.error = null;
        state.isProfileLoaded = true;
      })
      .addCase(authActions.authUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.authUser = null;
        state.error = action.payload;
        state.isProfileLoaded = false;
      })
      .addCase(authActions.setUserExamPreference.fulfilled, (state, action) => {
        state.showLogin = false;
        state.authView = 'AUTHENTICATION';
        state.isProfileLoaded = false;
        state.examPreference = action.payload;
      })
      .addCase(authActions.logoutUser, (state) => {
        state.isAuthenticated = false;
        state.authAction = null;
        state.userId = null;
        state.userPhoneNumber = null;
        state.authUser = null;
        state.error = null;
        state.isProfileLoaded = false;

        // Remove Cookies
        const cookies = new Cookie();

        cookies.remove('userID');
        cookies.remove('apiToken');
        cookies.remove('deviceID');
        cookies.remove('mobileNumber');
      })
      .addCase(authActions.getConsumedData.pending, (state) => {
        state.consumedData = {
          ...state.consumedData,
          loading: true,
          data: null,
          error: null,
        };
      })
      .addCase(authActions.getConsumedData.fulfilled, (state, action) => {
        state.consumedData = {
          ...state.consumedData,
          loading: false,
          data: action.payload,
          error: null,
        };
      })
      .addCase(authActions.getConsumedData.rejected, (state, action) => {
        state.consumedData = {
          ...state.consumedData,
          loading: false,
          data: null,
          error: action.payload,
        };
      })
      .addCase(authActions.getAboutStats.pending, (state) => {
        state.getAboutStats = {
          ...state.getAboutStats,
          loading: true,
          data: null,
          error: null,
        };
      })
      .addCase(authActions.getAboutStats.fulfilled, (state, action) => {
        state.getAboutStats = {
          ...state.getAboutStats,
          loading: false,
          data: action.payload,
          error: null,
        };
      })
      .addCase(authActions.getAboutStats.rejected, (state, action) => {
        state.getAboutStats = {
          ...state.getAboutStats,
          loading: false,
          data: null,
          error: action.payload,
        };
      })
      .addCase(authActions.getLeaderBoardData.pending, (state) => {
        state.leaderBoardData = {
          ...state.leaderBoardData,
          loading: true,
          data: null,
          error: null,
        };
      })
      .addCase(authActions.getLeaderBoardData.fulfilled, (state, action) => {
        state.leaderBoardData = {
          ...state.leaderBoardData,
          loading: false,
          data: action.payload,
          error: null,
        };
      })
      .addCase(authActions.getLeaderBoardData.rejected, (state, action) => {
        state.leaderBoardData = {
          ...state.leaderBoardData,
          loading: false,
          data: null,
          error: action.payload,
        };
      })
      .addCase(authActions.updateUserEmail.pending, (state) => {
        state.userEmailUpdate = {
          ...state.userEmailUpdate,
          loading: true,
        };
      })
      .addCase(authActions.updateUserEmail.fulfilled, (state) => {
        state.userEmailUpdate = {
          ...state.userEmailUpdate,
          loading: false,
          isUpdated: true,
        };
      })
      .addCase(authActions.updateUserEmail.rejected, (state, action) => {
        state.userEmailUpdate = {
          ...state.userEmailUpdate,
          loading: false,
          error: action.payload,
        };
      })
      .addCase(authActions.clearUpdateUserEmailError, (state) => {
        state.userEmailUpdate = {
          ...state.userEmailUpdate,
          error: null,
        };
      })
      .addCase(authActions.clearAuthError, (state) => {
        state.error = null;
      })
      .addCase(authActions.resetAuthState, (state, action) => ({
        ...initialState,
        ...action.payload,
      }))
      .addCase(authActions.getSeoData.pending, (state) => {
        state.seo = {
          ...state.seo,
          loading: true,
        };
      })
      .addCase(authActions.getSeoData.fulfilled, (state, action) => {
        state.getAllComment = {
          ...state.seo,
          loading: false,
          data: action.payload,
        };
      })
      .addCase(authActions.getSeoData.rejected, (state, action) => {
        state.seo = {
          ...state.seo,
          loading: false,
          error: action.payload,
          data: null,
        };
      });
  },
});

export default authSlice.reducer;
