/* eslint-disable no-empty, no-unsafe-optional-chaining, consistent-return, max-len, indent */
import React, { useEffect } from 'react';

import { Cookie } from 'next-cookie';
import App from 'next/app';
import { Quicksand } from 'next/font/google';
import dynamic from 'next/dynamic';
import { Router, useRouter } from 'next/router';
import Script from 'next/script';
import NextNProgress from 'nextjs-progressbar';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import classNames from 'classnames';
import random from 'lodash.random';
import times from 'lodash.times';
import { useDispatch, useSelector } from 'react-redux';

import {
  VideoDashboardTour,
  courseDashboardTourType1,
  courseDashboardTourType2,
  courseDashboardTourType3,
  courseDashboardTourType4,
  myBookMarksTour,
  myLibraryContinueSection,
  myLibraryLiveClassTour,
  myLibraryYourPurchaseTour,
} from '../hooks/TourSteps';
import { trackMoengageEvent } from '../hooks/useMoengage';

// Redux
import wrapper from '../redux';
import { getSeoData, validateAppAuth } from '../redux/slices/authSlice/actions';
import { getCoachMarkStatus } from '../redux/slices/coachMarkSlice';
import { getUpscSectionDetails } from '../redux/slices/homeSlice';
import { fetchUserLibrary } from '../redux/slices/librarySlice';

// eslint-disable-next-line import/no-unresolved
import { DE_API_DOMAIN, FRONTEND_DOMAIN, GROWTHBOOK_CLIENT_KEY } from '../constants/api';
import { getAuthState, javaApi } from '../utils/AxiosInstance';
import { getGrowthBookDeviceType } from '../utils/helper';

import MiniPlayer from '../components/MiniPlayer';
import MiniPlayerContext from '../contexts/MiniPlayerContext';

import '../styles/index.scss';

const CourseDashboardTopBar = dynamic(() => import('../components/CourseDashboard/CourseItem/CourseDashboardTopBar'));
const NoInternetModal = dynamic(() => import('../components/CourseDashboard/NoInternetModal/NoInternetModal'));
const WatchListToggleComponent = dynamic(() => import('../components/MyLibrary/WatchListToggleComponent'));
const OGMetaTags = dynamic(() => import('../components/OGMetaTags'));
const Toaster = dynamic(() => import('../components/UI/Toaster'));
const AndroidModal = dynamic(() => import('../components/UserLibrary/WatchList/AndroidModal'));
const BuyNowContainer = dynamic(() => import('../container/BuyNow'));

// Components
const NavBarCourseDashboard = dynamic(() => import('../container/CourseDashboard/NavBarCourseDashboard'));
const HeaderComponent = dynamic(() => import('../components/Header/Header'));
const FooterComponent = dynamic(() => import('../components/Footer/Footer'));
const AuthComponent = dynamic(() => import('../components/Auth'), {
  ssr: false,
});

const Tour = dynamic(() => import('../hooks/Tour'), { ssr: false });

const quicksand = Quicksand({ subsets: ['latin'] });

const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.NODE_ENV !== 'production',
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    window.dataLayer.push({
      event: 'experiment_viewed',
      experimentId: experiment.key,
      variationId: result.key,
    });

    // MoEngage SDK tracking
    trackMoengageEvent('GROWTHBOOK_EXPERIMENT_VIEWED', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});
growthbook.init({ streaming: true });

function MyAppComponent({ header, authCookie, haveFixedWidth = false, footer, withoutLayout = false, children }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const [isOnline, setIsOnline] = React.useState(true);
  const [isModal, setIsModal] = React.useState(false);
  const [{ playerRef, ...playerData }, setPlayerData] = React.useState({});

  const {
    courses,
    continueWatch: { list: continueWatchList },
  } = useSelector((state) => state.library);

  const resourceType = useSelector((state) => state.resourceDashboard.resource?.detail?.resourceType);
  const isStoreEnabled = useSelector((state) => state.home.getUPSCDetails?.store_enabled);
  const getCoachMarkData = useSelector((state) => state.coachMark.coachMarkData);
  const courseDetails = useSelector((state) => state.courseConsumption.courseDetails.details);

  const courseTypeId = courseDetails.courseTemplateTypeId;

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isCourseContent = router?.asPath?.includes('/course-content/') && !router?.asPath?.includes('/answer-writing/');

  const [isStoreEnableFlag, setIsStoreEnableFlag] = React.useState(false);

  const { course } = router.query;
  const [courseId] = (course || '').split('-');
  const [courseDashboardTour, setCourseDashboardTour] = React.useState();

  React.useEffect(() => {
    const cookie = new Cookie();

    let expUserId = cookie.get('exp-user-id');
    if (!expUserId) {
      expUserId = times(20, () => random(35).toString(36)).join('');
      cookie.set('exp-user-id', expUserId, { path: '', maxAge: 31557600 });
    }

    growthbook.setAttributes({
      id: expUserId,
      url: router.asPath,
      userAgent: typeof window !== 'undefined' ? window?.navigator?.userAgent : null,
      deviceType: getGrowthBookDeviceType(),
    });
  }, [router]);

  React.useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);

  React.useEffect(() => {
    if (!isOnline) {
      setIsModal(true);
    } else {
      setIsModal(false);
    }
  }, [isOnline]);

  React.useEffect(() => {
    dispatch(getUpscSectionDetails());
  }, [dispatch]);

  React.useEffect(() => {
    if (isStoreEnabled) {
      setIsStoreEnableFlag(isStoreEnabled);
    }
  }, [isStoreEnabled, isStoreEnableFlag]);

  const getSeoDetails = async () => {
    try {
      const seoData = await dispatch(getSeoData(router?.asPath));
      if (seoData?.payload?.status === 200) {
        const response = seoData?.payload?.data?.response;
        if (response?.redirectUrl) {
          // router.push(response?.redirectUrl);
        }
      }
    } catch (error) { }
  };

  React.useEffect(() => {
    let arr = sessionStorage.getItem('sourceScreen_Array');
    if (arr === 'undefined' || arr === 'null' || !arr) {
      arr = '';
    }
    const pathName = router?.asPath?.toString()?.slice(1);
    arr = arr?.split(',');
    const path = pathName !== '' ? pathName : 'home';
    const lastElem = arr?.slice(-1)[0];
    if (lastElem !== path) {
      arr.push(path);
    }
    if (arr?.length > 4) {
      arr = arr.slice(-4);
    }
    sessionStorage.setItem('sourceScreen_Array', arr);
    getSeoDetails();
  }, [router?.asPath]);

  React.useEffect(() => {
    async function fetchData() {
      const { authToken } = await getAuthState();
      if (isAuthenticated && authToken && courseDetails) {
        const { isCoursePurchased, courseSlug, isCourseFree } = courseDetails;
        if (isCourseFree === '0' && isCoursePurchased === 0 && courseSlug) {
          const slugCdp = `/course-detail/${courseSlug}`;
          window.location.href = slugCdp;
        }
      }
    }

    fetchData();
  }, [isAuthenticated, courseDetails, courseDetails?.isCourseFree, courseDetails?.isCoursePurchased]);

  React.useEffect(() => {
    const cookie = new Cookie();

    if (!authCookie?.userID) {
      (async () => {
        const userID = cookie.get('userID');
        const apiToken = cookie.get('apiToken');

        if (userID && apiToken) {
          dispatch(
            validateAppAuth({
              userID,
              apiToken,
              forceLogin: false,
            }),
          );
        }
      })();
    }
  }, [dispatch, authCookie]);

  React.useEffect(() => {
    if (!authCookie?.userID && isAuthenticated) {
      dispatch(fetchUserLibrary());
    }
  }, [dispatch, isAuthenticated, authCookie]);

  const [isLiveClassDetails, setIsLiveClassDetails] = React.useState(false);
  const allLiveClassListState = useSelector((state) => state.library.allLiveClass.list);

  const [isContinueSectionDetails, setIsContinueSectionDetails] = React.useState(false);
  const [isMyPurchaseSection, setIsMyPurchaseSection] = React.useState(false);

  React.useEffect(() => {
    if (getCoachMarkData) {
      if (continueWatchList?.length > 0 && typeof window !== 'undefined') {
        if (window?.location?.pathname === '/my-purchases') {
          setTimeout(() => {
            setIsContinueSectionDetails(true);
          }, 700);
        }
      }
    }
  }, [router, continueWatchList, isAuthenticated]);

  React.useEffect(() => {
    if (getCoachMarkData) {
      if (courses?.length > 0 && typeof window !== 'undefined') {
        if (window?.location?.pathname === '/my-purchases') {
          setTimeout(() => {
            setIsMyPurchaseSection(true);
          }, 1200);
        }
      }
    }
  }, [router, courses, isAuthenticated]);

  React.useEffect(() => {
    if (getCoachMarkData) {
      if (allLiveClassListState?.length > 0 && typeof window !== 'undefined') {
        if (window?.location?.pathname === '/my-purchases') {
          setTimeout(() => {
            setIsLiveClassDetails(true);
          }, 500);
        }
      }
    }
  }, [router, allLiveClassListState, isAuthenticated]);

  const [renderTour, setRenderTour] = React.useState(false);

  useEffect(() => {
    if (getCoachMarkData) {
      if (resourceType === 'VIDEO') {
        setTimeout(() => {
          setRenderTour(true);
        }, 500);
      } else {
        setRenderTour(false);
      }
    }
  }, [resourceType, getCoachMarkData, renderTour, isAuthenticated]);

  React.useEffect(() => {
    if (getCoachMarkData) {
      if (courseTypeId === 1) {
        setCourseDashboardTour(courseDashboardTourType1);
      } else if (courseTypeId === 2) {
        setCourseDashboardTour(courseDashboardTourType2);
      } else if (courseTypeId === 3) {
        setCourseDashboardTour(courseDashboardTourType3);
      }
    }
  }, [courseDetails, courseTypeId, isAuthenticated]);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentUrl = window?.location?.pathname;
      const screenWidth = window?.innerWidth > 768;
      if ((currentUrl === '/my-purchases' || currentUrl?.includes('/course-content/')) && screenWidth) {
        if (isAuthenticated) {
          dispatch(getCoachMarkStatus());
        }
      }
    }
    const folder = sessionStorage?.getItem('consumption-folder');
    if (folder) {
      if (folder !== router?.query?.course && folder !== router?.query?.folders?.[0]) {
        sessionStorage.removeItem('parentFilter');
        sessionStorage.removeItem('childFilter');
        sessionStorage.setItem('consumption-folder', router?.query?.folders?.[0]);
      }
    }
  }, [router, isAuthenticated]);

  const tourSteps = React.useMemo(() => {
    if (getCoachMarkData?.length === 0) return null;

    // List all active Coatchmarks
    const activeCoachMarks = getCoachMarkData
      .filter((section) => section.isActive)
      .map((section) => section.sectionId);

    // Video Dashboard Tour
    if (renderTour) {
      if (activeCoachMarks.includes(4)) return VideoDashboardTour;
      if (activeCoachMarks.includes(8)) return myBookMarksTour;
    }

    // My Library Live Class Tour
    if (isLiveClassDetails && activeCoachMarks.includes(6)) return myLibraryLiveClassTour;

    if (
      isMyPurchaseSection &&
      activeCoachMarks.includes(1) &&
      (!activeCoachMarks.includes(6) || !isLiveClassDetails) &&
      (!activeCoachMarks.includes(7) || !isContinueSectionDetails)
    ) return myLibraryYourPurchaseTour;

    if (
      isContinueSectionDetails &&
      activeCoachMarks.includes(7) &&
      (!activeCoachMarks.includes(6) || !isLiveClassDetails)
    ) return myLibraryContinueSection;

    if (
      isAuthenticated &&
      courseTypeId &&
      courseDashboardTour &&
      activeCoachMarks.includes(2) &&
      router?.asPath?.split('/')?.length !== 6 &&
      ['VIDEO', 'TEXT'].includes(resourceType) === false
    ) return courseDashboardTour;

    if (
      courseTypeId === 1 &&
      activeCoachMarks.includes(5) &&
      ['VIDEO', 'TEXT'].includes(resourceType) === false &&
      router?.asPath?.split('/')?.length === 6
    ) return courseDashboardTourType4;

    return null;
  }, [
    renderTour, isAuthenticated, courseTypeId, resourceType,
    courseDashboardTour, isLiveClassDetails, isMyPurchaseSection,
    isContinueSectionDetails, getCoachMarkData, router?.asPath,
  ]);

  return (
    <div
      className={classNames('app-content', quicksand.className, {
        sticky: header?.sticky,
        'fixed-width': haveFixedWidth,
      })}
    >
      <Toaster />
      <NextNProgress
        color="linear-gradient(to right, #a9a6ff 0% 50%, #423DCF 100%)"
        startPosition={0.3}
        stopDelayMs={200}
        height={5}
        showOnShallow
      />

      <MiniPlayerContext.Provider value={setPlayerData}>
        {withoutLayout ? (
          children
        ) : (
          <>
            <HeaderComponent isSticky={header?.sticky || false} isStoreEnableFlag={isStoreEnableFlag} />

            {tourSteps && <Tour tourSteps={tourSteps} />}

            <AndroidModal />

            {isModal ? (
              <NoInternetModal />
            ) : (
              <>
                {isAuthenticated && isCourseContent && resourceType !== 'VIDEO' && resourceType !== 'TEXT' && (
                  <>
                    <CourseDashboardTopBar />
                    <WatchListToggleComponent courseId={courseId} />
                    <NavBarCourseDashboard />
                  </>
                )}

                {children}

                {footer?.hide !== true && <FooterComponent isShowFlag={footer?.hide} />}
              </>
            )}
          </>
        )}
        {isAuthenticated && resourceType !== 'VIDEO' && resourceType !== 'TEXT' && (
          <MiniPlayer key={playerData?.video?.assignedLessonId} ref={playerRef} {...playerData} />
        )}
      </MiniPlayerContext.Provider>

      <AuthComponent />
      <BuyNowContainer />

      <Script strategy="afterInteractive" id="mux" src="https://cdn.jsdelivr.net/npm/mux.js@5.5.1/dist/mux.min.js" />
      <Script
        strategy="afterInteractive"
        id="detect-incognito"
        src="https://cdn.jsdelivr.net/gh/Joe12387/detectIncognito@v1.3.0/dist/es5/detectIncognito.min.js"
      />

      <Script
        strategy="afterInteractive"
        id="moengageEventPush"
        dangerouslySetInnerHTML={{
          __html: `(function() {
                  window.CURRENT_SEND_URL = "___";
                  var origOpen = XMLHttpRequest.prototype.open;
                  XMLHttpRequest.prototype.open = function() {
                      window.CURRENT_SEND_URL = arguments[1];
                      origOpen.apply(this, arguments);
                  };
                  var origSend = XMLHttpRequest.prototype.send;
                  XMLHttpRequest.prototype.send = function() {
                    origSend.apply(this, arguments);

                    if(window.CURRENT_SEND_URL.indexOf("moengage.com")!==-1 && typeof arguments?.[0] !== 'undefined' && arguments?.[0] !== 'null' && arguments?.[0]?.length > 0){
                      detectIncognito().then((result) => {
                      let modifiedObj;
                      if(JSON.parse(arguments?.[0])?.query_params?.is_incognito !== undefined){
                        var sampleObj = JSON.parse(arguments?.[0]);
                        var queryParams = sampleObj?.query_params;
                        queryParams['is_incognito'] = result?.isPrivate;
                        modifiedObj = JSON.stringify(sampleObj);
                      }else{
                        modifiedObj = arguments?.[0];
                      }
                      fetch('${DE_API_DOMAIN}/dataengg-ws/api/v1/siq/event-push', {
                              method: 'POST',
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                              },
                              body: modifiedObj
                          });
                        });
                      }
                  };
              })();`,
        }}
      />
    </div>
  );
}

class MyApp extends App {
  static getInitialProps = wrapper.getInitialAppProps((store) => async (context) => {
    const { userID, authToken } = getAuthState(context.ctx);

    let pageSeoObject = null;
    try {
      if (
        context.router.asPath.startsWith('/_next') === false &&
        context.router.asPath !== '/serviceworker.js'
      ) {
        const { data: { status, data } } = await javaApi.get(
          'content-ws/v1/seo/detail/get',
          { params: { url: context.router.asPath } },
        );

        if (status === 200) {
          pageSeoObject = data.response;
        }
      }
    } catch (e) {
      // Handle Error
    }

    // Redirect to redirectUrl
    if (pageSeoObject?.redirectUrl) {
      if (context?.ctx?.res) {
        context.ctx.res.writeHead(301, { Location: pageSeoObject.redirectUrl });
        context.ctx.res.end();
      } else if (typeof Router.replace === 'function') {
        Router.replace('/');
      }

      return {};
    }

    if (userID && authToken) {
      await store.dispatch(
        validateAppAuth({
          userID,
          apiToken: authToken,
          forceLogin: false,
        }),
      );
    }

    const { isAuthenticated } = store.getState().auth;

    if (isAuthenticated) {
      await store.dispatch(fetchUserLibrary());
    }

    return {
      pageProps: {
        ...(await App.getInitialProps(context)).pageProps,
        pathname: context.ctx.asPath,
        authCookie: { userID, apiToken: authToken },
        pageSeoObject,
      },
    };
  });

  render() {
    const { Component, pageProps } = this.props;
    const { pageSeoObject } = pageProps;
    const isTestSeriesPage = /^\/test-series\//.test(pageProps.pathname);

    const seoBacklist = [/^\/course-detail/];

    const isSeoBlacklistedRoute = seoBacklist.some((rx) => rx.test(pageProps.pathname));

    const faqSchema = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: pageSeoObject?.faqs?.map((faq) => ({
        '@type': 'Question',
        name: faq.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq.answer,
        },
      })),
    };

    return (
      <GrowthBookProvider growthbook={growthbook}>
        <MyAppComponent withoutLayout={isTestSeriesPage} {...pageProps}>
          {!isSeoBlacklistedRoute && (
            <OGMetaTags
              title={pageSeoObject?.title}
              desc={pageSeoObject?.metaDescription}
              keywords={pageSeoObject?.keywords}
              category={pageSeoObject?.category}
              robots={pageSeoObject?.robots}
              canonical={
                pageSeoObject?.canonical?.length ? pageSeoObject?.canonical : `${FRONTEND_DOMAIN}${pageProps.pathname}`
              }
            />
          )}

          <Component {...pageProps} />

          {pageSeoObject?.faqs?.length > 0 && (
            <Script id="faq-schema" type="application/ld+json">
              {JSON.stringify(faqSchema)}
            </Script>
          )}
        </MyAppComponent>
      </GrowthBookProvider>
    );
  }
}

export default wrapper.withRedux(MyApp);
