/* eslint-disable max-len */
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import camelCase from 'lodash.camelcase';
import fromPairs from 'lodash.frompairs';
import mapKeys from 'lodash.mapkeys';

import { javaApi } from '../../utils/AxiosInstance';
import { getOfflineCentreData } from '../../utils/commonApiUtils';
import { formatCourseDetails } from '../../utils/helper';

import { fetchResourceDetails } from './resourceDashboard';

// Course Consumption Actions
export const fetchUserCourseSidebar = createAsyncThunk(
  'courseConsumption/fetchUserCourseSidebar',
  async ({ courseId, languageId }, thunkAPI) => {
    try {
      const courseOfflineDataRes = await getOfflineCentreData(courseId);
      const response = await javaApi.get('app-content-ws/v1/course/getDetails', {
        params: {
          courseId,
          languageId,
          isOfflineCourse: courseOfflineDataRes?.isOfflineEnabled,
          offlineCentreId: courseOfflineDataRes?.offlineCentreId || '',
        },
      });

      const { success, ...courseDetails } = response.data || {};
      const haveSidebar = courseDetails.courseTemplateTypeId !== 3;

      const sidebarFolders = haveSidebar ? courseDetails.data : [];

      if (success) {
        return {
          courseId,
          haveSidebar,
          sidebarFolders,
          languageId,
        };
      }

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const getCourseContentFilters = createAsyncThunk(
  'courseConsumption/getCourseContentFilters',
  async (thunkAPI) => {
    try {
      const response = await javaApi.get('app-content-ws/v1/course/getCourseContentFilters');

      const { success, data } = response.data;
      if (success) {
        return data;
      }

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const fetchUserCourseDetails = createAsyncThunk(
  'courseConsumption/fetchUserCourseDetails',
  async ({ courseId, languageId, parentId, parentFilter, childFilter, authToken }, thunkAPI) => {
    try {
      const params = { courseId, languageId, parentId };

      if (parentFilter) {
        params.contentTypeFilter = parentFilter;
      }

      if (childFilter) {
        params.contentStatusTypeFilter = childFilter;
      }
      const courseOfflineDataRes = await getOfflineCentreData(courseId, authToken);
      if (courseOfflineDataRes) {
        params.isOfflineCourse = courseOfflineDataRes?.isOfflineEnabled;
        params.offlineCentreId = courseOfflineDataRes?.offlineCentreId || '';
      }
      const response = await javaApi.get('app-content-ws/v1/course/getDetails', {
        params,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { success, ...courseDetails } = response.data || {};

      // const isRoot = courseDetails.parentId === null;
      const isRoot = courseDetails.parents.length === 0;
      const haveSidebar = courseDetails.courseTemplateTypeId !== 3;

      const currentState = await thunkAPI.getState();
      const { details: courseDetailState, languages } = currentState.courseConsumption.courseDetails;
      const isDiffCourse = courseDetailState?.courseId !== courseDetails?.courseId;

      let { sidebarFolders } = courseDetailState;
      if (isRoot && isDiffCourse && haveSidebar) {
        sidebarFolders = courseDetails.data;
        courseDetails.data = [];
      }

      courseDetails.languages = [];
      if (!isRoot || courseDetails.courseTemplateTypeId === 3) {
        courseDetails.languages = languages;
      }

      try {
        const courseActiveTabsResponse = await javaApi.get('/app-content-ws/v2/course/get/courseLevelTabs', {
          params: { courseId, languageId },
          headers: { Authorization: `Bearer ${authToken}` },
        });

        const courseTabs = courseActiveTabsResponse.data.data.map((courseTab) => {
          let tabKey = null;

          switch (courseTab.id) {
            case 1:
              tabKey = 'MY_COURSE';
              break;
            case 2:
              tabKey = 'MY_DOUBTS';
              break;
            case 3:
              tabKey = 'MY_PLAYLIST';
              break;
            case 4:
              tabKey = 'ANSWER_WRITING';
              break;
            default:
              return null;
          }

          return [tabKey, Boolean(courseTab.isVisible)];
        });
        courseDetails.activeTabs = fromPairs(courseTabs);
      } catch (error) {
        courseDetails.activeTabs = {};
      }

      if (success) {
        return {
          ...courseDetails,
          isRoot,
          haveSidebar,
          sidebarFolders,
          languageId,
        };
      }

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const fetchUserCourseMinDetail = createAsyncThunk(
  'courseConsumption/fetchUserCourseMinDetail',
  async ({ courseId, languageId, contentId }, thunkAPI) => {
    try {
      const { authToken } = thunkAPI.getState().auth;

      const courseOfflineDataRes = await getOfflineCentreData(courseId, authToken);
      const response = await javaApi.get('app-content-ws/v1/course/getDetails/minify', {
        params: {
          courseId,
          languageId,
          contentId,
          isOfflineCourse: courseOfflineDataRes?.isOfflineEnabled,
          offlineCentreId: courseOfflineDataRes?.offlineCentreId || '',
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { success, resource, ...data } = response.data;
      if (success) {
        resource.parents = data.parent;

        thunkAPI.dispatch({
          type: fetchResourceDetails.fulfilled.toString(),
          payload: resource,
        });
        return data;
      }

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const resetUserCourseDetails = createAction('courseConsumption/resetUserCourseDetails');

export const fetchCourseLanguages = createAsyncThunk(
  'courseConsumption/fetchCourseLanguages',
  async (courseId, thunkAPI) => {
    try {
      const { authToken } = thunkAPI.getState().auth;

      const response = await javaApi.post(
        'app-content-ws/api/web/course/languages',
        { courseId },
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      if (response?.data?.success) return response.data.data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

export const updateCourseDetails = createAsyncThunk(
  'courseConsumption/updateCourseDetails',
  async (payload, thunkAPI) => {
    try {
      const { authToken } = thunkAPI.getState().auth;
      const { courseId, languageId, parentId } = thunkAPI.getState().courseConsumption?.courseDetails?.details || {};

      const courseOfflineDataRes = await getOfflineCentreData(courseId);
      const response = await javaApi.get('app-content-ws/v1/course/getDetails', {
        params: {
          courseId,
          languageId,
          parentId,
          isOfflineCourse: courseOfflineDataRes?.isOfflineEnabled,
          offlineCentreId: courseOfflineDataRes?.offlineCentreId || '',
        },
        headers: { Authorization: `Bearer ${authToken}` },
      });

      const { success, data } = response.data || {};

      if (success) return data;

      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: 'API Error!',
      });
    }
  },
);

// Initial State
const initialState = {
  courseDetails: {
    loading: false,
    details: {
      haveSidebar: null,
      parents: [],
    },
    isParentLoading: false,
    languages: [],
    isResource: null,
    languageId: null,
    elementContentType: null,
    error: null,
  },
  isAndroidModalPopUp: {
    show: false,
    screenName: null,
    videoDashBoard: null,
    disableClose: false,
  },
  filterData: {
    loading: false,
    data: [],
    error: null,
  },
};

// Auth Slice
const courseConsumptionSlice = createSlice({
  name: 'courseConsumption',
  initialState,
  reducers: {
    isAndroidModal: (state, action) => {
      const { details, show, disableClose } = action.payload;
      state.isAndroidModalPopUp = {
        show,
        screenName: details,
        videoDashBoard: details,
        disableClose,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserCourseDetails.pending, (state, action) => {
        const isParentLoading = !!action.meta.arg.parentId;
        state.courseDetails = {
          ...state.courseDetails,
          loading: true,
          isParentLoading,
          details: {
            haveSidebar: null,
            sidebarFolders: state?.courseDetails?.details?.sidebarFolders || [],
          },
          languageId: null,
        };
      })
      .addCase(
        fetchUserCourseDetails.fulfilled,
        (state, { payload: { languages, languageId, isCourseFree, ...payload } }) => {
          state.courseDetails = {
            ...initialState.courseDetails,
            details: {
              ...payload,
              data: formatCourseDetails(payload.data || []),
              parents: payload.parents,
              isCourseFree: parseInt(isCourseFree, 10),
            },
            languages: languages?.map((language) => mapKeys(language, (v, k) => camelCase(k))),
            languageId,
          };
        },
      )
      .addCase(fetchUserCourseDetails.rejected, (state, action) => {
        state.courseDetails = {
          ...initialState.courseDetails,
          details: {
            haveSidebar: null,
          },
          error: action.payload,
        };
      })
      .addCase(updateCourseDetails.fulfilled, (state, action) => {
        state.courseDetails.details.data = formatCourseDetails(action.payload || []);
      })
      .addCase(fetchUserCourseSidebar.fulfilled, (state, action) => {
        state.courseDetails = {
          ...state.courseDetails,
          details: {
            ...state.courseDetails.details,
            ...action.payload,
          },
        };
      })
      .addCase(fetchCourseLanguages.pending, (state) => {
        state.courseDetails = {
          ...state.courseDetails,
          languages: initialState.courseDetails.languages,
        };
      })
      .addCase(fetchCourseLanguages.fulfilled, (state, action) => {
        state.courseDetails = {
          ...state.courseDetails,
          languages: action.payload?.map((language) => mapKeys(language, (v, k) => camelCase(k))),
        };
      })
      .addCase(fetchCourseLanguages.rejected, (state) => {
        state.courseDetails = {
          ...state.courseDetails,
          languages: initialState.courseDetails.languages,
        };
      })
      .addCase(fetchUserCourseMinDetail.pending, (state) => {
        state.courseDetails.loading = true;
        state.courseDetails.isResource = null;
        state.courseDetails.elementContentType = null;
      })
      .addCase(fetchUserCourseMinDetail.fulfilled, (state, action) => {
        state.courseDetails = {
          ...state.courseDetails,
          loading: initialState.courseDetails.loading,
          details: {
            ...state.courseDetails.details,
            parents: action.payload.parent || [],
          },
          elementContentType: action.payload.elementContentType,
        };
      })
      .addCase(fetchUserCourseMinDetail.rejected, (state) => {
        state.courseDetails = {
          ...state.courseDetails,
          details: {
            ...state.courseDetails.details,
            parents: initialState.courseDetails.details.parents,
          },
          loading: initialState.courseDetails.loading,
          languages: initialState.courseDetails.languages,
          elementContentType: initialState.courseDetails.elementContentType,
        };
      })
      .addCase(resetUserCourseDetails, (state) => {
        state.courseDetails = initialState.courseDetails;
      })
      .addCase(getCourseContentFilters.pending, (state) => {
        state.filterData.loading = true;
        state.filterData.data = [];
        state.filterData.error = null;
      })
      .addCase(getCourseContentFilters.fulfilled, (state, action) => {
        state.filterData.loading = false;
        state.filterData.data = action.payload;
        state.filterData.error = null;
      })
      .addCase(getCourseContentFilters.rejected, (state, action) => {
        state.filterData.loading = false;
        state.filterData.data = [];
        state.filterData.error = action.payload;
      });
  },
});

export default courseConsumptionSlice.reducer;

export const { isAndroidModal } = courseConsumptionSlice.actions;
