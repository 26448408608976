import { Cookie } from 'next-cookie';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { javaApi } from '../../../utils/AxiosInstance';

// eslint-disable-next-line import/no-cycle
import { authUserProfile } from '../authSlice/actions';

const initialData = {
  originalUserId: null,
  originalUserToken: null,
};

// Actions
export const fetchExamPreferenceList = createAsyncThunk('accountInfo/fetchExamPreferenceList', async (thunkAPI) => {
  try {
    const response = await javaApi.get('/app-content-ws/api/web/user/exam/preference/list');
    if (response?.data?.success) return response?.data;

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const getTransactionsData = createAsyncThunk('user_transactions', async (page, thunkAPI) => {
  try {
    const cookies = new Cookie();
    const userId = cookies.get('userID');
    const apiToken = cookies.get('apiToken');
    const response = await javaApi.post(
      `app-content-ws/api/web/user/transactions?page=${page}`,
      {
        userId,
        page,
      },
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      },
    );

    if (response?.status === 200) {
      return response?.data?.data;
    }
    return thunkAPI.rejectWithValue(response?.msg);
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const impersonateUser = createAsyncThunk('impersonate_user', async (mobile, thunkAPI) => {
  try {
    const cookies = new Cookie();
    const params = JSON.stringify({
      mobileNumber: mobile,
      platform: 'WEB',
    });
    const apiToken = cookies.get('apiToken');
    const response = await javaApi.post('user-auth-ws/v1/auth/requestusertoken', params, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (response?.status === 200) {
      const { message, userToken, userId, success } = response.data;
      initialData.originalUserId = await cookies.get('userID');
      initialData.originalUserToken = await cookies.get('apiToken');
      const expirationDate = new Date();
      expirationDate?.setTime(expirationDate.getTime() + 365 * 24 * 60 * 60 * 1000);
      cookies.set('apiToken', userToken, {
        expires: expirationDate,
        path: '/',
      });
      cookies.set('userID', userId, { expires: expirationDate, path: '/' });
      const data = {
        userToken,
        userId,
        didImpersonate: success,
        message,
      };
      return data;
    }
    return thunkAPI.rejectWithValue(response?.msg);
  } catch (error) {
    return thunkAPI.rejectWithValue('API Error!');
  }
});

export const triggerEmailOtp = createAsyncThunk('trigger_email_otp', async (email, thunkAPI) => {
  try {
    const cookies = new Cookie();
    const userId = cookies.get('userID');
    const res = await javaApi.post('app-content-ws/api/web/verify/primary/number', {
      userId,
      // apiToken,
      action: 2,
      email,
    });
    if (res?.status === 200) {
      return res?.data;
    }
    return thunkAPI.rejectWithValue(res?.msg);
  } catch (e) {
    return e?.response?.data;
    // return thunkAPI.rejectWithValue('API Error!');
  }
});

export const verifyEmailOtp = createAsyncThunk('verify_email_otp', async ({ email, otp }, thunkAPI) => {
  try {
    const cookies = new Cookie();
    const userId = cookies.get('userID');
    const res = await javaApi.post('app-content-ws/api/web/verify/primary/otp', {
      userId,
      // apiToken,
      action: 2,
      email,
      otp,
    });
    if (res?.status === 200) {
      return res?.data;
    }
    return thunkAPI.rejectWithValue(res?.msg);
  } catch (e) {
    return e?.response?.data;
    // return thunkAPI.rejectWithValue('API Error!');
  }
});

export const triggerMobileOtp = createAsyncThunk('trigger_mobile_otp', async (mobile, thunkAPI) => {
  try {
    const cookies = new Cookie();
    const userId = cookies.get('userID');
    const res = await javaApi.post('app-content-ws/api/web/verify/primary/number', {
      userId,
      // apiToken,
      action: 1,
      mobile,
    });
    if (res?.status === 200) {
      return res?.data;
    }
    return thunkAPI.rejectWithValue(res?.msg);
  } catch (e) {
    return e?.response?.data;
    // return thunkAPI.rejectWithValue('API Error!');
  }
});

export const verifyMobileOtp = createAsyncThunk('verify_mobile_otp', async ({ mobile, otp }, thunkAPI) => {
  try {
    const cookies = new Cookie();
    const userId = cookies.get('userID');
    const res = await javaApi.post('app-content-ws/api/web/verify/primary/otp', {
      userId,
      // apiToken,
      action: 1,
      mobile,
      otp,
    });
    if (res?.status === 200) {
      return res?.data;
    }
    return thunkAPI.rejectWithValue(res?.msg);
  } catch (e) {
    return e?.response?.data;
    // return thunkAPI.rejectWithValue('API Error!');
  }
});

export const getCityStateData = createAsyncThunk('accountInfo/getCityStateData', async ({ pincode }, thunkAPI) => {
  try {
    const response = await javaApi.post(`user-auth-ws/api/v1/pincode?pincode=${pincode}`);
    if (response?.data?.success) return response?.data;

    return thunkAPI.rejectWithValue({
      field: 'server',
      message: response?.data?.msg,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.msg);
  }
});

export const updateUserProfile = createAsyncThunk(
  'accountInfo/updateUserProfile',
  async ({ name, address, city, state, pinCode, landmark, area }, thunkAPI) => {
    try {
      const cookies = new Cookie();
      const userId = cookies.get('userID');
      const apiToken = cookies.get('apiToken');
      const response = await javaApi.post(
        'user-auth-ws/api/v1/update/user/profile',
        {
          userId,
          // apiToken,
          firstName: name,
          address,
          city,
          state,
          pinCode,
          landmark,
          area,
        },
      );
      if (response?.data?.success) {
        await thunkAPI.dispatch(authUserProfile({ userID: userId, apiToken }));
        return response?.data;
      }
      return thunkAPI.rejectWithValue({
        field: 'server',
        message: response?.data?.msg,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.msg);
    }
  },
);
