import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import { configureStore } from '@reduxjs/toolkit';

import { getAuthState } from '../utils/AxiosInstance';

import rootReducer from './reducer';
import { setAuthUserAndToken } from './slices/authSlice/actions';

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    return {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };
  }

  return rootReducer(state, action);
};

const middlewares = [];

if (process.env.NODE_ENV !== 'production') {
  // middlewares.push(logger);
}

// Create the store
const makeStore = (context) => {
  const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      getDefaultMiddleware().concat(middlewares),
    devTools: process.env.NODE_ENV !== 'production',
  });

  const { userID, authToken } = getAuthState(context.ctx);
  if (userID && authToken) {
    // Set API Creds
    store.dispatch(setAuthUserAndToken(userID, authToken));
  }

  global.store = store;

  return store;
};

export default createWrapper(makeStore);
