export const myLibraryYourPurchaseTour = [
  {
    target: '#yourPurchaseSection',
    content: 'Here are all your purchases',
    disableBeacon: true,
    sectionId: 1,
  },
  {
    target: '#firstCardDetails',
    content: 'Click on your purchased course',
    disableBeacon: true,
    sectionId: 1,
  },
];

export const courseDashboardTourType1 = [
  {
    target: '#courseDashboardSearchBar',
    content: 'You can search your favourite chapters here',
    disableBeacon: true,
    sectionId: 2,
  },
  {
    target: '#firstSideBarItem',
    content: 'Here left side pane shows all chapters of this course',
    disableBeacon: true,
    placement: 'bottom',
    sectionId: 2,
  },
  {
    target: '#firstFolderItem',
    content: 'Here left side pane shows all lessons of a chapter',
    disableBeacon: true,
    sectionId: 2,
  },
];

export const courseDashboardTourType2 = [
  {
    target: '#courseDashboardSearchBar',
    content: 'You can search your favourite lessons here',
    disableBeacon: true,
    sectionId: 2,
  },
  {
    target: '#firstSideBarItem',
    content: 'Here left side pane shows all chapters of this course',
    disableBeacon: true,
    placement: 'bottom',
    sectionId: 2,
  },
  {
    target: '#firstCourseItem',
    content: 'Tap to watch your favourite content',
    disableBeacon: true,
    sectionId: 2,
  },
];

export const courseDashboardTourType3 = [
  {
    target: '#courseDashboardSearchBar',
    content: 'You can search your favourite topic here',
    disableBeacon: true,
    sectionId: 2,
  },
  {
    target: '#firstCourseItem',
    content: 'Tap to watch your favourite content',
    disableBeacon: true,
    sectionId: 2,
  },
];

export const VideoDashboardTour = [
  {
    target: '#topBar',
    content: 'Here is easy navigation to switch between multiple topics',
    disableBeacon: true,
    placement: 'bottom',
    sectionId: 4,
  },
  {
    target: '#tabsOutterWrapper',
    content: 'Here you can find all additional resources of particular topic',
    disableBeacon: true,
    placement: 'left',
    sectionId: 4,
  },
];

export const LiveClassTour = [
  {
    target: '#liveClassWrapper',
    content: 'Get all your list of todays live classes here',
    disableBeacon: true,
    isLiveClassSection: true,
    sectionId: 3,
  },
  {
    target: '#liveClassSchedule',
    content: 'Here you can find schedule of this live course',
    disableBeacon: true,
    placement: 'bottom',
    isLiveClassSection: true,
    sectionId: 3,
  },
];

export const courseDashboardTourType4 = [
  {
    target: '#firstCourseItem',
    content: 'Tap to watch your favourite content',
    disableBeacon: true,
    isCourseDashBoardFolder: true,
    sectionId: 5,
  },
];

export const myLibraryLiveClassTour = [
  {
    target: '#libraryWrapperList',
    content: "Get all your today's live classes at one place",
    disableBeacon: true,
    isCourseDashBoardFolder: true,
    sectionId: 6,
  },
];

export const myLibraryContinueSection = [
  {
    target: '#continueSectionSlider',
    content: 'Get all your un-completed lessons/tests at one place',
    disableBeacon: true,
    isCourseDashBoardFolder: true,
    sectionId: 7,
  },
];

export const myBookMarksTour = [
  {
    target: '#topBarBookMark',
    content: 'Tap here to bookmark your favourite content',
    disableBeacon: true,
    isCourseDashBoardFolder: true,
    sectionId: 8,
  },
];
