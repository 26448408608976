/* eslint-disable default-param-last */
import React from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  getCourseVideoTracking,
  updateContinueWatchingVideos,
  updateTotalWatchingVideos,
} from '../redux/slices/resourceDashboard';

// eslint-disable-next-line import/no-cycle
import MiniPlayerContext from '../contexts/MiniPlayerContext';
import { useMoengageEventTracker } from './useMoengage';

const defaultOptions = {
  syncProgress: false,
  autoPlay: true,
  onPlay: () => null,
};

const usePlayerEventCapture = (courseId, languageId, video, options = defaultOptions, primaryPlayerRef = null) => {
  const setPlayerData = React.useContext(MiniPlayerContext);

  let lastPlayedTime = null;
  // eslint-disable-next-line no-unused-vars
  let videoPlayedTime = 0;
  let videoLastTimestamp = null;
  let videoWatchTime = 0;
  let currentTimestamp = 0;

  const playerRef = React.useRef(null);
  const isPlayingRef = React.useRef(null);

  const dispatch = useDispatch();
  const triggerMoengageEvent = useMoengageEventTracker();

  const [player] = React.useState(() => video?.player);
  const [videoLastState, setVideoLastState] = React.useState(null);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const { isAuthenticated, videoInfo, trackedVideo, courseDetails, playedVideosNoRecord } = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    videoInfo: state.resourceDashboard.videoInfo,
    trackedVideo: state.resourceDashboard.videoTracking?.details,
    courseDetails: state.courseConsumption.courseDetails.details,
    playedVideosNoRecord: state.resourceDashboard.playedvideosNoRecord,
  }), shallowEqual);

  React.useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (!HTMLMediaElement.prototype.hasOwnProperty('playing')) {
      Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
        get() {
          return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
        },
      });
    }
  }, []);

  const updateVideoState = React.useCallback(
    (eventName) => async (event) => {
      if (!video) return;

      if (eventName === 'play' && playerRef?.current?.videoElement?.playbackRate) {
        const playerConfig = window.sessionStorage.getItem('playerConfig');
        if (playerConfig) {
          const { playbackRate } = JSON.parse(playerConfig);
          playerRef.current.videoElement.playbackRate = playbackRate;
        }
      }

      if (eventName === 'ended' && typeof playerRef?.current?.onVideoEnd === 'function') {
        window.clearInterval(window.ON_VIDEO_END_TIMEOUT);

        window.ON_VIDEO_END_TIMEOUT = setTimeout(playerRef.current.onVideoEnd, 500);
      }

      const videoId = video?.videoTypeId === 4 ? `JW-${video?.referenceId}` : video?.referenceId;
      const lessonId = video?.contentId;
      const assignedLessonId = video?.assignedLessonId;

      if (
        eventName === 'play' &&
        typeof options.onPlay === 'function'
      ) options.onPlay(playerRef, 'updateVideoState');

      let isVideoPlaying = false;
      let lastTimestamp = null;
      let videoTotalTime = null;
      if (player === 'VIDEO_CIPHER') {
        isVideoPlaying = playerRef?.current?.status === 1;
        lastTimestamp = parseInt(playerRef.current.currentTime * 1000, 10);
        videoTotalTime = parseInt(playerRef.current.duration * 1000, 10);
      } else if (player === 'SHAKA') {
        isVideoPlaying = playerRef?.current?.videoElement?.playing;
        lastTimestamp = parseInt(playerRef?.current?.videoElement?.currentTime, 10) * 1000;
        videoTotalTime = parseInt(playerRef?.current?.videoElement?.duration, 10) * 1000;
      } else if (player === 'JW_PLAYER') {
        isVideoPlaying = playerRef.current.videoElem.playing;
        lastTimestamp = parseInt(playerRef.current.getPosition() * 1000, 10);
        videoTotalTime = parseInt(playerRef.current.getDuration() * 1000, 10);

        if (event?.oldstate === 'buffering') return;
      } else if (player === 'VIMEO') {
        const playerObj = playerRef?.current;
        if (!playerObj) return;

        isVideoPlaying = playerRef?.current?.player?.isPlaying;

        lastTimestamp = await playerObj?.getCurrentTime();
        lastTimestamp = parseInt(lastTimestamp * 1000, 10);
        videoTotalTime = await playerObj?.getDuration();
        videoTotalTime = parseInt(videoTotalTime * 1000, 10);
      }

      // Setting State to Update Outside Hook
      if (isPlayingRef.current !== isVideoPlaying) {
        setIsPlaying(isVideoPlaying);
      }

      isPlayingRef.current = isVideoPlaying;
      playerRef.current.isPlaying = isVideoPlaying;

      const roundLastTimestamp = Math.round(lastTimestamp / 1000);

      if (isVideoPlaying) {
        if (roundLastTimestamp !== currentTimestamp && !['pause', 'seeked'].includes(eventName)) {
          videoWatchTime += 1;
          currentTimestamp = roundLastTimestamp;
        }
      }

      if (lastTimestamp < 1000 && eventName !== 'seeked') return;
      if (videoLastTimestamp === roundLastTimestamp) return;

      let trackable = 1;
      if (courseDetails?.isCourseFree) {
        trackable = 0;
      } else if (video?.source === 'secondary') {
        trackable = 2;
      }

      const videoState = {
        courseId,
        videoId,
        lessonId,
        lastTimestamp,
        videoTotalTime,
        contentType: 'VD',
        assignedLessonId,
        videoTypeId: video?.videoTypeId,
        languageId,
        trackable,
      };

      if (
        (isVideoPlaying || ['pause', 'seeked', 'ended'].includes(eventName)) &&
        videoLastTimestamp !== roundLastTimestamp &&
        (roundLastTimestamp % 10 === 0 || typeof eventName !== 'undefined')
      ) {
        if (lastTimestamp > lastPlayedTime) {
          if (isVideoPlaying || ['pause', 'ended'].includes(eventName)) {
            videoPlayedTime += roundLastTimestamp - videoLastTimestamp;
          }

          const videoWatchedState = {
            courseId,
            videoId,
            totalWatchTime: videoWatchTime * 1000,
            lessonId,
            contentType: 'VD',
            assignedLessonId,
          };

          videoWatchTime = 0;
          if (isAuthenticated) {
            dispatch(updateTotalWatchingVideos(videoWatchedState));
          }
        }

        videoLastTimestamp = roundLastTimestamp;

        // if (isAuthenticated && video?.source !== 'secondary' && !video?.isCourseFree) {
        if (isAuthenticated) {
          dispatch(updateContinueWatchingVideos(videoState));
        }
      }
    },
    [videoWatchTime, video, courseDetails],
  );

  const deviceOrientation = () => {
    const isLandscape = window.matchMedia('(orientation: landscape)').matches;

    return isLandscape ? 'landscape' : 'portrait';
  };

  const triggerEvent = React.useCallback(
    (eventName, eventData) => {
      triggerMoengageEvent('player_interaction', {
        ...eventData,
        course_category: courseDetails?.courseTypeName,
        course_id: courseDetails?.courseId,
        course_status: courseDetails?.coursePaymentStatus,
        course_type: courseDetails?.isCourseFree ? 'paid' : 'free',
        screen_orientation: deviceOrientation(),
      });
    },
    [
      courseDetails?.courseId,
      courseDetails?.coursePaymentStatus,
      courseDetails?.courseTypeName,
      courseDetails?.isCourseFree,
      triggerMoengageEvent,
    ],
  );

  React.useEffect(() => {
    if (courseId && video?.referenceId) {
      const videoId = video?.videoTypeId === 4 ? `JW-${video?.referenceId}` : video?.referenceId;
      const lessonId = video?.contentId;
      const assignedLessonId = video?.assignedLessonId;

      if (courseId && videoId && lessonId && assignedLessonId) {
        dispatch(
          getCourseVideoTracking({
            courseId,
            videoId,
            lessonId,
            assignedLessonId,
          }),
        );
      }
    }
  }, [video?.referenceId, courseId]);

  React.useEffect(() => setVideoLastState(trackedVideo), [trackedVideo, setVideoLastState]);

  React.useEffect(() => {
    if (playedVideosNoRecord === 'No records found') {
      if (courseId && video) {
        const videoId = video?.videoTypeId === 4 ? `JW-${video?.referenceId}` : video?.referenceId;
        const lessonId = video?.contentId;
        const assignedLessonId = video?.assignedLessonId;
        const totalWatchTime = 0;

        dispatch(
          updateTotalWatchingVideos({
            courseId,
            videoId,
            lessonId,
            assignedLessonId,
            totalWatchTime,
            contentType: 'VD',
          }),
        );
      }
    }
  }, [courseId, dispatch, playedVideosNoRecord, video]);

  React.useEffect(() => {
    if (
      player &&
      typeof setPlayerData === 'function' &&
      playerRef.current &&
      !primaryPlayerRef?.current &&
      video?.source !== 'secondary'
    ) {
      setPlayerData({
        playerRef,
        languageId,
        courseId,
        video,
      });
    }

    if (player && playerRef.current && typeof Object.getPrototypeOf(playerRef.current)?.pauseVideo === 'undefined') {
      // Adding new functions to playerRef
      const playVideo = (fromKeyboard = false) => {
        if (fromKeyboard === true && video.source === 'mini-player') return false;

        try {
          if (player === 'SHAKA') {
            playerRef.current.videoElement.play();
          } else if (player === 'VIDEO_CIPHER') {
            playerRef.current.play();
            playerRef.current.addEventListener('load', playerRef.current.play);
          } else if (player === 'VIMEO') {
            playerRef.current.setCurrentTime(1000);
          }
        } catch (e) { return false; }

        if (
          typeof options?.onPlay === 'function' &&
          playerRef.current.isPlaying
        ) options.onPlay(playerRef, 'playVideo');

        return true;
      };

      const pauseVideo = (fromKeyboard = false) => {
        if (fromKeyboard === true && video.source === 'mini-player') return false;

        try {
          if (player === 'SHAKA') {
            playerRef.current.videoElement.pause();
          } else if (player === 'VIDEO_CIPHER') {
            playerRef.current.pause();
          } else if (player === 'VIMEO') {
            playerRef.current.pause();
          }
        } catch (e) {
          return false;
        }

        return true;
      };

      const seekTimeTo = (seekTimeMs) => {
        try {
          if (player === 'SHAKA') {
            playerRef.current.videoElement.currentTime = seekTimeMs / 1000;
          } else if (player === 'VIDEO_CIPHER') {
            playerRef.current.seek(seekTimeMs / 1000);
          } else if (player === 'VIMEO') {
            playerRef?.current?.setCurrentTime(seekTimeMs / 1000);
          }
        } catch (e) {
          return false;
        }

        return true;
      };

      Object.setPrototypeOf(playerRef.current, {
        triggerEvent,
        updateVideoState,
        playVideo,
        pauseVideo,
        seekTimeTo,
        ...Object.getPrototypeOf(playerRef.current),
      });
    }
  }, [player, options]);

  React.useEffect(() => {
    if (
      player &&
      videoLastState &&
      playerRef.current &&
      parseInt(videoLastState?.lessonId, 10) === parseInt(video?.contentId, 10)
    ) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      lastPlayedTime = Math.round(videoLastState.totalWatchTime / 1000);

      if (player === 'VIDEO_CIPHER' && playerRef?.current?.seek) {
        playerRef?.current?.seek(videoLastState.lastTimestamp / 1000);
        playerRef?.current?.addEventListener('load', () => {
          playerRef?.current?.seek(videoLastState.lastTimestamp / 1000);

          if (options?.autoPlay) {
            playerRef?.current?.playVideo();
          }
        });
      } else if (player === 'SHAKA') {
        setTimeout(() => {
          playerRef.current.videoElement.currentTime = videoLastState.lastTimestamp / 1000;

          if (options?.autoPlay) {
            playerRef?.current?.playVideo();
          }
        }, 100);
      } else if (player === 'JW_PLAYER' && playerRef?.current?.once) {
        playerRef?.current?.seek(videoLastState.lastTimestamp / 1000);
        playerRef?.current?.once('beforePlay', () => {
          playerRef?.current?.seek(videoLastState.lastTimestamp / 1000);
        });
      } else if (player === 'VIMEO') {
        window.clearInterval(window.REACT_PLAYER_INT);
        window.REACT_PLAYER_INT = window.setInterval(() => {
          if (playerRef?.current?.isReady) {
            playerRef.current.setCurrentTime(videoLastState.lastTimestamp / 1000);
            window.clearInterval(window.REACT_PLAYER_INT);
          }
        }, 500);

        return () => window.clearInterval(window.REACT_PLAYER_INT);
      }
    } else if (player && playerRef.current && videoLastState === false && options?.autoPlay) {
      window.VIDEO_PLAY_TIMEOUT = setTimeout(playerRef.current.playVideo, 1000);
    }

    return () => {
      window.clearInterval(window.REACT_PLAYER_INT);
      window.clearInterval(window.VIDEO_PLAY_TIMEOUT);
    };
  }, [player, videoLastState, videoInfo, options?.autoPlay]);

  React.useEffect(() => {
    let playerProgressInterval = 0;

    const videoPlayerRef = playerRef?.current;

    let videoElem = null;
    if (player === 'VIDEO_CIPHER') {
      videoElem = videoPlayerRef;
    } else if (player === 'SHAKA') {
      videoElem = videoPlayerRef?.videoElement;
    } else if (player === 'JW_PLAYER') {
      videoElem = videoPlayerRef?.videoElem;
    }

    if (videoElem) {
      // playerProgressInterval && clearInterval(playerProgressInterval);
      playerProgressInterval = setInterval(updateVideoState(), 100);

      const videoPlayEventCallback = updateVideoState('play');
      const videoPauseEventCallback = updateVideoState('pause');
      const videoSeekedEventCallback = updateVideoState('seeked');
      const videoEndedEventCallback = updateVideoState('ended');

      const vdoCipherEventUnsubscribers = [];

      if (typeof videoPlayerRef?.on === 'function') {
        videoPlayerRef?.on('play', videoPlayEventCallback);
        videoPlayerRef?.on('pause', videoPauseEventCallback);
        videoPlayerRef?.on('seeked', videoSeekedEventCallback);
        videoPlayerRef?.on('ended', videoEndedEventCallback);
      } else if (videoElem?.addEventListener) {
        vdoCipherEventUnsubscribers.push(videoElem.addEventListener('play', videoPlayEventCallback));
        vdoCipherEventUnsubscribers.push(videoElem.addEventListener('pause', videoPauseEventCallback));
        vdoCipherEventUnsubscribers.push(videoElem.addEventListener('seeked', videoSeekedEventCallback));
        vdoCipherEventUnsubscribers.push(videoElem.addEventListener('ended', videoEndedEventCallback));
      }

      return () => {
        window.clearInterval(playerProgressInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        videoLastTimestamp = 0;

        if (videoElem?.removeEventListener) {
          videoElem.removeEventListener('play', videoPlayEventCallback);
          videoElem.removeEventListener('pause', videoPauseEventCallback);
          videoElem.removeEventListener('seeked', videoSeekedEventCallback);
          videoElem.removeEventListener('ended', videoEndedEventCallback);
        }

        if (typeof videoPlayerRef?.off === 'function') {
          videoPlayerRef?.off('play', videoPlayEventCallback);
          videoPlayerRef?.off('pause', videoPauseEventCallback);
          videoPlayerRef?.off('seeked', videoSeekedEventCallback);
          videoPlayerRef?.off('ended', videoEndedEventCallback);
        }

        if (vdoCipherEventUnsubscribers.length && player === 'VIDEO_CIPHER') {
          vdoCipherEventUnsubscribers.forEach((unsubscriber) => unsubscriber && unsubscriber());
        }
      };
    }

    if (player === 'VIMEO') {
      playerProgressInterval = setInterval(updateVideoState(), 100);

      return () => {
        window.clearInterval(playerProgressInterval);
        videoLastTimestamp = 0;
      };
    }

    return () => {
      window.clearInterval(playerProgressInterval);
      videoLastTimestamp = 0;
    };
  }, [video, player, playerRef?.current, videoInfo, updateVideoState, courseDetails]);

  React.useEffect(() => {
    if (playerRef.current) {
      if (options.syncProgress && primaryPlayerRef?.current) {
        window.PLAYER_SYNC_INTERVAL = setInterval(() => {
          if (player === 'SHAKA') {
            if (
              primaryPlayerRef?.current?.videoElement?.playing &&
              !playerRef?.current?.videoElement?.playing
            ) {
              playerRef.current.videoElement.currentTime = parseInt(
                primaryPlayerRef?.current?.videoElement?.currentTime || '0',
                10,
              );
            }
          } else if (player === 'VIDEO_CIPHER') {
            if (
              primaryPlayerRef?.current?.status === 1 &&
              playerRef?.current?.status !== 1
            ) {
              playerRef?.current?.seek(primaryPlayerRef.current.currentTime);
            }
          }
        }, 1000);
      }
    }

    return () => {
      window.clearInterval(window.PLAYER_SYNC_INTERVAL);
    };
  }, [options?.syncProgress, player, primaryPlayerRef]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (player === 'SHAKA' && playerRef.current) {
        const { player: shakaPlayer } = playerRef.current;

        const abrEnabled = shakaPlayer.getConfiguration().abr.enabled;
        const selectedResolution = shakaPlayer.getVariantTracks().find((track) => track.active);

        if (isPlayingRef.current) {
          window.sessionStorage.setItem('playerConfig', JSON.stringify({
            playbackRate: shakaPlayer.getPlaybackRate(),
            playbackResolution: abrEnabled ? null : selectedResolution?.originalVideoId,
          }));
        }
      }
    }, 1000);

    return () => window.clearInterval(interval);
  }, [player]);

  return { player, playerRef, triggerEvent, updateVideoState, isPlaying };
};

export default usePlayerEventCapture;
